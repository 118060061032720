<div class="messagebox" [class]="messageType">
  <strong *ngIf="messageType === 'received'" class="sr-only" i18n="sr-only@@messageStatus.receive">Empfangen: </strong>
  <strong *ngIf="messageType === 'sent'" class="sr-only" i18n="sr-only@@messageStatus.sent">Gesendet: </strong>
  <strong *ngIf="messageType === 'system'" class="sr-only" i18n="sr-only@@messageStatus.system">System: </strong>
  <p *ngIf="timestamp" class="messagebox__date">
    <span *ngIf="messageType === 'sent'" i18n="@@employment.conversation.you">Du, </span>
    <span *ngIf="messageType === 'received'">{{ companionName }}, </span>
    {{ timestamp | date : "dd.MM.yyyy 'um' HH:mm 'Uhr'" }}
  </p>
  <ng-content></ng-content>
</div>
