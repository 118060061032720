import { SelectionTag } from '../ui-library/models/selection-tag';

export interface SearchRequest {
  criteria: FilterCriteria;
  orderBy: OrderBy;
  sortDirection: SortDirectionDto;
  page: number;
}

export interface FilterCriteria {
  workPerimeter: Perimeter;
  compensation: SelectionTag[];
  assistanceServices: SelectionTag[];
  languages: SelectionTag[];
  mobility: SelectionTag[];
  preferredGender: SelectionTag[];
}

export interface SearchResult {
  totalMatches: number;
  totalPages: number;
  currentPage: number;
  matches: Match[];
}

export interface Match {
  member: Member;
  referenceCount: number;
  relevanceScore: number;
  distanceKm: number;
}

export interface Member {
  id: string;
  placeholder: boolean;
  firstName: string;
  lastName: string;
  profilePicture: { path: string };
  aboutMe: string;
  phoneNumber: string;
  emailAddress: string;
  documents: Document[];
  dateOfBirth: Date;
  age: number;
  nativeLanguage: SelectionTag;
  memberLanguages: SelectionTag[];
  gender: SelectionTag;
  offers: Offer[];
  references: Reference[];
  hasPrivilegedData: boolean;
  agbAccepted?: boolean;
}

export interface MemberModification {
  firstName: string;
  lastName: string;
  profilePicture: { path: string };
  aboutMe: string;
  phoneNumber: string;
  emailAddress: string;
  documents: Document[];
  dateOfBirth: Date;
  nativeLanguage: SelectionTag;
  memberLanguages: SelectionTag[];
  gender: SelectionTag;
  offer: OfferModification;
}

export interface ProfileImageModification {
  type: 'Avatar' | 'Custom';
  source: string;
}

export interface DocumentModification {
  name: string;
  type: string;
  path: string;
  modificationType: 'Add' | 'Remove';
}

export interface Offer {
  id: string;
  offerType: OfferType;
  isActive: boolean;
  description: string;
  assistanceServices: SelectionTag[];
  languages: SelectionTag[];
  mobility: SelectionTag[];
  workPerimeter: Perimeter;
  compensation: SelectionTag;
  preferredGender: SelectionTag;
}

export interface OfferModification {
  id: string;
  offerType: OfferType;
  isActive: boolean;
  description: string;
  assistanceServices: SelectionTag[];
  languages: SelectionTag[];
  mobility: SelectionTag[];
  workPerimeter: Perimeter;
  compensation: SelectionTag;
  preferredGender: SelectionTag;
}

export type OfferType = 'Assistance' | 'Job';
export type SortDirectionDto = 'Desc' | 'Asc';

export const LOCALSTORAGE_SORT_ORDER_KEY = 'marketplace-sort-field';
export const ORDER_BY_DISTANCE = 'Distance';
export const ORDER_BY_SCORE = 'RelevanceScore';
export type OrderBy = typeof ORDER_BY_SCORE | typeof ORDER_BY_DISTANCE;

export interface Document {
  name: string;
  path: string;
}


export interface Reference {
  createdOn: Date;
  authorId: string;
  authorFirstName: string;
  authorProfilePicture: { path: string };
  comment: string;
}

export interface Perimeter {
  place: string;
  zip: string;
  longitude: number;
  latitude: number;
  radiusM: number;
}

export class Location implements Perimeter {
  place: string;
  zip: string;
  longitude: number;
  latitude: number;
  radiusM: number;

  public constructor(init: Partial<Perimeter>) {
    Object.assign(this, init);
  }

  get  zipAndName(): string {
    return `${this.zip} ${this.place}`;
  }

  get  radiusInKm(): number {
    return this.radiusM / 1000;
  }
  set  radiusInKm(value: number) {
    this.radiusM = value * 1000;
  }
}
