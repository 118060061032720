import { Pipe, PipeTransform } from '@angular/core';
import { SelectionTag } from '../models/selection-tag';

@Pipe({
  name: 'stringifySelectionTags'
})
export class StringifySelectionTagsPipe implements PipeTransform {

  transform(value: SelectionTag | SelectionTag[]): string {
    let result = '';

    if (value instanceof Array) {
      result = (value as SelectionTag[]).map(v => v.name).join(', ');
    } else if (value instanceof Object) {
      result = (value as SelectionTag).name;
    }

    return result;
  }

}
