import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { SelectionTag } from 'src/app/ui-library/models/selection-tag';
import { MarketplaceService } from 'src/app/marketplace/marketplace.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { distinctUntilChanged, tap, switchMap, catchError, map, debounce, debounceTime } from 'rxjs/operators';
import { Location } from 'src/app/marketplace/marketplace.model';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-recipient-offer',
  templateUrl: './recipient-offer.component.html',
  styleUrls: ['./recipient-offer.component.scss']
})
export class RecipientOfferComponent implements OnInit {
  @ViewChild('locationSelect') locationSelect: NgSelectComponent;

  @Input() parentGroup: FormGroup;

  availableAssistanceServices$: Observable<SelectionTag[]>;

  locationInput$ = new Subject<string>();
  locations$: Observable<Location[]>;
  locationLoading: boolean;
  readonly linkToFilterDescription: string = $localize`:@@www-clea-app-filter-erklaerung-clea-jobplattform:https://www.clea.app/filter-erklaerung-clea-jobplattform/`;

  constructor(private marketplaceService: MarketplaceService) { }

  get locationControl(): AbstractControl {
    return this.parentGroup.get('location');
  }

  get assistanceServicesControl(): AbstractControl {
    return this.parentGroup.get('assistanceServices');
  }

  get jobDescription(): AbstractControl {
    return this.parentGroup.get('jobDescription');
  }

  ngOnInit(): void {
    this.availableAssistanceServices$ = this.marketplaceService.getAvailableAssistanceServices();

    this.locations$ = concat(
      of([]),
      this.locationInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.locationLoading = true),
        switchMap(term => this.marketplaceService.queryLocation(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.locationLoading = false),
        ))
      )
    );
  }

  clearLocation() {
    this.locationSelect.clearModel();
  }

}
