import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  FilterCriteria,
  Location,
  OfferModification,
  Perimeter,
  OrderBy,
  ORDER_BY_SCORE,
  LOCALSTORAGE_SORT_ORDER_KEY
} from './marketplace.model';
import { SelectionTag } from '../ui-library/models/selection-tag';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceFormService {
  constructor(private fb: FormBuilder, private profile: ProfileService) {
  }

  createSearchForm(): FormGroup {
    const selectedSortField: OrderBy = localStorage.getItem(LOCALSTORAGE_SORT_ORDER_KEY) as OrderBy || ORDER_BY_SCORE;

    const searchForm = this.fb.group({
      page: [1, Validators.required],
      sortField: [selectedSortField, Validators.required],
      offerId: [null, Validators.required],
      location: [null, Validators.required],
      range: [0, []],
      assistanceServices: [[]],
      languages: [[]],
      compensation: 'np',
      mobility: [[]],
      preferredGender: 'np',
    });

    this.profile.currentMember().subscribe(m => {
      const o = m.offers[0];
      const location = o ? new Location(o.workPerimeter) : undefined;
      const nativeLanguageArray = m.nativeLanguage ? [m.nativeLanguage.key] : [];
      const offerValues = {
        offerId: o.id,
        location,
        range: location ? location.radiusInKm : 5,
        assistanceServices: o.assistanceServices.map(l => l.key),
        languages: o.languages.map(l => l.key).concat(nativeLanguageArray),
        compensation: o.compensation.key,
        mobility: o.mobility.map(l => l.key),
        preferredGender: o.preferredGender.key,
      };

      searchForm.patchValue(offerValues);
    });

    return searchForm;
  }
}

export const mapToFilterCriteria = (formValues: any): FilterCriteria => {
  let location = null;
  if (formValues.location) {
    location = new Location(formValues.location as Perimeter);
    location.radiusInKm = formValues.range;
  }
  return {
    workPerimeter: location,
    compensation: [createTag(formValues.compensation)],
    assistanceServices: formValues.assistanceServices.map((l) => createTag(l)),
    languages: formValues.languages.map((l) => createTag(l)),
    mobility: [],  // at the moment we cannot search for mobility AP-263,
    preferredGender: [createTag(formValues.preferredGender)]
  };
};

export const mapToOffer = (formValues: any): OfferModification => {
  let location = null;
  if (formValues.location) {
    location = new Location(formValues.location as Perimeter);
    location.radiusInKm = formValues.range;
  }
  return {
    id: formValues.offerId,
    offerType: formValues.offerType, // really make this changeable
    isActive: true,
    description: undefined,
    workPerimeter: location,
    compensation: createTag(formValues.compensation),
    assistanceServices: formValues.assistanceServices.map((s) => createTag(s)),
    languages: formValues.languages.map((l) => createTag(l)),
    mobility: formValues.mobility.map((m) => createTag(m)),
    preferredGender: createTag(formValues.preferredGender)
  };
};

const createTag = (key: string): SelectionTag => ({
    key,
    name: null
  });
