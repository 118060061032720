import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../identity/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProfileFormService, mapToMember, mapToProfileImageModification, mapToDocumentModifications } from '../profile-form.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ProfileService, UserRoleType } from '../profile.service';
import { Member, OfferType } from 'src/app/marketplace/marketplace.model';
import { GiverProfileComponent } from '../shared/giver-profile/giver-profile.component';
import { RecipientProfileComponent } from '../shared/recipient-profile/recipient-profile.component';
import { SelectionTag } from 'src/app/ui-library/models/selection-tag';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { IdentityService } from 'src/app/identity/identity.service';
import { ChangeEmailComponent } from 'src/app/profile/change-email/change-email.component';
import { DeactivationGuarded } from 'src/app/ui-library/can-deactivate-guard.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit, DeactivationGuarded {
  @ViewChild('giverProfile')
  giverProfile: GiverProfileComponent;

  @ViewChild('recipientProfile')
  recipientProfile: RecipientProfileComponent;

  activeFragment = this.route.fragment;
  isGiver = false;
  isRecipient = false;

  offerId: string;
  member: Member;

  form: FormGroup;
  step1: FormGroup;
  step2: FormGroup;
  step3: FormGroup;

  showPersonalData = false;
  showAssistance = false;
  showVisibility = false;

  activeOfferControlChoices: SelectionTag[] = [
    {
      key: 'true',
      name: $localize`:@@profileEdit.select.true:Mein Profil in Suchen anzeigen`
    }, {
      key: 'false',
      name: $localize`:@@profileEdit.select.false:Mein Profil nicht veröffentlichen`
    }];

  constructor(
    public auth: AuthService,
    public identity: IdentityService,
    public route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private profileService: ProfileService,
    private formService: ProfileFormService,
    private modal: ModalService) {
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.form.dirty) {
      return this.modal.showConfirmation(
        $localize`:@@profileEdit.popup.title.canDeactivate:Ungespeicherte Änderungen`,
        $localize`:@@profileEdit.popup.content.canDeactivate:Sie haben die Änderungen an Ihrem Profil nicht gespeichert. Möchten Sie dennoch fortfahren?`
      );
    }
    return true;
  }

  get activeOfferControl(): AbstractControl {
    return this.step3.get('isActive');
  }

  ngOnInit(): void {
    this.initForm();
    this.profileService.currentMember(true)
      .subscribe(m => {
        if (this.auth.isGiver) {
          this.member = m;
          this.offerId = m.offers.find(o => o.offerType === 'Assistance').id;
          this.form = this.formService.createGiver(m);
          this.step1 = this.form.get('step1') as FormGroup;
          this.step2 = this.form.get('step2') as FormGroup;
          this.step3 = this.form.get('step3') as FormGroup;

        } else if (this.auth.isRecipient) {
          this.member = m;
          this.offerId = m.offers.find(o => o.offerType === 'Job').id;
          this.form = this.formService.createRecipient(m);
          this.step1 = this.form.get('step1') as FormGroup;
          this.step2 = this.form.get('step2') as FormGroup;
          this.step3 = this.form.get('step3') as FormGroup;
        }
      });

      this.profileService.offer$.subscribe((offer) => {
        if (this.step3 && offer) {
          this.step3.patchValue({ isActive: offer.isActive });
          this.activeOfferControl.setValue('' + offer.isActive);
        }
      });
  }

  back(): void {
    this.location.back();
  }

  async submit() {
    if (this.form.valid) {

      // update profileImage based on cropping if it was changed...
      const customProfileImage = this.step1.get('customProfileImage');
      const profileImage = this.step1.get('profileImage');
      if (customProfileImage.dirty && customProfileImage.value) {
        const croppedImage = await (this.isGiver ? this.giverProfile.croppedImage() : this.recipientProfile.croppedImage());
        profileImage.setValue(croppedImage);
        profileImage.markAsDirty();
      }

      const offerType: OfferType = this.isGiver ? 'Assistance' : 'Job';
      const member = mapToMember(this.offerId, offerType, this.form);
      const profileImageMod = profileImage.dirty ? mapToProfileImageModification(this.form) : undefined;
      const docMods = mapToDocumentModifications(this.form.value.step2.documents);
      this.profileService.modifyMember(member, profileImageMod, docMods).then(
        () => {
          this.identity.silentAuthentication(true).then(() => {
            console.log('Successfully modified');
            this.ngOnInit();
          });
        },
        (err) => console.log('Modification failed', err)
      );
    } else {
      console.log('form invalid');
    }
  }

  async deleteMember() {
    const confirmed = await this.modal.showConfirmation(
      $localize`:@@profileEdit.popup.title.deleteMember:Wirklich löschen?`,
      $localize`:@@profileEdit.popup.content.deleteMember:Wenn Sie den User löschen, gehen alle Ihre Daten UNWIDERRUFLICH verloren. Möchten Sie dennoch fortfahren?`
    );
    if (confirmed) {
      try {
        await this.profileService.deleteMember();
      } finally {
        this.auth.clearSession();
        this.router.navigate(['/']);
      }
    }
  }

  toggle(section: string): void {
    switch (section) {
      case 'personal-data': {
        this.showPersonalData = !this.showPersonalData;
        break;
      }
      case 'assistance': {
        this.showAssistance = !this.showAssistance;
        break;
      }
      case 'visibility': {
        this.showVisibility = !this.showVisibility;
        break;
      }
    }
  }

  changePassword(): void {
    this.modal.showMessageBox(
      $localize`:@@profileEdit.popup.title.changePassword:Passwort ändern`,
      $localize`:@@profileEdit.popup.content.changePassword:Wir haben Ihnen eine E-Mail mit einem Link zur Änderung Ihres Passworts zugesendet. Falls Sie die E-Mail nicht erhalten haben, prüfen Sie bitte auch den Spam-Ordner.`
    );
    this.identity.resetPassword();
  }

  changeEmail(): void {
    this.modal.show(ChangeEmailComponent, { email: this.auth.userProfile.email });
  }

  private initForm(): void {
    this.form = this.step1 = this.step2 = this.step3 = null;
    if (this.auth.isGiver) {
      this.isGiver = true;
      this.isRecipient = false;

    } else if (this.auth.isRecipient) {
      this.isGiver = false;
      this.isRecipient = true;
    } else {
      console.error('Wrong roles');
    }
  }
}
