<div class="grid" [formGroup]="parentGroup"> <!-- style="align-items: center;"> -->
  <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
  <div class="form-group span-12 profile-image">
    <div class="avatar0">
      <app-avatar-selector name="avatar-group" [src]="avatars[0]"
                           number="1"
                           [checked]="profileImage === avatars[0]"
                           (change)="setAvatar(avatars[0])"></app-avatar-selector>
    </div>
    <div class="avatar1">
      <app-avatar-selector name="avatar-group" [src]="avatars[1]"
                           number="2"
                           [checked]="profileImage === avatars[1]"
                           (change)="setAvatar(avatars[1])"></app-avatar-selector>
    </div>
    <div class="avatar2">
      <app-avatar-selector name="avatar-group" [src]="avatars[2]"
                           number="3"
                           [checked]="profileImage === avatars[2]"
                           (change)="setAvatar(avatars[2])"></app-avatar-selector>
    </div>
    <div class="avatar3">
      <app-avatar-selector name="avatar-group" [src]="avatars[3]"
                          number="4"
                           [checked]="profileImage === avatars[3]"
                           (change)="setAvatar(avatars[3])"></app-avatar-selector>
    </div>
    <div class="custom">
      <app-profile-image-selector #profileImageSelector
                                  [src]="customProfileImage"
                                  (srcChange)="setCustomProfileImage($event)">
      </app-profile-image-selector>
    </div>

    <div *ngIf="profileImageControl.invalid && (profileImageControl.touched || profileImageControl.dirty)"
         class="validation error span-12" i18n="@@shared.recipientProfile.error.profileImage">Bitte wählen Sie Ihr Profilbild aus.</div>
  </div>

  <div class="form-group span-6">
    <label for="input-firstname" class="label">
      <ng-container i18n="@@shared.recipientProfile.input.firstName">Vorname</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <input id="input-firstname" type="text" class="input"
           formControlName="first"
           maxlength="40">
    <div *ngIf="firstControl.invalid && (firstControl.touched || firstControl.dirty)"
         class="validation error" i18n="@@shared.recipientProfile.error.firstName">Bitte geben Sie Ihren Vornamen ein.</div>
  </div>
  <div class="form-group span-6">
    <label for="input-name" class="label">
      <ng-container i18n="@@shared.recipientProfile.input.secondName">Name (wird nicht veröffentlicht)</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <input id="input-name" type="text" class="input"
           formControlName="name"
           maxlength="40">
    <div *ngIf="nameControl.invalid && (nameControl.touched || nameControl.dirty)"
         class="validation error" i18n="@@shared.recipientProfile.error.secondName">Bitte geben Sie Ihren Nachnamen ein.</div>
  </div>
</div>
