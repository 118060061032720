<main class="main-plain-centered">
  <div class="plain-content" *ngIf="isLoaded">
    <h1 class="sr-only" i18n="sr-only@@employment.teamOverview.title">Teamübersicht</h1>

    <div class="requests-area" *ngIf="employment.relations.length > 0">
      <h2 class="section-heading" i18n="@@employment.teamOverview.requests">Anfragen</h2>
      <ol>
        <li *ngFor="let relation of employment.relations" class="request">
          <app-job-request [memberRelation]="relation"></app-job-request></li>
      </ol>
    </div>

    <div class="members-area">
      <h2 class="section-heading" i18n="@@employment.teamOverview.myTeam">Mein Team</h2>
      <app-empty-team *ngIf="employment.relationsInTeam.length === 0" (invitationSent)="addPlaceholder($event)"></app-empty-team>

      <ul class="members" *ngIf="employment.relationsInTeam.length > 0">
        <li *ngFor="let teamMember of employment.relationsInTeam" class="member">
          <app-team-member [memberRelation]="teamMember"></app-team-member>
        </li>
        <li class="member">
          <app-invitation [addPlaceholderToTeam]="true" (sent)="addPlaceholder($event)"></app-invitation>
        </li>
      </ul>
    </div>

    <div class="invitee-area" *ngIf="employment.invitees.length > 0">
      <ul>
        <li *ngFor="let invitee of employment.invitees" class="invitee">
          <app-invitee-placeholder [memberRelation]="invitee" (removed)="clearPlaceholder($event)"></app-invitee-placeholder>
        </li>
      </ul>
    </div>

    <div class="footer-area" *ngIf="employment.relationsInTeam.length > 0">
      <img class="footer-image" src="/assets/images/illustration-needs.svg" aria-hidden="true" alt="">
      <app-message i18n="@@employment.teamOverview.haveIdeas">
        Wir arbeiten laufend daran, die CléA Assistenzplattform zu verbessern. Um Ihnen die Administration Ihrer persönlichen Assistentinnen und Assistenten zu erleichtern, planen wir die Einführung für Planung, Zeiterfassung inkl. Budgetplanung und Abrechnung als erweiterte Module der CléA Assistenzplattform. Haben Sie Ideen oder ein <a [href]="emailFeedbackPlatfrom">Feedback</a> , was wir sonst noch verbessern können?
      </app-message>
    </div>
  </div>
  <div *ngIf="!isLoaded" class="loading"><app-spinner></app-spinner></div>
</main>
