import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SelectionTag, TagStatus, SelectionTagKeyType } from '../models/selection-tag';

@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.scss']
})
export class TagSelectorComponent implements OnInit, OnChanges {
  @Input() selectableTags: SelectionTag[];
  @Input() value: SelectionTagKeyType[] = [];
  @Output() valueChange = new EventEmitter<SelectionTagKeyType[]>();
  @Input() block = false;
  @Input() labelledby = '';

  currentTags: SelectionValue[] = [];

  constructor() { }
  ngOnInit(): void {
    this.updateCurrentTags(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectableTags'] && changes['selectableTags'].currentValue) {
      this.initCurrentTags(changes['selectableTags'].currentValue);
    }
    if (changes['value'] && changes['value'].currentValue) {
      this.updateCurrentTags(changes['value'].currentValue);
    }
  }

  toggle(current: SelectionValue): void {
    current.status = current.status === TagStatus.Selected ? TagStatus.Unselected : TagStatus.Selected;
    this.updateValue(true);

  }

  isChecked(current: SelectionValue): boolean {
    return current.status === TagStatus.Selected;
  }

  private initCurrentTags(tags: SelectionTag[]): void {
    this.currentTags = tags.map(st => ({ key: st.key, name: st.name, status: TagStatus.Unselected } as SelectionValue));
    this.updateCurrentTags(this.value);
  }

  private updateCurrentTags(keys: SelectionTagKeyType[]): void {
    let hasUpdates = false;
    this.currentTags.forEach(st => {
      const found = keys.find(key => key === st.key);
      if (found) {
        if (st.status !== TagStatus.Selected) {
          st.status = TagStatus.Selected;
          hasUpdates = true;
        }
      } else {
        if (st.status === TagStatus.Selected) {
          st.status = TagStatus.Unselected;
          hasUpdates = true;
        }
      }
    });
    if (hasUpdates) {
      this.updateValue();
    }
  }

  private updateValue(emitValueChange = false): void {
    this.value = this.currentTags
      .filter(ct => ct.status === TagStatus.Selected)
      .map(ct => ct.key);

    if (emitValueChange) {
      this.valueChange.emit(this.value);
    }
  }
}

interface SelectionValue {
  key: string;
  name: string;
  status: TagStatus;
}
