import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MarketplaceComponent } from './marketplace/marketplace.component';
import { SearchComponent } from './search/search.component';
import { MemberDetailComponent } from './member-detail/member-detail.component';
import { AuthGuard } from '../auth.guard';

const marketplaceRoutes: Routes = [
  {
    path: 'marketplace',
    component: MarketplaceComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '',  component: SearchComponent },
      { path: ':id',  component: MemberDetailComponent }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(marketplaceRoutes)
  ],
  providers: [
    AuthGuard
  ],
  exports: [
    RouterModule
  ]
})
export class MarketplaceRoutingModule { }
