import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * Add classes 'icon' and 'icon-chevron-down' to arrow-wrapper of ng-select.
 */
@Directive({
  selector: '[appAddIconChevronDown]'
})
export class AddIconChevronDownDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    const $arrowWrapper = (this.el.nativeElement as HTMLElement).querySelector('.ng-arrow-wrapper');
    if (!$arrowWrapper) {
      console.warn(`AddIconChevronDownDirective applied to HTML element ${this.el.nativeElement} that is not an ng-select!`);
      return;
    }
    this.renderer.addClass($arrowWrapper, 'icon');
    this.renderer.addClass($arrowWrapper, 'icon-chevron-down');
  }
}
