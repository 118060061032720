<section class="multi-messages">
  <h3 class="heading" i18n="@@marketplace.memberDetail.news">Nachrichten</h3>
  <div
        class="form-group span-12 information-text information-text--messages-without-limit"
        i18n="@@employment.conversation.sendMessagesWithoutLimit"
  >Sie haben die Möglichkeit, uneingeschränkt Nachrichten auszutauschen. Mit dem Schreiben der Nachrichten wird Ihr Profil <b>nicht</b> automatisch geteilt. Wenn Sie Nachrichten erhalten haben, werden Sie einmal pro Tag via E-Mail darüber informiert.</div>
  <div class="multi-messages__input-area">
    <app-spinner [visible]="(isLoading$ | async) !== false" size="40" class="multi-messages__loading loading"></app-spinner>

    <form (submit)="$event.preventDefault(); request()">
      <div class="multi-messages__input-box">
        <textarea #replyControl
          class="multi-messages__textarea textarea"
          aria-label="Nachricht verfassen (max 320 Zeichen)…"
          i18n-aria-label="@@employment.conversation.placeholderInputMessage"
          [formControl]="replyFormControl"
          i18n-placeholder="@@employment.conversation.placeholderInputMessage"
          placeholder="Nachricht verfassen (max 320 Zeichen)…"
          rows="4"
          maxlength="320"
        ></textarea>

        <div *ngIf="replyFormControl.invalid && replyFormControl.touched" class="validation error" i18n="@@employment.conversation.messageError">
          Bitte geben Sie eine Nachricht ein.
        </div>

        <div class="multi-messages__actions actions">
          <button class="button secondary" type="submit" [disabled]="(isLoading$ | async) !== false">
            <span class="icon icon-send" aria-hidden="true"></span>
            <ng-container i18n="@@employment.conversation.btnSendMessage">Nachricht senden</ng-container>
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="multi-messages__container">
    <span class="sr-only" i18n="sr-only@@employment.conversation.messageHistory">Nachrichtenverlauf:</span>

    <ol>
      <li [class]="{ received: message.messageType === RECEIVED, sent: message.messageType === SENT}" *ngFor="let message of messages">
        <app-message
          [messageType]="message.messageType.toLowerCase()"
          [timestamp]="message.createdOn"
          [companionName]="companionName"
        >{{ message.data.text }}</app-message>
      </li>
    </ol>

  </div>
</section>
