<main class="main-start">
  <div class="content">
    <form [formGroup]="form">
      <div class="form-title" i18n="@@start.text.title">
        Registrieren
      </div>
      <div class="form-group">
        <p class="label" i18n="@@start.input.whatAppliesToYou?">Was trifft auf Sie zu?</p>
        <button type="button" class="information-text information-text--button" [class]="isRecipient ? 'active' : ''" (click)="selectRecipient()">
          <p class="information-text__title" i18n="@@start.input.title.iAmRecipient">Ich suche Assistenz</p>
          <p class="information-text__content" i18n="@@start.input.content.iAmRecipient">Wünschen Sie sich eine Assistenz im Alltag?</p>
          <span class="icon icon-check icon-front" *ngIf="isRecipient" aria-hidden="true"></span>
        </button>
        <button type="button" class="information-text information-text--button information-text--recipient" [class]="isGiver ? 'active' : ''" (click)="selectGiver()">
          <p class="information-text__title" i18n="@@start.input.title.iAmGiver">Ich biete Assistenz</p>
          <p class="information-text__content" i18n="@@start.input.content.iAmGiver">Auf der Suche nach einem Job als Assistenzperson.</p>
          <span class="icon icon-check icon-front" *ngIf="isGiver" aria-hidden="true"></span>
        </button>
      </div>
      <div class="form-group">
        <label for="input-email-giver" class="label" i18n="@@start.input.email">E-Mail</label>
        <input
          id="input-email-giver"
          type="email"
          class="input large"
          formControlName="email"
          placeholder="Ihre E-Mailadresse"
          i18n-placeholder="@@start.placeholder.email"
        >
        <div *ngIf="emailControl.invalid && (emailControl.touched || emailControl.dirty)" class="validation error" i18n="@@start.error.email">
          Bitte geben Sie eine gültige E-Mail Adresse ein.
        </div>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input id="input-accept-terms-giver" type="checkbox" formControlName="agb">
          <span i18n="@@start.checkbox.agb">
            <label for="input-accept-terms-giver">Ich akzeptiere</label>
            die
            <a [href]="linkToAGB" target="_blank">AGBs</a>
            und die
            <a [href]="linkToDSE" target="_blank">DSE</a>
            der CléA Assistenzplattform.
          </span>
        </div>
        <div *ngIf="agb.invalid && (agb.touched || agb.dirty)" class="validation error" i18n="@@start.error.agb">
          Bitte akzeptieren Sie die AGB.
        </div>
      </div>
      <div class="form-group">
        <button (click)="register()" class="button primary large block" [disabled]="!(isGiver || isRecipient)">
          <ng-container *ngIf="isGiver" i18n="@@start.btn.registerAsGiver">Als Assistent/in registrieren</ng-container>
          <ng-container *ngIf="!isGiver" i18n="@@start.btn.registerAsRecipient">Als Assistenznehmer/in registrieren</ng-container>
        </button>
      </div>
      <div class="form-group text-center">
        <button type="button" class="link login" (click)="login()" i18n="@@start.btn.alreadyRegistered">Bereits ein Konto? <span>Einloggen</span></button>
      </div>
    </form>
  </div>
  <div class="start-background"></div>
</main>
