<div class="summary">
  <figure class="avatar" aria-hidden="true">
    <img [src]="member.profilePicture.path" alt="">
  </figure>
  <div class="summary-content">
    <h3 class="heading">{{member.firstName}} <ng-container *ngIf="member.hasPrivilegedData">{{member.lastName}}</ng-container></h3>
    <p>
      <strong>{{offer.assistanceServices | stringifySelectionTags}}</strong><br>
      <span *ngIf="offer.workPerimeter">{{offer.workPerimeter.zip}} {{offer.workPerimeter.place}}, </span>
      <span *ngIf="match.distanceKm" i18n="@@marketplace.hitlistItem.distance">{{match.distanceKm}} km entfernt</span>
      <span *ngIf="match.distanceKm === 0" i18n="@@marketplace.hitlistItem.distanceFewKmAway">wenige km entfernt</span>
    </p>

    <p *ngIf="member.aboutMe" class="description">
      {{member.aboutMe}}
    </p>
    <p *ngIf="offer.description" class="description">
      {{offer.description}}
    </p>
    <ul class="summary__member-documents">
      <li class="summary__member-document" *ngFor="let document of member.documents">
        <span class="icon icon-file-text" aria-hidden="true"></span>
        <span>{{ document.name }}</span>
      </li>
    </ul>
  </div>
</div>
