import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StartComponent } from './start/start.component';
import { LoginComponent } from './login/login.component';
import { SetupRecipientProfileComponent } from './setup-recipient-profile/setup-recipient-profile.component';
import { SetupRecipientProfileDetailsComponent } from './setup-recipient-profile-details/setup-recipient-profile-details.component';
import { SetupRecipientOfferComponent } from './setup-recipient-offer/setup-recipient-offer.component';
import { SetupGiverProfileComponent } from './setup-giver-profile/setup-giver-profile.component';
import { SetupGiverProfileDetailsComponent } from './setup-giver-profile-details/setup-giver-profile-details.component';
import { SetupGiverOfferComponent } from './setup-giver-offer/setup-giver-offer.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AvatarSelectorComponent } from './avatar-selector/avatar-selector.component';
import { ProfileImageSelectorComponent } from './profile-image-selector/profile-image-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { VerifyComponent } from './verify/verify.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { GiverProfileComponent } from './shared/giver-profile/giver-profile.component';
import { GiverProfileDetailsComponent } from './shared/giver-profile-details/giver-profile-details.component';
import { GiverOfferComponent } from './shared/giver-offer/giver-offer.component';
import { RecipientProfileComponent } from './shared/recipient-profile/recipient-profile.component';
import { RecipientProfileDetailsComponent } from './shared/recipient-profile-details/recipient-profile-details.component';
import { RecipientOfferComponent } from './shared/recipient-offer/recipient-offer.component';
import { ChangeEmailComponent } from './change-email/change-email.component';

import { CroppieModule } from 'angular-croppie-module';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { ToglleVisibilityComponent } from './toglle-visibility/toglle-visibility.component';

@NgModule({
  declarations: [
    StartComponent,
    LoginComponent,
    SetupRecipientProfileComponent,
    SetupRecipientProfileDetailsComponent,
    SetupRecipientOfferComponent,
    SetupGiverProfileComponent,
    SetupGiverProfileDetailsComponent,
    SetupGiverOfferComponent,
    OnBoardingComponent,
    AvatarSelectorComponent,
    ProfileImageSelectorComponent,
    VerifyComponent,
    ProfileEditComponent,
    GiverProfileComponent,
    GiverProfileDetailsComponent,
    GiverOfferComponent,
    RecipientProfileComponent,
    RecipientProfileDetailsComponent,
    RecipientOfferComponent,
    ChangeEmailComponent,
    ToglleVisibilityComponent
  ],
  exports: [ToglleVisibilityComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiLibraryModule,
    NgSelectModule,
    HttpClientModule,
    ProfileRoutingModule,
    CroppieModule,
    FilePondModule
  ]
})
export class ProfileModule { }
