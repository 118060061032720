import { Component, OnInit } from '@angular/core';
import { RequiredAssistanceServicesComponent } from './app-layout/modals/required-assistance-services/required-assistance-services.component';
import { RelationRole } from './employment/employment.model';
import { EmploymentService } from './employment/employment.service';
import { AuthService } from './identity/auth.service';
import { IdentityService } from './identity/identity.service';
import { ModalService } from './ui-library/modal/modal.service';
import { Router, NavigationEnd } from '@angular/router';
import { ProfileService } from './profile/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  loggedIn: boolean;

  constructor(
    private modalService: ModalService,
    private auth: AuthService,
    private identity: IdentityService,
    private employment: EmploymentService,
    private profileService: ProfileService,
    private router: Router ) {}

  ngOnInit(): void {
    this.loggedIn = false;
    this.identity.silentAuthentication().then(() => {
      if (this.auth.isLoggedIn) {
        const myRole: RelationRole = this.auth.isGiver ? 'Employee' : 'Employer';
        this.employment.loadRelations(myRole).subscribe();

        this.checkAssistanceServices();
      }
    });

    this.router.events.subscribe( event => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/' && event.url !== '/verify-registration') {
          this.loggedIn = true;
        }
      }
    });
  }

  async checkAssistanceServices() {
    const member = await this.profileService.currentMember().toPromise();
    if (!member.offers || !member.offers.length) {
      return;
    }
    const offer = member.offers[0];
    const assistanceServices = offer?.assistanceServices ? offer.assistanceServices.map(l => l.key) : [];
    if (assistanceServices.length === 0) {
      this.modalService.show(RequiredAssistanceServicesComponent, {
        isGiver: this.auth.isGiver
      });
    }
  }
}
