<div class="message-modal">
  <img src="/assets/images/illustration-15.svg" class="header-image" alt="" aria-hidden="true">
  <h1 id="modal-title" class="title" i18n="@@employment.popup.contactShared.title">Kontaktdaten geteilt</h1>
  <p class="body" i18n="@@employment.popup.contactShared.content">Wir raten Ihnen, beim ersten Treffen eine Vertrauensperson dabei zu haben. Die Checkliste hilft Ihnen bei der Vorbereitung des Gespräches.</p>
  <div class="action">
    <button class="button" (click)="ok()" i18n="@@employment.popup.contactShared.btnOk">Alles klar</button>
    <a class="button primary"
      href="https://www.clea.app/informationen-assistenznehmende/"
      i18n-href="@@www-clea-app-informationen-assistenznehmende"
      target="_blank"
      i18n="@@employment.popup.contactShared.linkCheckList"
    >Checkliste</a>
  </div>
</div>
