import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './identity/auth.service';
import { IdentityService } from './identity/identity.service';

// Guard to protect all internal content which is only visible for registered users
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private identity: IdentityService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise<boolean | UrlTree>((resolve) => {
         this.identity.silentAuthentication().then(() => {
          if (!this.authService.hasValidToken) {
            this.identity.login();
            resolve(false);
          } else if (this.authService.isUncompletedRegistration) {
            resolve(this.router.createUrlTree(['/']));
          }

          resolve(true);
         });
      });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}



// Guard which ensures that the public content like the start page is
// only visible to not currently logged in users
@Injectable({
  providedIn: 'root'
})
export class PublicAuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private identity: IdentityService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise<boolean | UrlTree>((resolve) => {
        this.identity.silentAuthentication().then(() => {
          if (this.authService.isLoggedIn) {
            return resolve(this.router.createUrlTree(['/marketplace']));
          }

          resolve(true);
        });
      });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}


// Guard which ensures that the registration flow is
// only visible to users which have not yet completely registered
@Injectable({
  providedIn: 'root'
})
export class RegistrationAuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private identity: IdentityService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise<boolean | UrlTree>((resolve) => {
        this.identity.silentAuthentication().then(() => {
          if (!this.authService.hasValidToken) {
            resolve(this.router.createUrlTree(['/']));
          } else if (this.authService.isLoggedIn) {
            resolve(this.router.createUrlTree(['/marketplace']));
          }

          resolve(true);
        });
      });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}

// Guard to protect all internal content which is only Giver (PA) role
@Injectable({
  providedIn: 'root'
})
export class RolePAGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise<boolean | UrlTree>(async (resolve) => {
        if (this.authService.isGiver) {
          resolve(true);
        }

        resolve(this.router.createUrlTree(['/']));
      });
  }
}

// Guard to protect all internal content which is only Recipient (AN) role
@Injectable({
  providedIn: 'root'
})
export class RoleANGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise<boolean | UrlTree>(async (resolve) => {
        if (this.authService.isRecipient) {
          resolve(true);
        }

        resolve(this.router.createUrlTree(['/']));
      });
  }
}
