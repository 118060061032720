<form class="invitation-box" [class.vertical]="addPlaceholderToTeam" [formGroup]="invitationForm" (ngSubmit)="submitInvitation()">
  <div class="image d-none d-sm-flex">
    <img src="/assets/images/illustration-14.svg" i18n-alt="Alt text@@invitationForm.imageAltInvitation" alt="Einladung" aria-hidden="true">
  </div>
  <div class="content">
    <div class="flex-row">
      <div>
        <h3 *ngIf="addPlaceholderToTeam" i18n="@@invitationForm.forTeam.title">Empfehlen Sie uns weiter!</h3>
        <h3 *ngIf="!addPlaceholderToTeam" i18n="@@invitationForm.forPeople.title">Kennen Sie interessierte Leute?</h3>
        <p *ngIf="addPlaceholderToTeam" i18n="@@invitationForm.forTeam.content">Laden Sie bisherige persönliche Assistentinnen und Assistenten oder Freunde und Familie zur CléA Assistenzplattform ein.</p>
        <p *ngIf="!addPlaceholderToTeam" i18n="@@invitationForm.forPeople.content">Laden Sie Freunde und Familie ein, bei der CléA Assistenzplattform mitzumachen.</p>
      </div>
      <div class="image d-sm-none">
        <img src="/assets/images/illustration-06.svg" alt="" aria-hidden="true">
      </div>
    </div>
    <div *ngIf="(isLoading$ | async) === false && !invited" class="form-group">
      <label for="invitation-email" class="label" i18n="@@invitationForm.email">E-Mail</label>
      <input id="invitation-email" type="email" class="input" [formControl]="email" />
      <div *ngIf="email.invalid && email.touched" class="validation error" i18n="@@invitationForm.email.invalidFormat">
        Bitte geben Sie eine gültige E-Mail Adresse ein.
      </div>
    </div>
    <app-spinner [visible]="isLoading$ | async"></app-spinner>
    <p *ngIf="invited" class="invited" i18n="@@invitationForm.invitationHasBeenSent">Die Einladung wurde verschickt.</p>
  </div>
  <div class="form-group actions" *ngIf="(isLoading$ | async) === false && !invited">
    <button type="submit" [disabled]="isLoading$ | async" class="button secondary block" i18n="@@invitationForm.btn.send">Einladung versenden</button>
  </div>
</form>
