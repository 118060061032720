import { Component, OnInit, Input } from '@angular/core';
import { Member, Offer, OfferType, Reference, Document } from '../marketplace.model';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TagStatus, SelectionTag } from 'src/app/ui-library/models/selection-tag';
import { AuthService } from 'src/app/identity/auth.service';
import { ProfileService, documentDownloadUrl } from 'src/app/profile/profile.service';
import { EmploymentService } from 'src/app/employment/employment.service';
import { Relation, RelationRole } from 'src/app/employment/employment.model';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent implements OnInit {
  member: Member;
  relation: Relation;
  tagStatus: TagStatus = TagStatus.Selected;
  activeFragment = this.route.fragment;
  myProfile = false;

  readonly emailReportAbuseSubject: string = $localize`:@@emailReportAbuseSubject:Missbrauch / Vorfall melden`;
  readonly emailReportAbuseBody: string = $localize`:@@emailReportAbuseBody:emailReportAbuseBody`;

  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    private location: Location,
    private profileService: ProfileService,
    private employmentService: EmploymentService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.myProfile = false;
      this.relation = null;
      if (id === 'me') {
        this.myProfile = true;
        this.profileService.currentMember().subscribe(m => {
          this.member = m;
          this.member.hasPrivilegedData = false;
        });
      } else {
        this.profileService.member(id).subscribe(m => this.member = m);
        this.employmentService.getRelation(this._memberRole, id).subscribe(r => this.relation = r);
      }
    });
  }

  get emailReportAbuse(): string {
    const memberId = this.member && this.member.id;
    const body = this.emailReportAbuseBody.replace(/{{member.id}}/gi, memberId);
    return `mailto:missbrauch@clea.app?subject=${encodeURIComponent(this.emailReportAbuseSubject)}&body=${encodeURIComponent(body)}`;
  }

  get offer(): Offer {
    let visibleOffer: OfferType = this.auth.isGiver ? 'Job' : 'Assistance';

    if (this.myProfile) {
      visibleOffer = this.auth.isGiver ? 'Assistance' : 'Job';
    }

    return this.member?.offers.find(o => o.offerType === visibleOffer);
  }

  get references(): Reference[] {
    return this.member.references;
  }

  get _memberRole(): RelationRole {
    return this.auth.isGiver ? 'Employee' : 'Employer';
  }

  get memberLanguages(): Array<SelectionTag> {
      const uniqLanguages = [
        ...new Map(
          this.member.memberLanguages.map(item => [item['key'], item])
        ).values()
      ];

      return uniqLanguages;
  }

  get companionName(): string {
    const secondName = this.member.hasPrivilegedData ? ' ' + this.member.lastName : '';
    return `${this.member.firstName} ${secondName}`.trim();
  }

  back(): void {
    this.location.back();
  }

  downloadDocument(doc: Document, $event: MouseEvent) {
    $event.preventDefault();
    const url = this.documentUrl(doc);
    this.profileService.downloadDocument(url);
  }

  documentUrl(doc: Document) {
    return documentDownloadUrl(this.member.id, doc.path);
  }
}
