import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { IdentityService } from 'src/app/identity/identity.service';
import { ModalRef } from 'src/app/ui-library/modal/modal-ref';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {
  isLoading$ = new BehaviorSubject<boolean>(false);
  currentEmail = '';

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    confirmEmail: new FormControl('', [Validators.required])
  }, { validators: [this.checkConfirmEmail()]});

  constructor(
    private identity: IdentityService,
    private profile: ProfileService,
    private modal: ModalRef) { }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get confirmEmail(): AbstractControl {
    return this.form.get('confirmEmail');
  }

  ngOnInit(): void {
    this.currentEmail = this.modal.data.email;
  }

  submit() {
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.email.disable();
    this.confirmEmail.disable();
    this.isLoading$.next(true);

    this.profile.changeEmail(this.email.value)
      .then(() => {
        this.email.enable();
        this.confirmEmail.enable();
        this.isLoading$.next(false);
        this.modal.close();
        this.identity.logout();
      })
      .catch(() => {
        this.email.enable();
        this.confirmEmail.enable();
        this.isLoading$.next(false);
      });
  }

  cancel() {
    this.modal.close();
  }

  private checkConfirmEmail(): ValidatorFn {
    return (group: AbstractControl): {[key: string]: any} | null => {
      const email = group.get('email').value;
      const confirmEmail = group.get('confirmEmail').value;

      return email === confirmEmail ? null : { unconfirmedEmail: { value: confirmEmail }};
    };
  }
}
