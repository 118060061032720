<section *ngIf="readyForTeam || isInTeam" class="team-status">
  <h2 class="team-status__title" i18n="@@employment.team.teamStatus">Team-Status</h2>

  <ng-container *ngIf="readyForTeam">
    <p class="team-status__p" i18n="@@employment.team.contentTeamStatus">Sie können andere Personen in ihr Team einladen. Wenn die andere Person die Einladung annimmt, können beide Parteien die Informationen im erweiterten Profil sehen.</p>

    <button class="button secondary" (click)="ivite()" type="submit" [disabled]="(isLoading$ | async) !== false">
      <span class="icon icon-plus" aria-hidden="true"></span>
      <ng-container i18n="@@employment.team.btnInviteToTeam">In Team einladen</ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="isInTeam">
    <p class="team-status__p" i18n="@@employment.team.contentPersonInTeam">Personen die in Ihrem Team sind, haben automatisch Zugriff auf Ihr erweitertes Profil, um z. B. Kontaktdaten zu sehen.</p>

    <div class="info-label info-label--green">
      <div class="info-label__icon">
        <span class="icon icon-check" aria-hidden="true"></span>
      </div>
      <div class="info-label__text" i18n="@@employment.team.inTeam">{{ companionName }} ist Mitglied in Ihrem Team</div>
    </div>

    <button class="button warning revoke-access-btn" (click)="removeFromTeam()" type="button" [disabled]="(isLoading$ | async) !== false">
      <ng-container *ngIf="!isPA" i18n="@@employment.conversation.team.btnRemoveFromTeam">Aus dem Team entfernen</ng-container>
      <ng-container *ngIf="isPA" i18n="@@employment.conversation.team.btnPARemoveFromTeam">Aus dem Team austreten</ng-container>
    </button>
  </ng-container>
</section>
