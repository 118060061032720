import { Component, OnInit, ElementRef, Query, ViewChild } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { AuthService } from '../../identity/auth.service';
import { ProfileService } from '../../profile/profile.service';
import { IdentityService } from 'src/app/identity/identity.service';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { EmploymentService } from 'src/app/employment/employment.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  @ViewChild('profileMenuList') profileMenuList: ElementRef;

  isProfileMenuOpen = false;

  constructor(
    public auth: AuthService,
    private profileService: ProfileService,
    public identity: IdentityService,
    public employment: EmploymentService,
    private modal: ModalService,
    public overlay: Overlay) { }

  ngOnInit(): void {
  }

  get isProfileActive(): boolean {
    return this.profileService.isActive;
  }

  set isProfileActive(value: boolean) {
    this.profileService.isActive = value;
  }

  updateIsProfileActive(event: any) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    this.isProfileActive = !this.isProfileActive;
  }

  overlayKeydown(evt: KeyboardEvent): void {
    if (evt.key === 'Escape') {
      this.isProfileMenuOpen = false;
    }
  }

  arrowDownOnMenu(evt: KeyboardEvent): void {
    evt.preventDefault();
    this.gotoNext();
  }

  arrowUpOnMenu(evt: KeyboardEvent): void {
    evt.preventDefault();
    this.gotoPrevious();
  }

  login(): void {
    this.identity.loginWithCheckToken();
  }

  private gotoPrevious(): void {
    const index = this.findCurrentIndex();
    const list = this.profileMenuListItems();
    const nextIndex = index === 0 ? list.length - 1 : index - 1;
    list[index].tabIndex = -1;
    list[nextIndex].tabIndex = 0;
    list[nextIndex].focus();
  }

  private gotoNext(): void {
    const index = this.findCurrentIndex();
    const list = this.profileMenuListItems();
    const nextIndex = index === list.length - 1 ? 0 : index + 1;
    list[index].tabIndex = -1;
    list[nextIndex].tabIndex = 0;
    list[nextIndex].focus();
  }

  private profileMenuListItems(): any {
    return Array.from<any>(this.profileMenuList.nativeElement.querySelectorAll('li > *'));
  }

  private findCurrentIndex(): number {
    return this.profileMenuListItems().findIndex(v => v.tabIndex === 0);
  }
}
