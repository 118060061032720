import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
 } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IdentityService } from './identity/identity.service';
import { ModalService } from './ui-library/modal/modal.service';
import { I18nService } from './i18n.service';

 export class HttpErrorInterceptor implements HttpInterceptor {
   constructor(
     private modal: ModalService,
     private identity: IdentityService,
     private i18nService: I18nService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = request.clone({
      headers: request.headers.set('Content-Language', this.i18nService.siteLocale.code),
    });

    return next.handle(modifiedReq)
      .pipe(
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Fehler: ${error.error.message}`;
          } else {
            // server-side error
            if (error.status === 401 || error.status === 403) {
              console.log('Unauthorized from API call => redirect to login page');
              this.identity.login();
            } else {
              errorMessage = `Fehlercode: ${error.status}\nFehlermeldung: ${error.error}`;
            }
          }
          if (error.status === 422 || error.status === 413) {
            this.modal.showErrorBox($localize`:@@popup.error.bigFile:Datei zu gross!`);
          } else {
            this.modal.showErrorBox(errorMessage);
          }
          return throwError(errorMessage);
        })
      );
  }
 }
