import { Component, Input, OnInit } from '@angular/core';
import { Relation } from 'src/app/employment/employment.model';

@Component({
  selector: 'app-employment-state',
  templateUrl: './employment-state.component.html',
  styleUrls: ['./employment-state.component.scss']
})
export class EmploymentStateComponent implements OnInit {
  @Input() relation: Relation;
  @Input() unreadMessages: number;

  constructor() { }

  ngOnInit(): void {
  }

  get isActionRequired(): boolean {
    return this.answerNecessary || this.contactDataShared;
  }

  get answerNecessary(): boolean {
    return this.relation
      && !this.isInTeam
      && this.relation.allowedAction === 'ShareContactsOrDecline';
  }

  get sharingRequested(): boolean {
    return this.relation
      && !this.isInTeam
      && (this.relation.allowedAction === 'None'
        || this.answerNecessary);
  }

  get contactDataShared(): boolean {
    return this.relation
      && !this.isInTeam
      && (this.relation.allowedAction === 'AddToTeamOrDecline'
      || this.relation.allowedAction === 'Decline');
  }

  get isInTeam(): boolean {
    return this.relation
      && this.relation.inTeam;
  }

  get isNewMessages(): boolean {
    return !!this.unreadMessages;
  }

  get isPA(): boolean {
    return !!(this.relation && this.relation.memberRole === 'Employer');
  }

}
