<a [routerLink]="['/marketplace', member.id]" class="container" [class.action-required]="state.isActionRequired">
  <header class="header">
      <h3 class="heading">{{member.firstName}} {{member.lastName}}</h3>
    <app-employment-state #state [relation]="memberRelation.relation" [unreadMessages]="unreadMessages"></app-employment-state>
  </header>
  <figure class="avatar" aria-hidden="true">
    <img [src]="member.profilePicture.path" alt="">
  </figure>
  <div *ngIf="member.phoneNumber || member.emailAddress" class="job-request-contacts">
    <span *ngIf="member.phoneNumber" class="job-request-contact">
      <span class="sr-only" i18n="sr-only@@employment.teamMember.telefon">Telefon:</span>
      <a href="tel:{{ member.phoneNumber }}">
        <span class="icon icon-phone"></span>
        {{ member.phoneNumber }}
      </a>
    </span>
    <span *ngIf="member.emailAddress" class="job-request-contact">
      <span class="sr-only" i18n="sr-only@@employment.teamMember.email">E-Mail:</span>
      <a href="mailto:{{ member.emailAddress }}">
        <span class="icon icon-mail"></span>
        {{ member.emailAddress }}
      </a>
    </span>
  </div>
  <div>
    <span class="sr-only" i18n="sr-only@@employment.jobRequest.assistanceServices">Assistenzdienstleistungen:</span>
    <app-tag-list [tags]="offer.assistanceServices"></app-tag-list>
  </div>
</a>
