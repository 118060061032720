import { Member } from '../marketplace/marketplace.model';

export interface Relation {
  memberId: string;
  memberRole: RelationRole;
  allowedAction: AllowedAction;
  inTeam: boolean;
  conversation: Message[];
}

export interface MemberRelationData {
  member: Member;
  relation: Relation | null;
  unreadMessages: number;
  lastMessageTimeStamp: string | null;
}

export class MemberRelation implements MemberRelationData {
  member: Member;
  relation: Relation | null;
  unreadMessages: number;
  lastMessageTimeStamp: string|null;

  public constructor(init: Partial<MemberRelationData>) {
    Object.assign(this, init);
  }

  get isInTeam(): boolean {
    return this.relation && this.relation.inTeam;
  }

  get isOpenRequest(): boolean {
    return !this.isInTeam && this.relation && (this.relation.allowedAction === 'None'
    || this.relation.allowedAction === 'ShareContactsOrDecline'
    || this.relation.allowedAction === 'AddToTeamOrDecline'
    || this.relation.allowedAction === 'Decline');
  }
}

export const MESSAGE_TYPE_RECEIVED = 'Received';
export const MESSAGE_TYPE_SENT = 'Sent';
export const MESSAGE_TYPE_SYSTEM = 'System';
export const DATATYPE_TEXT = 'text';

export type MessageType = typeof MESSAGE_TYPE_RECEIVED | typeof MESSAGE_TYPE_SENT | typeof MESSAGE_TYPE_SYSTEM;
export type RelationRole = 'Employer' | 'Employee';
export type AllowedAction = 'None'
 | 'Request'
 | 'ShareContactsOrDecline'
 | 'AddToTeamOrDecline'
 | 'Decline'
 | 'RemoveFromTeam'
 | 'NoneAfterRelationTerminated';
 // this is a dummy value not known to the backend to handle team left state correctly.
 // In the future we should add the current state to the relation as well.

export interface Message {
  timestamp: Date;
  text: string;
  messageType: MessageType;
}

export interface MultiMessage {
  createdOn: Date;
  dataType: typeof DATATYPE_TEXT;
  messageType: MessageType;
  data: {
    text: string;
  };
}

export interface MultiMessagePost {
  dataType: typeof DATATYPE_TEXT;
  data: {
    text: string;
  };
}
export interface MessagesResult {
  messages: Array<MultiMessage>;
}
