import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { EmploymentService } from '../../employment/employment.service';
import { Relation, RelationRole } from '../../employment/employment.model';

@Component({
  selector: 'app-team-status',
  templateUrl: './team-status.component.html',
  styleUrls: ['./team-status.component.scss']
})
export class TeamStatusComponent implements OnInit {

  @Input() relation: Relation;
  @Input() companionName: string;

  public isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private _employment: EmploymentService, private modal: ModalService) {
  }

  ngOnInit(): void {
  }

  get isInTeam(): boolean {
    return this.relation
      && this.relation.inTeam;
  }

  get readyForTeam(): boolean {
    return this.relation && this.relation.allowedAction === 'AddToTeamOrDecline';
  }

  get readyForDecline(): boolean {
    return this.relation && this.relation.allowedAction === 'Decline';
  }

  get readyForTeamRemoval(): boolean {
    return this.relation && this.relation.allowedAction === 'RemoveFromTeam';
  }

  get isPA(): boolean {
    return !!(this.relation && this.relation.memberRole === 'Employer');
  }

  private get _myRole(): RelationRole {
    return this.relation && this.relation.memberRole === 'Employee' ? 'Employer' : 'Employee';
  }

  async ivite(): Promise<void> {
    this.isLoading$.next(true);

    this._employment.addToTeam(this.relation).subscribe({
      complete: () => {
        this.isLoading$.next(false);
      },
      error: (ex) => {
        this.isLoading$.next(false);
      }
    });
  }

  async removeFromTeam(): Promise<void> {
    const confirmed = await this.modal.showCustomConfirmation(
      $localize`:@@employment.popup.requestRemoveFromTeamTitle:Person aus Team entfernen?`,
      $localize`:@@employment.popup.requestRemoveFromTeamBody:Wenns sie Bestätigen, wird die Person aus dem Team entfernt. Der gegenseitige Zugriff auf die vollständigen Profile wird damit auch rückgängig gemacht.`,
      '/assets/images/illustration-58.svg',
      $localize`:@@employment.conversation.btnRemoveFromTeam:Person entfernen`,
    );

    if (!confirmed) {
      return;
    }

    this.isLoading$.next(true);

    this._employment.removeFromTeam(this._myRole, this.relation).subscribe({
      complete: () => {
        this.isLoading$.next(false);
      },
      error: (ex) => {
        this.isLoading$.next(false);
      }
    });
  }
}
