import { Component, OnInit, Input } from '@angular/core';
import { Member, Offer, Reference, OfferType, Match } from '../marketplace.model';
import { TagStatus } from 'src/app/ui-library/models/selection-tag';
import { AuthService } from 'src/app/identity/auth.service';

@Component({
  selector: 'app-hitlist-item',
  templateUrl: './hitlist-item.component.html',
  styleUrls: ['./hitlist-item.component.scss']
})
export class HitlistItemComponent implements OnInit {
  @Input() match: Match;
  tagStatus: TagStatus = TagStatus.Selected;

  constructor(private auth: AuthService) { }

  get member(): Member {
    return this.match?.member;
  }

  get offer(): Offer {
    const visibleOffer: OfferType = this.auth.isGiver ? 'Job' : 'Assistance';
    return this.member.offers.find(o => o.offerType === visibleOffer);
  }

  get references(): Reference[] {
    return [];
  }

  ngOnInit(): void {
  }
}
