import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SearchResult, SearchRequest, ORDER_BY_DISTANCE } from './marketplace.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { scan, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { mapToFilterCriteria, MarketplaceFormService } from './marketplace-form.service';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  loadedResults$: Observable<SearchResult>;
  isLoading$ = new BehaviorSubject<boolean>(true);
  isMoreLoading$ = new BehaviorSubject<boolean>(false);
  private _searchForm: FormGroup;
  private _calls$ = new Subject<Observable<SearchResult>>();

  constructor(
    private _formService: MarketplaceFormService,
    private profileService: ProfileService,
    private http: HttpClient) {

    this.loadedResults$ = this._calls$.pipe(
      switchMap((v) => v),
      scan((acc, val) => {
        if (val.currentPage === 1) {
          return val;
        } else {
          return {
            totalMatches: val.totalMatches,
            totalPages: val.totalPages,
            currentPage: val.currentPage,
            matches: acc.matches.concat(val.matches)
          };
        }
      }),
      shareReplay(1));
  }

  getSearchForm(): FormGroup {
    this._searchForm = this._formService.createSearchForm();
    this._searchForm.valueChanges
      .subscribe((v) => {
        this._performSearch(v, 1);
      });

    return this._searchForm;
  }

  loadPage(page: number, loadMore: boolean = false): void {
    if (this._searchForm) {
      const values = this._searchForm.value;
      this._performSearch(values, page, loadMore);
    }
  }

  private _performSearch(formValues: any, page: number, loadMore: boolean = false): void {
    if (!this.profileService._member) {
      return;
    }

    const criteria = mapToFilterCriteria(formValues);
    const request: SearchRequest = {
      criteria,
      orderBy: formValues.sortField,
      sortDirection: formValues.sortField === ORDER_BY_DISTANCE ? 'Asc' : 'Desc',
      page
    };
    const params = new HttpParams()
      .append('criteria', JSON.stringify(request.criteria))
      .append('sortDirection', request.sortDirection)
      .append('orderBy', request.orderBy)
      .append('page', request.page.toString());

    this.isLoading$.next(true);
    if (loadMore) {
      this.isMoreLoading$.next(true);
    }
    const search$ = this.http
      .get<SearchResult>(`/api/marketplace/members/search`, { params }).pipe(
        tap(() => {
          this.isLoading$.next(false);
          this.isMoreLoading$.next(false);
        })
      );  // TODO error handling
    this._calls$.next(search$);
  }
}
