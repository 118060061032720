import { Component, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { SelectionTagKeyType } from '../models/selection-tag';

@Component({
  selector: 'app-segment-button',
  templateUrl: './segment-button.component.html',
  styleUrls: ['./segment-button.component.scss']
})
export class SegmentButtonComponent {
  @Input() isChecked = false;
  @Input() value: SelectionTagKeyType;
  @Input() name: string;
  @Output() click = new EventEmitter<SelectionTagKeyType>();

  @ViewChild('button') buttonElement: ElementRef;

  check(): void {
    this.click.emit(this.value);
    this.buttonElement.nativeElement.focus();
  }

}
