import { Component, OnInit } from '@angular/core';
import { ModalRef } from 'src/app/ui-library/modal/modal-ref';

@Component({
  selector: 'app-contact-shared',
  templateUrl: './contact-shared.component.html',
  styleUrls: ['./contact-shared.component.css']
})
export class ContactSharedComponent implements OnInit {

  constructor(private modal: ModalRef) { }

  ngOnInit(): void {
  }

  ok(): void {
    this.modal.close();
  }

}
