<main class="layout-profile-sidebar">
  <header class="breadcrumb">
    <button class="button tertiary d-none d-sm-flex" (click)="back()">
      <span class="icon icon-arrow-left"></span>
      <ng-container i18n="@@marketplace.memberDetail.return">Zurück</ng-container>
    </button>
  </header>
  <article class="layout-ignore">
    <h1 class="sr-only" i18n="sr-only@@marketplace.memberDetail.title">Profildetails</h1>
    <aside class="sidebar">
      <nav>
        <h2 class="sr-only" i18n="sr-only@@marketplace.memberDetail.sidebar">Seitenleiste</h2>
        <ul class="navbar-nav vertical">
          <li class="nav-item" [class.link-active]="(activeFragment | async) === null || (activeFragment | async) === 'profile'">
            <a [routerLink]='"."' [replaceUrl]="true" fragment="profile" class="nav-link" i18n="@@marketplace.memberDetail.link.profile">Profil</a>
          </li>
          <li *ngIf="relation" class="nav-item" [class.link-active]="(activeFragment | async) === 'messages'">
            <a [routerLink]='"."' [replaceUrl]="true" fragment="messages" class="nav-link" i18n="@@marketplace.memberDetail.link.news">Nachrichten</a>
          </li>
        </ul>
      </nav>

      <div *ngIf="member && !myProfile" class="sidebar-section d-none d-sm-flex">
        <h2 class="sr-only" i18n="sr-only@@marketplace.memberDetail.titleActions">Aktionen</h2>
        <ul class="navbar-nav vertical">
          <li class="nav-item">
            <a class="nav-link"
              i18n="@@marketplace.memberDetail.link.reportAbuse"
              [href]="emailReportAbuse"
            >
              Missbrauch melden
            </a>
          </li>
        </ul>
      </div>
    </aside>
    <div *ngIf="member" class="layout-ignore">
      <section id="profile" class="summary">
        <div class="summary">
          <figure class="avatar" aria-hidden="true">
            <img [src]="member.profilePicture.path" alt="">
          </figure>
          <div class="summary-content">
            <div class="header">
              <h2 class="heading">{{ companionName }}</h2>
              <app-employment-state [relation]="relation"></app-employment-state>
            </div>
            <h3 class="sr-only" i18n="sr-only@@marketplace.memberDetail.profile">Profil</h3>
            <p>
              <span *ngIf="offer.workPerimeter">{{offer.workPerimeter.zip}} {{offer.workPerimeter.place}}</span>
              <span *ngIf="offer.workPerimeter && offer.workPerimeter.radiusM !== 0">,
                <ng-container i18n="@@marketplace.memberDetail.maxDistanceToWork">Arbeitsweg maximal {{offer.workPerimeter.radiusM / 1000}} km</ng-container>
              </span>
            </p>
            <p *ngIf="member.aboutMe">
              {{member.aboutMe}}
            </p>
            <p *ngIf="offer.description">
              {{offer.description}}
            </p>

            <app-tag-list [tags]="offer.assistanceServices" [status]="tagStatus"></app-tag-list>
          </div>
        </div>
      </section>
      <div class="content">
        <section>
          <app-message *ngIf="member.hasPrivilegedData" i18n="@@marketplace.memberDetail.hasPrivilegedData">
            {{member.firstName}} hat Ihnen die Kontaktdaten freigegeben.
          </app-message>
          <table class="details-table">
            <ng-container *ngIf="member.hasPrivilegedData">
              <tr class="private-details">
                <th i18n="@@marketplace.memberDetail.telefon">Telefon</th>
                <td><span class="icon icon-phone" aria-hidden="true"></span>{{member.phoneNumber}}</td>
              </tr>
              <tr class="private-details">
                <th i18n="@@marketplace.memberDetail.email">E-Mail</th>
                <td>
                  <span class="icon icon-mail" aria-hidden="true"></span>
                  <a style="line-break: anywhere;" [href]="member.emailAddress | mailto">{{member.emailAddress}}</a>
                </td>
              </tr>
            </ng-container>
            <tr class="private-details">
              <th *ngIf="auth.isRecipient" i18n="@@marketplace.memberDetail.documents">Dokumente</th>
              <th *ngIf="auth.isGiver" i18n="@@marketplace.memberDetail.dossier">Dossier</th>
              <td>
                <ul>
                  <li *ngFor="let document of member.documents">
                    <span class="icon icon-folder" aria-hidden="true"></span>
                    <a [href]="documentUrl(document)" (click)="downloadDocument(document, $event)">{{document.name}}</a>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th i18n="@@marketplace.memberDetail.age">Alter</th>
              <td>{{member.age}}</td>
            </tr>
            <tr>
              <th i18n="@@marketplace.memberDetail.nativeLanguage">Muttersprache</th>
              <td>{{member.nativeLanguage | stringifySelectionTags}}</td>
            </tr>
            <tr>
              <th i18n="@@marketplace.memberDetail.languages">Sprachen</th>
              <td>{{ memberLanguages | stringifySelectionTags}}</td>
            </tr>
            <tr>
              <th i18n="@@marketplace.memberDetail.mobility">Mobilität</th>
              <td>{{offer.mobility | stringifySelectionTags}}</td>
            </tr>
            <tr>
              <th i18n="@@marketplace.memberDetail.assistantDoctor">Assistenzart</th>
              <td>{{offer.compensation | stringifySelectionTags}}</td>
            </tr>
          </table>
        </section>

        <ng-container *ngIf="relation">
          <app-relation-status [relation]="relation" [companionName]="companionName"></app-relation-status>

          <app-team-status [relation]="relation" [companionName]="companionName"></app-team-status>

          <app-multi-messages [relation]="relation" [companionName]="companionName"></app-multi-messages>
        </ng-container>

        <section *ngIf="member && !myProfile" class="actions form-group d-sm-none">
          <h3 class="sr-only" i18n="sr-only@@marketplace.memberDetail.titleActions">Aktionen</h3>
          <a i18n="@@marketplace.memberDetail.link.reportAbuse" [href]="emailReportAbuse">
            Missbrauch melden
          </a>
        </section>
      </div>
    </div>
  </article>
</main>
