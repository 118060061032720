import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TeamOverviewComponent } from './team-overview/team-overview.component';
import { TeamMemberComponent } from './team-member/team-member.component';
import { JobRequestComponent } from './job-request/job-request.component';
import { EmptyTeamComponent } from './empty-team/empty-team.component';
import { EmploymentRoutingModule } from './employment-routing.module';
import { EmploymentComponent } from './employment/employment.component';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { ConversationComponent } from './conversation/conversation.component';
import { JobsOverviewComponent } from './jobs-overview/jobs-overview.component';
import { NoJobsComponent } from './no-jobs/no-jobs.component';
import { ContactSharedComponent } from './modals/contact-shared/contact-shared.component';
import { InviteePlaceholderComponent } from './invitee-placeholder/invitee-placeholder.component';
import { AppLayoutModule } from '../app-layout/app-layout.module';



@NgModule({
  declarations: [
    TeamOverviewComponent,
    JobsOverviewComponent,
    TeamMemberComponent,
    JobRequestComponent,
    EmptyTeamComponent,
    NoJobsComponent,
    EmploymentComponent,
    ConversationComponent,
    InviteePlaceholderComponent,
    ContactSharedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppLayoutModule,
    UiLibraryModule,
    EmploymentRoutingModule
  ],
  exports: [
    ConversationComponent
  ]
})
export class EmploymentModule { }
