<div class="layout-sidebar">
  <h1 class="sr-only" i18n="sr-only@@marketplace.search.title">Suche</h1>
  <aside class="sidebar">
    <app-search-mask [parentGroup]="form" [unsavedQuery]="unsavedQuery" [isSaving]="isSaving$ | async" (saveQuery)="saveQuery()"></app-search-mask>
  </aside>
  <main class="plain-content">
    <div class="spinner-container" *ngIf="(search.isLoading$ | async) && (search.isMoreLoading$ | async) === false">
      <app-spinner></app-spinner>
    </div>
    <app-hitlist
      [hidden]="(search.isLoading$ | async) && (search.isMoreLoading$ | async) === false"
      [parentGroup]="form"
      [hitlist]="hitlist"
      [unsavedQuery]="unsavedQuery"
      [isSaving]="isSaving$ | async"
      (saveQuery)="saveQuery()"
      (loadMore)="loadMore()"></app-hitlist>
  </main>
</div>
