import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  Member,
  OfferType,
  Perimeter,
  MemberModification,
  OfferModification,
  Location,
  ProfileImageModification, DocumentModification
} from '../marketplace/marketplace.model';
import moment from 'moment-es6';

@Injectable({
  providedIn: 'root'
})
export class ProfileFormService {

  constructor(private fb: FormBuilder) {
  }

  createGiver(member: Member = null): FormGroup {
    const offer = member?.offers.find(o => o.offerType === 'Assistance');
    const location = offer ? new Location(offer.workPerimeter) : undefined;

    return this.fb.group({
      step1: this.fb.group({
        name: [member ? member.lastName : '', Validators.required],
        first: [member ? member.firstName : '', Validators.required],
        profileImage: [member ? member.profilePicture.path : '', Validators.required],
        customProfileImage: customProfileImageFromMember(member),
        aboutMe: [member ? member.aboutMe : '', Validators.maxLength(700)]
      }),
      step2: this.fb.group({
        gender: member?.gender ? member.gender.key : 'ns', // not required because '' == "Keine Angabe"
        nativeLanguage: [member?.nativeLanguage ? member.nativeLanguage.key : '', Validators.required],
        languages: [member?.memberLanguages ? member.memberLanguages.map(l => l.key) : []],
        mobility: [offer?.mobility ? offer.mobility.map(l => l.key) : []],
        phone: [member ? member.phoneNumber : '', Validators.pattern('^((\\+)|0)?[0-9 ]{10,}$')],
        birthdate: [
          member?.dateOfBirth ? moment(member.dateOfBirth).format('YYYY-MM-DD') : undefined,
          [
            Validators.required,
            Validators.pattern('^[12][0-9]{3}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$'),
            allowedAgeValidator()
          ]
        ],
        authorizedToSign: [true],
        documents: [member?.documents ? member.documents : []]
      }),
      step3: this.fb.group({
        isActive: createBooleanString(offer ? offer.isActive : true),
        compensation: offer?.compensation ? offer.compensation.key : 'np', // 'np' == "Beides"
        location: [location, Validators.required],
        range: [location ? location.radiusInKm : 5, [Validators.required, Validators.min(1)]],
        assistanceServices: [offer?.assistanceServices ? offer.assistanceServices.map(l => l.key) : [], Validators.required],
        jobDescription: [offer ? offer.description : '', Validators.maxLength(700)],
        preferredGender: offer ? offer.preferredGender.key : 'np',
      })
    });
  }

  createRecipient(member: Member = null): FormGroup {
    const offer = member?.offers.find(o => o.offerType === 'Job');
    const location = offer ? new Location(offer.workPerimeter) : undefined;

    return this.fb.group({
      step1: this.fb.group({
        name: [member ? member.lastName : '', Validators.required],
        first: [member ? member.firstName : '', Validators.required],
        profileImage: [member ? member.profilePicture.path : '', Validators.required],
        customProfileImage: customProfileImageFromMember(member),
        aboutMe: [member ? member.aboutMe : '', Validators.maxLength(700)]
      }),
      step2: this.fb.group({
        gender: member?.gender ? member.gender.key : 'ns', // not required because '' == "Keine Angabe"
        nativeLanguage: [member?.nativeLanguage ? member.nativeLanguage.key : '', Validators.required],
        languages: [member?.memberLanguages ? member.memberLanguages.map(l => l.key) : []],
        mobility: [offer?.mobility ? offer.mobility.map(l => l.key) : []],
        phone: [member ? member.phoneNumber : '', Validators.pattern('^((\\+)|0)?[0-9 ]{10,}$')],
        birthdate: [
          member?.dateOfBirth ? moment(member.dateOfBirth).format('YYYY-MM-DD') : undefined,
          [
            Validators.required,
            Validators.pattern('^[12][0-9]{3}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$'),
            allowedAgeValidator()
          ]
        ],
        authorizedToSign: [member ? true : false, Validators.requiredTrue],
        documents: [member?.documents ? member.documents : []]
      }),
      step3: this.fb.group({
        isActive: createBooleanString(offer ? offer.isActive : true),
        compensation: offer?.compensation ? offer.compensation.key : 'np', // 'np' == "Beides",
        location: [location, Validators.required],
        range: 0,
        assistanceServices: [offer?.assistanceServices ? offer.assistanceServices.map(l => l.key) : [], Validators.required],
        jobDescription: [offer ? offer.description : '', Validators.maxLength(700)],
        preferredGender: offer ? offer.preferredGender.key : 'np',
      })
    });
  }
}

const allowedAgeValidator = (): ValidatorFn => (control: AbstractControl): {[key: string]: any} | null => {
    const inputDate = moment(control.value);
    if (inputDate.isValid()) {
      const years = moment().diff(inputDate, 'years');
      return years >= 18 && years <= 120 ? null : {allowedAge: {value: control.value}};
    } else {
      return {allowedAge: {value: control.value}};
    }
  };

export interface FileInfo {
  name: string;
  path: string;
  id?: any;
  uppy?: true;
  type?: string;
  markDelete?: true;
}

export const mapToMember = (offerId: string, offerType: OfferType, fg: FormGroup): MemberModification => {
  const val = fg.value;
  return {
    firstName: val.step1.first,
    lastName: val.step1.name,
    profilePicture: {path: val.step1.profileImage},
    emailAddress: '', // TODO JH: Add info once available
    documents: null, // TODO JH: Add info once available
    aboutMe: val.step1.aboutMe,
    gender: createTag(val.step2.gender),
    nativeLanguage: createTag(val.step2.nativeLanguage),
    memberLanguages: val.step2.languages.map((l) => createTag(l)),
    phoneNumber: val.step2.phone,
    dateOfBirth: val.step2.birthdate,
    offer: mapToOffer(offerId, offerType, fg)
  };
};

export const prepareMemberToUpdate = (member: Member, data: object): MemberModification => {
  const memberData: MemberModification = {
    firstName: member.firstName,
    lastName: member.lastName,
    profilePicture: member.profilePicture,
    emailAddress: member.emailAddress,
    documents: member.documents,
    aboutMe: member.aboutMe,
    gender: member.gender,
    nativeLanguage: member.nativeLanguage,
    memberLanguages: member.memberLanguages,
    phoneNumber: member.phoneNumber,
    dateOfBirth: member.dateOfBirth,
    offer: member.offers[0],
  };
  return Object.assign(memberData, data);
};

export const mapToProfileImageModification = (fg: FormGroup): ProfileImageModification => {
  const step1 = fg.get('step1');
  const profileImage = step1.get('profileImage') as FormControl;
  const customProfileImage = step1.get('customProfileImage');
  return {
    type: customProfileImage.value ? 'Custom' : 'Avatar',
    source: profileImage.value
  };
};

const mapToOffer = (offerId: string, offerType: OfferType, fg: FormGroup): OfferModification => {
  const val = fg.value;
  const location = new Location(val.step3.location as Perimeter);
  location.radiusInKm = val.step3.range;
  return {
    id: offerId,
    offerType,
    isActive: (val.step3.isActive === 'true'),
    description: val.step3.jobDescription,
    workPerimeter: location,
    compensation: createTag(val.step3.compensation),
    assistanceServices: val.step3.assistanceServices.map((s) => createTag(s)),
    languages: val.step2.languages.map((l) => createTag(l)),
    mobility: val.step2.mobility.map((m) => createTag(m)),
    preferredGender: createTag(val.step3.preferredGender)
  };
};

const createBooleanString = (flag: boolean) => flag === true ? 'true' : 'false';

const createTag = (key: string) => ({
    key,
    name: null
  });

export const mapToDocumentModifications = (files: FileInfo[]): DocumentModification[] => files
    .filter(f => f.uppy || f.markDelete)
    .map(f => ({
      name: f.name,
      path: f.path,
      type: f.type,
      modificationType: f.uppy ? 'Add' : 'Remove'
    }));

const customProfileImageFromMember = (m: Member) => !m || !m.profilePicture || m.profilePicture.path.startsWith('assets/')
    ? ''
    : m.profilePicture.path;
