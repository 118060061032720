import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { SelectionTag } from 'src/app/ui-library/models/selection-tag';
import { MarketplaceService } from 'src/app/marketplace/marketplace.service';
import { Location } from 'src/app/marketplace/marketplace.model';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-giver-offer',
  templateUrl: './giver-offer.component.html',
  styleUrls: ['./giver-offer.component.scss']
})
export class GiverOfferComponent implements OnInit {
  @ViewChild('locationSelect') locationSelect: NgSelectComponent;

  @Input() parentGroup: FormGroup;

  availableCompensations$: Observable<SelectionTag[]>;
  availableAssistanceServices$: Observable<SelectionTag[]>;

  locationInput$ = new Subject<string>();
  locations$: Observable<Location[]>;
  locationLoading: boolean;
  readonly linkToFilterDescription: string = $localize`:@@www-clea-app-filter-erklaerung-clea-jobplattform:https://www.clea.app/filter-erklaerung-clea-jobplattform/`;

  constructor(private marketplace: MarketplaceService) { }

  get compensationControl(): AbstractControl {
    return this.parentGroup.controls.compensation;
  }

  get locationControl(): AbstractControl {
    return this.parentGroup.controls.location;
  }

  get rangeControl(): AbstractControl {
    return this.parentGroup.controls.range;
  }

  get assistanceServicesControl(): AbstractControl {
    return this.parentGroup.controls.assistanceServices;
  }

  ngOnInit(): void {
    this.availableCompensations$ = this.marketplace.getAvailableCompensations();
    this.availableAssistanceServices$ = this.marketplace.getAvailableAssistanceServices();

    this.locations$ = concat(
      of([]),
      this.locationInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.locationLoading = true),
        switchMap(term => this.marketplace.queryLocation(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.locationLoading = false)
        ))
      )
    );
  }

  clearLocation() {
    this.locationSelect.clearModel();
  }

}
