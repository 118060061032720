import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ProfileFormService } from '../profile-form.service';
import { GiverProfileComponent } from '../shared/giver-profile/giver-profile.component';
import { IdentityService } from 'src/app/identity/identity.service';
import { ScreenService } from 'src/app/ui-library/screen.service';

// issue: https://github.com/angular/angular/issues/28108

@Component({
  selector: 'app-setup-giver-profile',
  templateUrl: './setup-giver-profile.component.html',
  styleUrls: ['./setup-giver-profile.component.scss']
})
export class SetupGiverProfileComponent implements OnInit, OnDestroy {
  @ViewChild('giverProfile') giverProfile: GiverProfileComponent;

  readonly form: FormGroup;
  readonly step1: FormGroup;
  isTrapActive = false;

  constructor(private identity: IdentityService,
              private route: ActivatedRoute,
              private router: Router,
              private renderer: Renderer2,
              private screenService: ScreenService,
              formService: ProfileFormService) {
    this.isTrapActive = this.screenService.isMobileScreen();
    this.form = formService.createGiver();
    this.step1 = this.form.get('step1') as FormGroup;
  }

  get customProfileImage(): any {
    return this.step1.get('customProfileImage').value;
  }

  ngOnInit() {
    this.identity.handleLoginCallback('/on-boarding/giver-profile');

    // add class to body to prevent scrolling in the background on mobile screen
    this.renderer.addClass(document.body, 'full-screen');

    const formState = window.history.state?.onboardingForm;
    if (formState) {
      this.form.setValue(formState);
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'full-screen');
  }

  async next() {
    if (!this.step1.valid) {
      this.step1.markAllAsTouched();
      return;
    }

    // update profileImage based on cropping...
    if (this.customProfileImage) {
      const croppedImage = await this.giverProfile.croppedImage();
      this.step1.get('profileImage').setValue(croppedImage);
      this.step1.get('customProfileImage').setValue(croppedImage);
    }

    this.router.navigate(['../giver-profile-details'], {
      relativeTo: this.route,
      state: {
        onboardingForm: this.form.value
      }
    });
  }
}
