<h1 class="sr-only" i18n="sr-only@@topNavbar.title">Kopfzeile</h1>
<nav class="navbar">
  <div class="navbar-logo">
    <div class="brand" [class.large]="!auth.isLoggedIn">
      <img src="assets/images/logo.svg" i18n-alt="Alt text@@topNavbar.logo" alt="CléA Logo">
    </div>
  </div>

  <button
    type="button"
    *ngIf="!auth.isLoggedIn"
    class="link login login-button-header"
    (click)="login()"
  >
    <span class="icon icon-user"></span>
    <ng-container i18n="@@header.btn.login">Bereits ein Konto?</ng-container>
  </button>

  <ng-container *ngIf="auth.isLoggedIn">
    <div class="navbar-main">
      <h2 class="sr-only" i18n="sr-only@@topNavbar.mainNavigation">Hauptnavigation</h2>
      <ul class="navbar-nav">
        <li class="nav-item">
          <div #rlas="routerLinkActive"
            [routerLinkActive]="['link-active']"
            [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/marketplace']">
              <ng-container i18n="@@topNavbar.link.search">Suche</ng-container>
              <span *ngIf="rlas.isActive" class="sr-only" i18n="sr-only@@topNavbar.linkActiveState">(aktiv)</span>
            </a>
          </div>
        </li>
        <li class="nav-item">
          <div #rlat="routerLinkActive"
            [class.has-badge]="employment.countOpenRequestsAndNewMessages"
            [routerLinkActive]="['link-active']"
            [routerLinkActiveOptions]="{ exact: true }">
            <a *ngIf="auth.isRecipient" class="nav-link" [routerLink]="['/team']">
              <ng-container i18n="@@topNavbar.link.myTeam">Mein Team</ng-container>
              <span *ngIf="rlat.isActive" class="sr-only" i18n="sr-only@@topNavbar.linkActiveState">(aktiv)</span>
            </a>
            <a *ngIf="auth.isGiver" class="nav-link" [routerLink]="['/jobs']">
              <ng-container i18n="@@topNavbar.link.myJobs">Meine Jobs</ng-container>
              <span *ngIf="rlat.isActive" class="sr-only" i18n="sr-only@@topNavbar.linkActiveState">(aktiv)</span>
            </a>
          </div>
          <app-badge [pulse]="true" [symbol]="employment.countOpenRequestsAndNewMessages" flavor="alert" i18n="@@badge.unansweredMessages">Unbeantwortete Nachrichten vorhanden</app-badge>
        </li>
      </ul>
    </div>

    <div class="navbar-profile">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link"
            href="https://www.clea.app/faq-zur-clea-assistenzplattform/"
            i18n-href="@@www-clea-app-faq-zur-clea-assistenzplattform"
            target="_blank"
            i18n="@@topNavbar.link.help"
          >Hilfe</a>
        </li>
        <li
          class="nav-item">
          <button
            class="profile-button"
            type="button"
            aria-haspopup="true"
            [attr.aria-expanded]="isProfileMenuOpen"
            aria-controls="profile-menu"
            cdkOverlayOrigin
            #profileMenuTrigger="cdkOverlayOrigin"
            (click)="isProfileMenuOpen = !isProfileMenuOpen"
            (keyup.Space)="isProfileMenuOpen = !isProfileMenuOpen"
            i18n-title="Title text@@topNavbar.link.profileMenu"
            title="Profil Menü">
            <img [src]="auth.userProfile.picture" i18n-alt="Alt text@@topNavbar.link.profileMenu" alt="Profil Menü">
          </button>

          <ng-template
            *ngIf="auth.isLoggedIn"
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="profileMenuTrigger"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'profile-menu-backdrop'"
            [cdkConnectedOverlayOpen]="isProfileMenuOpen"
            (backdropClick)="isProfileMenuOpen=false"
            (overlayKeydown)="overlayKeydown($event)">
            <ul id="profile-menu" #profileMenuList cdkTrapFocus cdkTrapFocusAutoCapture class="navbar-menu" role="menu" (keydown.ArrowDown)="arrowDownOnMenu($event)" (keydown.ArrowUp)="arrowUpOnMenu($event)">
              <li role="none" class="menuitem-info">
                <span class="strong">{{auth.userProfile.given_name}} {{auth.userProfile.family_name}}</span><br>
                <span>{{auth.userProfile.email}}</span>
              </li>
              <li class="p-0">
                <ion-item>
                  <ion-label [class]="isProfileActive ? 'checked' : 'unchecked'">
                    <ng-container *ngIf="isProfileActive" i18n="@@topNavbar.btn.profileEnabled">Profil auffindbar</ng-container>
                    <ng-container *ngIf="!isProfileActive" i18n="@@topNavbar.link.profileDisabled">Profil ist verborgen</ng-container>
                  </ion-label>
                  <ion-toggle (click)="updateIsProfileActive($event)" [checked]="isProfileActive" name="isProfileActive" mode="ios"></ion-toggle>
                </ion-item>
              </li>
              <li>
                <a role="menuitem" [routerLink]="['/marketplace', 'me']" (click)="isProfileMenuOpen=false" class="nav-link" i18n="@@topNavbar.link.viewMyProfile">
                  Mein öffentliches Profil anzeigen
                </a>
              </li>
              <li>
                <a role="menuitem" [routerLink]="['/profile']" (click)="isProfileMenuOpen=false" class="nav-link" i18n="@@topNavbar.link.editMyProfile">
                  Profil bearbeiten
                </a>
              </li>
              <li>
                <button role="menuitem" (click)="identity.logout(); isProfileMenuOpen=false;" class="link nav-link" i18n="@@topNavbar.link.signOut">Abmelden</button>
              </li>
            </ul>
          </ng-template>
        </li>
      </ul>
    </div>
  </ng-container>
</nav>
