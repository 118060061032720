import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-avatar-selector',
  templateUrl: './avatar-selector.component.html',
  styleUrls: ['./avatar-selector.component.scss']
})
export class AvatarSelectorComponent implements OnInit {

  @Input()
  checked: boolean;

  /** Source url of avatar image */
  @Input()
  src: string;

  /** Source Key of avatar image */
  @Input()
  number: string;

  /** Radio button group */
  @Input()
  name: string;

  @Output()
  change = new EventEmitter<any>();

  get avatarTitle(): string {
    return $localize`:@@input.avatar.variant:Avatar Variant #` + this.number;
  }

  constructor() {
  }

  ngOnInit() {
  }

  onChange($event: Event) {
    this.change.emit($event);
  }
}
