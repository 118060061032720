import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { LayoutModule } from '@angular/cdk/layout';

import { AppLayoutModule } from './app-layout/app-layout.module';
import { UiLibraryModule } from './ui-library/ui-library.module';
import { ProfileModule } from './profile/profile.module';
import { MarketplaceModule } from './marketplace/marketplace.module';
import { EmploymentModule } from './employment/employment.module';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AcceptAgbBoxComponent } from './ui-library/modal/accept-agb-box/accpet-agb-box.component';
import { ConfigService } from './config.service';
import { CallbackComponent } from './identity/callback/callback.component';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { AuthService } from './identity/auth.service';
import { IdentityService } from './identity/identity.service';
import { I18nService } from './i18n.service';
import { ModalService } from './ui-library/modal/modal.service';

export const initIdentityServices = (
  http: HttpClient,
  config: ConfigService,
  identity: IdentityService
): (() => Promise<boolean | void>) => (): Promise<boolean | void> => config.init().then(() => identity.init());

export const jwtOptionsFactory = (identity: IdentityService) => ({
    tokenGetter: async () => await identity.getTokenSilently(),
    whitelistedDomains: [
      'localhost:5001',
      'localhost:4200',
      'cleaapp-dev-app.azurewebsites.net',
      'clea.app'],
    blacklistedRoutes: [
      '/api/config',
      '//localhost:5001/api/config',
      '//cleaapp-dev-app.azurewebsites.net/api/config',
      '//assistenzplattform-test.clea.app/api/config', ]
  });

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    PageNotFoundComponent,
    AcceptAgbBoxComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [IdentityService]
      }
    }),
    FormsModule,
    UiLibraryModule,
    AppLayoutModule,
    ProfileModule,
    MarketplaceModule,
    EmploymentModule,
    LayoutModule,
    IonicModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initIdentityServices,
      multi: true,
      deps: [
        HttpClient,
        ConfigService,
        IdentityService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [
        ModalService,
        IdentityService,
        I18nService
      ]
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
