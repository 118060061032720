import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { EmploymentService } from '../../employment/employment.service';
import { Relation, RelationRole } from '../../employment/employment.model';

@Component({
  selector: 'app-relation-status',
  templateUrl: './relation-status.component.html',
  styleUrls: ['./relation-status.component.scss']
})
export class RelationStatusComponent implements OnInit {
  @Input() relation: Relation;
  @Input() companionName: string;

  public isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private _employment: EmploymentService, private modal: ModalService) {
  }

  ngOnInit(): void {
  }

  get readyForRequest(): boolean {
    return !!(this.relation && this.relation.allowedAction === 'Request');
  }

  get sharingRequested(): boolean {
    return this.relation && this.relation.allowedAction === 'None';
  }

  get isInTeam(): boolean {
    return this.relation
      && this.relation.inTeam;
  }

  get answerNecessary(): boolean {
    return this.relation
      && !this.isInTeam
      && this.relation.allowedAction === 'ShareContactsOrDecline';
  }

  get contactDataShared(): boolean {
    return this.relation
      && !this.isInTeam
      && (this.relation.allowedAction === 'AddToTeamOrDecline'
      || this.relation.allowedAction === 'Decline');
  }

  private get _myRole(): RelationRole {
    return this.relation && this.relation.memberRole === 'Employee' ? 'Employer' : 'Employee';
  }

  async request(): Promise<void> {
    const confirmed = await this.modal.showCustomConfirmation(
      $localize`:@@employment.popup.requestTitle:Anfrage senden?`,
      $localize`:@@employment.popup.requestBody:Wenn die andere Person akzeptiert, können beide Parteien das erweiterte Profil der jeweils anderen Person sehen.`,
      '/assets/images/illustration-58.svg',
      $localize`:@@employment.conversation.btnSendRequest:Anfrage senden`,
    );

    if (!confirmed) {
      return;
    }

    this.isLoading$.next(true);

    this._employment.request(this.relation, '').subscribe({
      complete: () => {
        this.isLoading$.next(false);
      },
      error: (ex) => {
        this.isLoading$.next(false);
      }
    });
  }
  async decline(): Promise<void> {
    const confirmed = await this.modal.showCustomConfirmation(
      $localize`:@@employment.popup.requestDeclineTitle:Anfrage ablehnen: Erweitertes Profil anzeigen`,
      ($localize`:@@employment.popup.requestDeclineBody:%s hat Ihnen eine Anfrage für die Freigabe des erweiterten Profils gesendet. Wenn Sie diese akzeptieren, haben beide Parteien Zugriff auf die Daten im erweiterten Profil.`).replace('%s', this.companionName),
      '/assets/images/illustration-58.svg',
      $localize`:@@employment.conversation.btnDeclineInPopup:Ablehnen`,
    );

    if (!confirmed) {
      return;
    }

    this.isLoading$.next(true);

    this._employment.decline(this._myRole, this.relation, '').subscribe({
      complete: () => {
        this.isLoading$.next(false);
      },
      error: (ex) => {
        this.isLoading$.next(false);
      }
    });
  }
  async grant(): Promise<void> {
    const confirmed = await this.modal.showCustomConfirmation(
      $localize`:@@employment.popup.requesGranttTitle:Anfrage: Erweitertes Profil anzeigen`,
      ($localize`:@@employment.popup.requestGrantBody:%s hat Ihnen eine Anfrage für die Freigabe des erweiterten Profils gesendet. Wenn Sie diese akzeptieren, haben beide Parteien Zugriff auf die Daten im erweiterten Profil.`).replace('%s', this.companionName),
      '/assets/images/illustration-58.svg',
      $localize`:@@employment.conversation.btnAccept:Akzeptieren`,
    );

    if (!confirmed) {
      return;
    }

    this.isLoading$.next(true);

    this._employment.grant(this._myRole, this.relation, '').subscribe({
      complete: () => {
        this.isLoading$.next(false);
      },
      error: (ex) => {
        this.isLoading$.next(false);
      }
    });
  }
  async revoke(): Promise<void> {
    const confirmed = await this.modal.showCustomConfirmation(
      $localize`:@@employment.popup.requestRevokeTitle:Vollständiges Profil nicht mehr teilen`,
      $localize`:@@employment.popup.requestRevokeBody:Wenns sie Bestätigen, wird der gegenseitige Zugriff auf die erweiterten Profile rückgängig gemacht.`,
      '/assets/images/illustration-58.svg',
      $localize`:@@employment.conversation.btnRevoke:Nicht mehr teilen`,
    );

    if (!confirmed) {
      return;
    }

    this.isLoading$.next(true);

    this._employment.decline(this._myRole, this.relation, '').subscribe({
      complete: () => {
        this.isLoading$.next(false);
      },
      error: (ex) => {
        this.isLoading$.next(false);
      }
    });
  }

}
