import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Observable, Subject, concat, of, Subscription } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError, map, debounceTime } from 'rxjs/operators';
import { SelectionTag, SelectionTagKeyType } from 'src/app/ui-library/models/selection-tag';
import { MarketplaceService } from '../marketplace.service';
import { Location } from 'src/app/marketplace/marketplace.model';
import { ModalRef } from 'src/app/ui-library/modal/modal-ref';
import { AuthService } from 'src/app/identity/auth.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-advanced-search-mask',
  templateUrl: './advanced-search-mask.component.html',
  styleUrls: ['./advanced-search-mask.component.scss']
})
export class AdvancedSearchMaskComponent implements OnInit, OnDestroy {
  @ViewChild('locationSelect') locationSelect: NgSelectComponent;

  @Input() form: FormGroup;

  availableGenders$: Observable<SelectionTag[]>;
  availableKinds$: Observable<SelectionTag[]>;
  availableAssistanceServices$: Observable<SelectionTag[]>;

  locationInput$ = new Subject<string>();
  locations$: Observable<Location[]>;
  locationLoading: boolean;

  availableLanguages: SelectionTag[] = [];
  initialFavoriteLanguages: SelectionTag[] = [];
  favoriteLanguages: SelectionTag[] = [];

  readonly addSelectedLanguageControl = new FormControl();
  readonly linkToFilterDescription: string = $localize`:@@www-clea-app-filter-erklaerung-clea-jobplattform:https://www.clea.app/filter-erklaerung-clea-jobplattform/`;
  private addLanguageSubscription: Subscription;


  constructor(
    public auth: AuthService,
    private marketplace: MarketplaceService,
    private modal: ModalRef) { }

  get compensationControl(): AbstractControl {
    return this.form.controls.compensation;
  }

  get locationControl(): AbstractControl {
    return this.form.controls.location;
  }

  get rangeControl(): AbstractControl {
    return this.form.controls.range;
  }

  get assistanceServicesControl(): AbstractControl {
    return this.form.controls.assistanceServices;
  }

  get genderControl(): AbstractControl {
    return this.form.controls.preferredGender;
  }

  get languagesControl(): AbstractControl {
    return this.form.controls.languages;
  }

  ngOnInit(): void {
    this.availableGenders$ = this.marketplace.getAvailableGenders()
      .pipe(map(sts => sts.filter(st => st.key !== 'ns'))); // do not show 'not specified' which is not used for search
    this.availableKinds$ = this.marketplace.getAvailableCompensations();
    this.availableAssistanceServices$ = this.marketplace.getAvailableAssistanceServices();
    Promise.all([
      this.marketplace.getAvailableLanguages().toPromise(),
      this.marketplace.getFavoriteLanguages().toPromise()
    ]).then(v => {
        this.availableLanguages = v[0].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.initialFavoriteLanguages = v[1];
        this.updateFavoriteLanguages(this.languagesControl.value);
    });

    this.locations$ = concat(
      of([]),
      this.locationInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.locationLoading = true),
        switchMap(term => this.marketplace.queryLocation(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.locationLoading = false)
        ))
      )
    );

    this.form = this.modal.data;

    this.addLanguageSubscription = this.addSelectedLanguageControl.valueChanges.subscribe((lang: SelectionTag) => {
      const selected = this.languagesControl.value as SelectionTagKeyType[];
      if (!selected.includes(lang.key)) {
        const newSelection = [...selected, lang.key];
        this.updateFavoriteLanguages(newSelection);
        this.languagesControl.setValue(newSelection);
        this.languagesControl.markAsDirty();
      }
      this.addSelectedLanguageControl.setValue(null, {emitEvent: false});
    });
  }

  ngOnDestroy(): void {
    this.addLanguageSubscription.unsubscribe();
  }

  close() {
    this.modal.close();
  }

  updateLocation(newLocation) {
    // This is necessary because otherwise the two location selectors won't stay in sync
    // https://github.com/ng-select/ng-select/issues/1791
    this.locationControl.setValue(newLocation);
  }

  clearLocation() {
    this.locationSelect.clearModel();
  }

  private updateFavoriteLanguages(selected: SelectionTagKeyType[] = []): void {
    let combined = this.initialFavoriteLanguages;

    selected.forEach(s => {
      if (!combined.find(fl => fl.key === s)) {
        const lang = this.availableLanguages.find(fl => fl.key === s);
        combined = [...combined, lang];
      }
    });
    this.favoriteLanguages = combined;
  }
}
