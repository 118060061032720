import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { EmploymentStateComponent } from './employment-state/employment-state.component';
import { RequiredAssistanceServicesComponent } from './modals/required-assistance-services/required-assistance-services.component';



@NgModule({
  declarations: [
    FooterComponent,
    TopNavbarComponent,
    EmploymentStateComponent,
    RequiredAssistanceServicesComponent,
  ],
  exports: [
    FooterComponent,
    TopNavbarComponent,
    EmploymentStateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiLibraryModule,
    OverlayModule,
    A11yModule
  ]
})
export class AppLayoutModule { }
