import { Component, OnInit, Input, ElementRef, Renderer2, AfterViewInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MarketplaceService } from '../../../marketplace/marketplace.service';
import { FileInfo } from '../../profile-form.service';
import { Observable, Subscription } from 'rxjs';
import { SelectionTag, SelectionTagKeyType } from '../../../ui-library/models/selection-tag';
import { map } from 'rxjs/operators';
import { ModalService } from '../../../ui-library/modal/modal.service';
import { documentDownloadUrl, ProfileService } from '../../profile.service';
import * as tus from 'tus-js-client';
import { ScreenService } from 'src/app/ui-library/screen.service';
import { I18nService } from '../../../i18n.service';
@Component({
  selector: 'app-giver-profile-details',
  templateUrl: './giver-profile-details.component.html',
  styleUrls: ['./giver-profile-details.component.scss']
})
export class GiverProfileDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() memberId: string;
  @Input() parentGroup: FormGroup;
  @Input() form: FormGroup;
  @ViewChild('myPond') myPond: any;

  readonly addSelectedLanguageControl = new FormControl();

  genderControl: AbstractControl;
  nativeLanguageControl: AbstractControl;
  languagesControl: AbstractControl;
  mobilityControl: AbstractControl;
  documentsControl: AbstractControl;
  birthdateControl: AbstractControl;

  availableGenders$: Observable<SelectionTag[]>;
  availableMobility$: Observable<SelectionTag[]>;
  availableLanguages: SelectionTag[] = [];
  initialFavoriteLanguages: SelectionTag[] = [];
  favoriteLanguages: SelectionTag[] = [];

  pondOptions = {
    class: 'my-filepond',
    multiple: true,
    labelIdle: $localize`:@@input.uploadfile:Dateien hier hinziehen oder <span class="filepond--link">auswählen</span><br><span class="info">Maximal 10 MB pro Datei</span><span aria-hidden="true" class="icon icon-arrow-up-circle"></span>`,
    labelFileProcessing: $localize`:@@input.uploadfile.uploading:Uploading`,
    labelTapToCancel: $localize`:@@input.uploadfile.tapToCancel:tap to cancel`,
    labelTapToRetry: $localize`:@@input.uploadfile.tapToRetry:tap to retry`,
    server: {
      load: `/api/marketplace/documents/`,
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        const upload = new tus.Upload(file, {
          headers: {
            'Content-Language': this.i18nService.siteLocale.code,
          },
          endpoint: '/api/files/',
          removeFingerprintOnSuccess: true,
          retryDelays: [0, 1000, 3000, 5000],
          onError: (err) => {
            this.uploadError(file, err);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            progress(true, bytesUploaded, bytesTotal);
          },
          onSuccess: () => {
            load(upload.url.split('/').pop());
            this.uploadSuccess(file, upload.url);
          }
        });
        // Start the upload
        upload.start();
        return {
          abort: () => {
            upload.abort();
            abort();
          }
        };
      }
    }
  };

  private addLanguageSubscription: Subscription;

  get visibleDocuments(): FileInfo[] {
    return (this.documentsControl.value as FileInfo[]).filter(d => !d.markDelete);
  }

  get phone(): AbstractControl {
    return this.parentGroup.get('phone');
  }

  constructor(
    private el: ElementRef,
    private marketplaceService: MarketplaceService,
    private profileService: ProfileService,
    private modalService: ModalService,
    private renderer: Renderer2,
    private screenService: ScreenService,
    private http: HttpClient,
    private i18nService: I18nService,
  ) {
  }

  ngOnInit(): void {
    this.genderControl = this.parentGroup.controls.gender;
    this.nativeLanguageControl = this.parentGroup.controls.nativeLanguage;
    this.languagesControl = this.parentGroup.controls.languages;
    this.mobilityControl = this.parentGroup.controls.mobility;
    this.documentsControl = this.parentGroup.controls.documents;
    this.birthdateControl = this.parentGroup.controls.birthdate;

    this.availableGenders$ = this.marketplaceService.getAvailableGenders()
      .pipe(map(sts => sts.filter(st => st.key !== 'np'))); // do not show 'no preference' which is only used for search
    this.availableMobility$ = this.marketplaceService.getAvailableMobilities();
    Promise.all([
      this.marketplaceService.getAvailableLanguages().toPromise(),
      this.marketplaceService.getFavoriteLanguages().toPromise()
    ]).then(v => {
      this.availableLanguages = v[0].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      this.initialFavoriteLanguages = v[1];
      this.updateFavoriteLanguages(this.languagesControl.value);
    });

    this.addLanguageSubscription = this.addSelectedLanguageControl.valueChanges.subscribe((lang: SelectionTag) => {
      const selected = this.languagesControl.value as SelectionTagKeyType[];
      if (!selected.includes(lang.key)) {
        const newSelection = [...selected, lang.key];
        this.updateFavoriteLanguages(newSelection);
        this.languagesControl.setValue(newSelection);
        this.languagesControl.markAsDirty();
      }
      this.addSelectedLanguageControl.setValue(null, {emitEvent: false});
    });
  }

  ngOnDestroy(): void {
    this.addLanguageSubscription.unsubscribe();
  }

  ngAfterViewInit() {

  }

  removeDocument(doc: FileInfo) {
    const docs = this.documentsControl.value as FileInfo[];
    // if this is a newly uploaded document, then request deletion right away
    if (doc.uppy) {
      this.http.delete(doc.path, {
        headers: {'Tus-Resumable': '1.0.0'}
      }).subscribe();
      this.documentsControl.setValue(docs.filter((d) => d.id !== doc.id));
    } else {
      // ... just mark the document for deletion and hide it
      doc.markDelete = true;
      this.documentsControl.setValue([...docs]);
    }
    this.documentsControl.markAsDirty();
  }

  downloadDocument(doc: FileInfo, $event: MouseEvent) {
    $event.preventDefault();

    const url = this.documentUrl(doc);
    this.profileService.downloadDocument(url);
  }

  documentUrl(doc: FileInfo) {
    if (doc.uppy) {
      return doc.path;
    } else {
      return documentDownloadUrl(this.memberId, doc.path);
    }
  }

  uploadError(file, error) {
    this.documentsControl.setErrors(null);
    this.documentsControl.markAsUntouched();
    if (error.errorShown) {
      return;  // hack to prevent showing error twice...
    }

    error.errorShown = true;
    this.myPond.removeFile(file);
    if (error.message.indexOf(': 413') >= 0) {
      this.modalService.showErrorBox($localize`:@@popup.error.title.bigFile:Datei zu gross!`);
    } else if (error.message.indexOf('failed to upload chunk at offset 0') >= 0) {
      const errorMessage = $localize`:@@popup.error.content.cloudIssue:Es besteht seitens Google Drive ein Fehler für Android, welchen wir nicht beheben können. Sie können Ihre Anmeldung ohne Dokumente über das Smartphone abschliessen. Loggen Sie sich anschliessend über Tablet oder Desktop mit Ihrem Profil ein und laden die gewünschten Unterlagen hoch. Sobald Google eine Lösung anbietet, werden wir diese umsetzen. Wir danken für Ihr Verständnis.`;
      this.modalService.showErrorBox(errorMessage, $localize`:@@popup.error.title.uploadDocuments:Dokumente später hochladen`);
    } else {
      const errorMessage = $localize`:@@popup.error.content.mainIssue:Fehler beim Upload: ` + error.message;
      this.modalService.showErrorBox(errorMessage);
    }
  }

  uploadSuccess(file, uploadUrl: string) {
    this.addDocumentFilePond(file, uploadUrl);
    this.myPond.removeFile(file);
    this.documentsControl.setErrors(null);
    this.documentsControl.markAsUntouched();
  }

  async onChange(event) {
    const input = event.target as HTMLInputElement;
    input.parentElement.classList.add('loading');
    const files = input.files;
    for (let i = 0; i < files.length; i++) {
      await this.uploadFile(files[i]);
    }

    input.value = '';
    input.parentElement.classList.remove('loading');
  }

  async uploadFile(file, error = null): Promise<string> {
    const formData = new FormData();
      formData.append('file', file, file.name);
      let response;
      try {
        response = await this.http.post('/api/files/u', formData, {
          observe: 'response'
        }).toPromise();

      } catch (err) {
        return;
      }

      const url = response.headers.get('location');
      this.uploadSuccess(file, url);
      return url;
  }

  private updateFavoriteLanguages(selected: SelectionTagKeyType[] = []): void {
    let combined = this.initialFavoriteLanguages;

    selected.forEach(s => {
      if (!combined.find(fl => fl.key === s)) {
        const lang = this.availableLanguages.find(fl => fl.key === s);
        combined = [...combined, lang];
      }
    });
    this.favoriteLanguages = combined;
  }

  private addDocumentFilePond(file, uploadUrl: string) {
    const docs = this.documentsControl.value as FileInfo[];
    this.documentsControl.setValue([...docs, {
      id: file.name,
      uppy: true,
      name: file.name,
      type: file.type,
      path: uploadUrl
    }]);
    this.documentsControl.markAsDirty();
  }
}
