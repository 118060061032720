import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  mapToMember,
  ProfileFormService,
  mapToProfileImageModification,
  mapToDocumentModifications
} from '../profile-form.service';
import { FormGroup } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { BehaviorSubject } from 'rxjs';
import { ScreenService } from 'src/app/ui-library/screen.service';

@Component({
  selector: 'app-setup-giver-offer',
  templateUrl: './setup-giver-offer.component.html',
  styleUrls: ['./setup-giver-offer.component.scss']
})
export class SetupGiverOfferComponent implements OnInit, OnDestroy {
  readonly form: FormGroup;
  readonly step3: FormGroup;
  isTrapActive = false;
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private renderer: Renderer2,
              private screenService: ScreenService,
              private profileService: ProfileService,
              formService: ProfileFormService) {
    this.isTrapActive = this.screenService.isMobileScreen();
    this.form = formService.createGiver();
    this.step3 = this.form.get('step3') as FormGroup;
  }

  ngOnInit() {
    // add class to body to prevent scrolling in the background on mobile screen
    this.renderer.addClass(document.body, 'full-screen');

    const formState = window.history.state?.onboardingForm;
    if (formState) {
      this.form.setValue(formState);
    } else {
      this.router.navigate(['../giver-profile'], {
        relativeTo: this.route
      });
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'full-screen');
  }

  back() {
    this.router.navigate(['../giver-profile-details'], {
      relativeTo: this.route,
      state: this.navigateState
    });
  }

  submitForm() {
    if (this.form.valid) {
      this.isLoading$.next(true);
      const member = mapToMember(null, 'Assistance', this.form);
      const profileImageMod = mapToProfileImageModification(this.form);
      const docMods = mapToDocumentModifications(this.form.value.step2.documents);
      this.profileService.registerGiver(member, profileImageMod, docMods).then(
        () => {
          this.isLoading$.next(false);
          console.log('Successfully registered');
          this.router.navigate(['/marketplace'], { relativeTo: this.route, state: { showWelcome: true } });
        },
        (err) => {
          this.isLoading$.next(false);
          console.log('Registration failed', err);
        }
      );
    } else {
      this.step3.markAllAsTouched();
    }
  }

  get navigateState() {
    return {
      onboardingForm: this.form.value
    };
  }
}
