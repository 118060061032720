import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { SearchMaskComponent } from './search-mask/search-mask.component';
import { HitlistComponent } from './hitlist/hitlist.component';
import { AdvancedSearchMaskComponent } from './advanced-search-mask/advanced-search-mask.component';
import { HitlistItemComponent } from './hitlist-item/hitlist-item.component';
import { MemberDetailComponent } from './member-detail/member-detail.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MarketplaceRoutingModule } from './marketplace-routing.module';
import { NoResultsComponent } from './no-results/no-results.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ReferenceListComponent } from './reference-list/reference-list.component';
import { MessagesComponent } from './messages/messages.component';
import { HttpClientModule } from '@angular/common/http';
import { EmploymentModule } from '../employment/employment.module';
import { ConversationComponent } from '../employment/conversation/conversation.component';
import { AppLayoutModule } from '../app-layout/app-layout.module';
import { MultiMessagesComponent } from './multi-messages/multi-messages.component';
import { RelationStatusComponent } from './relation-status/relation-status.component';
import { TeamStatusComponent } from './team-status/team-status.component';



@NgModule({
  declarations: [
    SearchComponent,
    SearchMaskComponent,
    HitlistComponent,
    AdvancedSearchMaskComponent,
    HitlistItemComponent,
    MemberDetailComponent,
    MarketplaceComponent,
    NoResultsComponent,
    ReferenceListComponent,
    MessagesComponent,
    MultiMessagesComponent,
    RelationStatusComponent,
    TeamStatusComponent
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    UiLibraryModule,
    ReactiveFormsModule,
    HttpClientModule,
    MarketplaceRoutingModule,
    EmploymentModule,
    AppLayoutModule
  ]
})
export class MarketplaceModule { }
