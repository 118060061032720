import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { RecipientProfileComponent } from '../shared/recipient-profile/recipient-profile.component';
import { ProfileFormService } from '../profile-form.service';
import { IdentityService } from 'src/app/identity/identity.service';
import { ScreenService } from 'src/app/ui-library/screen.service';

@Component({
  selector: 'app-setup-recipient-profile',
  templateUrl: './setup-recipient-profile.component.html',
  styleUrls: ['./setup-recipient-profile.component.scss']
})
export class SetupRecipientProfileComponent implements OnInit, OnDestroy {
  @ViewChild('recipientProfile') recipientProfile: RecipientProfileComponent;

  readonly form: FormGroup;
  readonly step1: FormGroup;
  isTrapActive = false;

  get customProfileImage(): any {
    return this.step1.get('customProfileImage').value;
  }

  constructor(
    private identity: IdentityService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private screenService: ScreenService,
    formService: ProfileFormService) {
    this.isTrapActive = this.screenService.isMobileScreen();
    this.form = formService.createRecipient();
    this.step1 = this.form.get('step1') as FormGroup;
  }

  ngOnInit(): void {
    this.identity.handleLoginCallback('/on-boarding/recipient-profile');

    // add class to body to prevent scrolling in the background on mobile screen
    this.renderer.addClass(document.body, 'full-screen');

    const formState = window.history.state?.onboardingForm;
    if (formState) {
      this.form.setValue(formState);
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'full-screen');
  }

  async next() {
    if (!this.step1.valid) {
      this.step1.markAllAsTouched();
      return;
    }

    // update profileImage based on cropping...
    if (this.customProfileImage) {
      const croppedImage = await this.recipientProfile.croppedImage();
      this.step1.get('profileImage').setValue(croppedImage);
      this.step1.get('customProfileImage').setValue(croppedImage);
    }

    this.router.navigate(['../recipient-profile-details'], {
      relativeTo: this.route,
      state: {
        onboardingForm: this.form.value
      }
    });
  }

}
