import { Component, OnInit, LOCALE_ID } from '@angular/core';
import { I18nService } from 'src/app/i18n.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public activeSwitcher = false;

  readonly emailFeedbackPlatfrom: string = 'mailto:feedback@clea.app?subject=' + encodeURIComponent($localize`:@@emailFeedbackPlatfrom:Feedback Nutzung CléA Jobplattform`);

  constructor(private i18nService: I18nService) { }

  get siteLocale() {
    return this.i18nService.siteLocale;
  }

  get languageList() {
    return this.i18nService.languageList;
  }

  ngOnInit(): void {
  }

  toggleLangSwitcher() {
    this.activeSwitcher = !this.activeSwitcher;
  }

  createIssue(): void {
    (window as any).showCollectorDialog();
  }

  toggleLang(event, language) {
    event.stopPropagation();
    event.preventDefault();

    this.i18nService.setLangToLocalStorage(language.code);
    this.i18nService.redirectToLanguage(language);
  }

}
