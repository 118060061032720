import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2, SimpleChanges,
  ViewChild
} from '@angular/core';
// https://github.com/william-lohan/angular-croppie
import { CroppieOptions } from 'croppie';
import { CroppieDirective } from 'angular-croppie-module';

@Component({
  selector: 'app-profile-image-selector',
  templateUrl: './profile-image-selector.component.html',
  styleUrls: ['./profile-image-selector.component.scss']
})
export class ProfileImageSelectorComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input()
  src: string;

  @Output()
  srcChange = new EventEmitter();

  @ViewChild('imageEditor')
  imageEditor: ElementRef;

  @ViewChild('input')
  input: ElementRef;

  @ViewChild('croppie')

  public croppieDirective: CroppieDirective;
  public croppieOptions: CroppieOptions = {
    viewport: { width: 134, height: 134, type: 'circle' },
    boundary: { width: 134, height: 134 },
    showZoomer: false,
    enableOrientation: true
  };
  private $cropContainer: HTMLElement;

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    const $imageEditor = this.imageEditor.nativeElement as HTMLElement;
    this.$cropContainer = $imageEditor.querySelector<HTMLElement>('.croppie-container');
    this.initCropper();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('src' in changes) {
      if (this.input) {
        this.input.nativeElement.value = '';
      }
      this.resetCropper();
    }
  }

  ngOnDestroy() {
  }

  loadProfileImage(files: FileList) {
    if (!files || files.length !== 1) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      this.src = e.target.result as string;
      this.srcChange.emit(e.target.result);
      this.resetCropper();

      this.croppieDirective.croppie.bind({ url: this.src });
    };

    reader.readAsDataURL(files[0]);
  }

  removeProfileImage() {
    this.input.nativeElement.value = '';
    this.src = '';
    this.srcChange.emit('');
    this.resetCropper();
  }

  async croppedImage(): Promise<any> {
    return this.croppieDirective.croppie.result({
      type: 'base64',
      format: 'png'
    });
  }

  private resetCropper(position?: object) {
    if (this.croppieDirective && this.croppieDirective.croppie) {
      if (this.src) {
        this.$cropContainer.setAttribute('tabindex', '0');
      } else {
        this.$cropContainer.removeAttribute('tabindex');
      }
    }
  }

  private initCropper() {
    if (this.src) {
      this.croppieDirective.croppie.bind({ url: this.src });
    }
  }
}
