import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import moment from 'moment-es6';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import { I18nService } from 'src/app/i18n.service';

import { SelectionDateField } from '../models/selection-date-field';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() valueControl: AbstractControl;
  @Output() valueChange = new EventEmitter();

  public birthdateDayControl = new FormControl();
  public birthdateMonthControl = new FormControl();
  public birthdateYearControl = new FormControl();
  public availableDays: SelectionDateField[] = Array.from({length: 31}, (_, i) => ({key: i + 1, label: `${i + 1}` }));
  public availableMonths: SelectionDateField[] = [];
  public availableYears: SelectionDateField[] = Array.from({length: 121}, (_, i) => ({key: i + 1901, label: `${i + 1901}`}));

  private birthdateDaySubscription: Subscription;
  private birthdateMonthSubscription: Subscription;
  private birthdateYearSubscription: Subscription;

  constructor(private i18nService: I18nService) { }

  ngOnInit(): void {
    moment.locale(this.i18nService.siteLocale.code);
    console.log('set locale: ', this.i18nService.siteLocale);
    this.availableMonths = moment.months().map((name, i) => ({key: i, label: name + ' |' + ('0' + (i + 1)).slice(-2)}));
    this.updateDate();

    this.birthdateDaySubscription = this.birthdateDayControl.valueChanges.subscribe((date: number) => {
      this.setPartOfDate('date', date);
    });

    this.birthdateMonthSubscription = this.birthdateMonthControl.valueChanges.subscribe((month: number) => {
      this.setPartOfDate('month', month);
    });

    this.birthdateYearSubscription = this.birthdateYearControl.valueChanges.subscribe((year: number) => {
      this.setPartOfDate('year', year);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && changes['value'].currentValue) {
      this.updateDate();
    }
  }

  ngOnDestroy(): void {
    this.birthdateDaySubscription.unsubscribe();
    this.birthdateMonthSubscription.unsubscribe();
    this.birthdateYearSubscription.unsubscribe();
  }

  private setPartOfDate(type, date: number) {
    let newDate = null;
    if (this.valueControl.value) {
      newDate = moment(this.valueControl.value).set(type, date).format('YYYY-MM-DD');
      this.valueChange.emit(newDate);
      return;
    }

    const isAllFieldsSet = this.birthdateDayControl.value !== null
      && this.birthdateMonthControl.value !== null
      && this.birthdateYearControl.value !== null;

    if (isAllFieldsSet) {
      const momentDate = moment().set('date', this.birthdateDayControl.value);
      momentDate.set('month', this.birthdateMonthControl.value);
      momentDate.set('year', this.birthdateYearControl.value);
      newDate = momentDate.format('YYYY-MM-DD');
    }

    this.valueChange.emit(newDate);
  }

  private updateDate(): void {
    if (!this.valueControl.value) {
      return;
    }
    const momentDate = moment(this.valueControl.value);
    this.birthdateDayControl.setValue(parseInt(momentDate.format('D'), 10));
    this.birthdateMonthControl.setValue(momentDate.month());
    this.birthdateYearControl.setValue(momentDate.year());
  }

}
