<main [cdkTrapFocus]="isTrapActive" cdkTrapFocusAutoCapture class="layout-panel-centered">
  <form class="panel overflowing" [formGroup]="step3">
    <header class="panel-header">
      <h1 class="sr-only" i18n="sr-only@@profile.setupGiverOffer.title">Registrierungsprozess</h1>
      <ol class="process-steps heading" aria-label="Schritte">
        <li class="completed d-none d-sm-flex"><a routerLink="/on-boarding/recipient-profile" i18n="@@profile.setupGiverOffer.profile">1. Profil</a></li>
        <li class="completed d-none d-sm-flex"><a routerLink="/on-boarding/recipient-profile-details" i18n="@@profile.setupGiverOffer.information">2. Angaben</a></li>
        <li class="completed"><a routerLink="/on-boarding/recipient-profile-offer" aria-current="step" i18n="@@profile.setupGiverOffer.assistance">3. Assistenz</a></li>
      </ol>
      <a routerLink="/" class="cancel" i18n="@@profile.setupGiverOffer.btn.cancel">Abbrechen</a>
    </header>
    <div class="panel-content needs-illustration">
      <p class="text" i18n="@@profile.setupRecipientOffer.info.moreDetails">
        Bitte geben Sie an, für welchen Bereich Sie Assistenz benötigen. Mit Hilfe der Auswahl können wir für Sie nach potentiellen persönlichen Assistenten/innen suchen.
      </p>

      <app-recipient-offer [parentGroup]="step3"></app-recipient-offer>
    </div>
    <div class="panel-footer">
      <button type="button" class="button block-xs" (click)="back()" i18n="@@profile.setupGiverOffer.btn.back">Zurück</button>
      <button type="button" class="button primary block-xs" [disabled]="isLoading$ | async" (click)="submitForm()" i18n="@@profile.setupGiverOffer.btn.submit">Registrieren</button>
      <app-spinner [visible]="isLoading$ | async" class="absolute"></app-spinner>
    </div>
  </form>
</main>
