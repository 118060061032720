<div class="grid" [formGroup]="parentGroup">
  <div class="form-group span-12">
    <label for="input-location" class="label">
      <ng-container i18n="@@shared.recipientOffer.input.location">Einsatzort</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <ng-select #locationSelect
              labelForId="input-location"
              class="typeahead"
              i18n-placeholder="@@shared.recipientOffer.placeholder.location"
              placeholder="Einsatzort auswählen..."
              bindLabel="zipAndName"
              i18n-typeToSearchText="@@shared.giverOffer.placeholder.plzTypeToSearchText"
              typeToSearchText="PLZ/Ortsnamen eingeben"
              notFoundText="Keine Treffer"
              [items]="locations$ | async"
              [typeahead]="locationInput$"
              [formControl]="locationControl"
              (keydown.delete)="clearLocation()">
    </ng-select>
    <div *ngIf="locationControl.invalid && (locationControl.touched || locationControl.dirty)"
        class="validation error" i18n="@@shared.recipientOffer.error.location">Bitte wählen Sie Ihren Einsatzort aus.</div>
  </div>

  <div class="form-group span-12">
    <label id="input-assistance-services" class="label">
      <ng-container i18n="@@shared.recipientOffer.input.assistanceServices">Ich suche Assistenz für</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <div class="form-group span-12 information-text information-text--not-shure-terms" i18n="@@recipientOffer.informationText.notShureTerms">Sind sie nicht ganz sicher, was die untenstehenden Begriffe bedeuten oder welche Aufgaben damit verbunden sind? Kein Problem <a [href]="linkToFilterDescription" target="_blank">lesen sie es hier nach.</a></div>
    <div *ngIf="assistanceServicesControl.invalid && (assistanceServicesControl.touched || assistanceServicesControl.dirty)"
        class="validation error fix-margin-assistance" i18n="@@shared.recipientOffer.error.assistanceServices">Bitte wählen Sie mindestens 1 Kategorie aus, um fortzufahren.</div>
    <app-tag-selector labelledby="input-assistance-services"
                      [selectableTags]="availableAssistanceServices$ | async"
                      [value]="assistanceServicesControl.value"
                      (valueChange)="assistanceServicesControl.setValue($event); assistanceServicesControl.markAsDirty();">
    </app-tag-selector>
  </div>

  <div class="form-group span-12">
    <label for="input-job-desc" class="label" i18n="@@shared.recipientOffer.input.jobDescription">Jobbeschreibung (optional, max 700 Zeichen)</label>
    <textarea id="input-job-desc"
      formControlName="jobDescription"
      class="textarea"
      i18n-placeholder="@@shared.recipientOffer.placeholder.jobDescription"
      placeholder="Beschreiben Sie, bei was Sie Hilfe benötigen. Was ist Ihnen wichtig (z.B. Zuverlässlichkeit, Vertrauen, etc.)?"
      maxlength="701"></textarea>
    <div *ngIf="jobDescription.invalid && (jobDescription.touched || jobDescription.dirty)"
          class="validation error" i18n="@@shared.recipientOffer.error.jobDescription">Sie haben mehr als die erlaubten 700 Zeichen eingegeben. Bitte reduzieren Sie die Anzahl Zeichen.</div>
  </div>
</div>
