import { Component, OnInit } from '@angular/core';
import { ModalRef } from '../modal-ref';

@Component({
  selector: 'app-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss']
})
export class ErrorBoxComponent implements OnInit {
  text = '';
  title = '';

  constructor(private modal: ModalRef) {
    this.text = modal.data.text;
    this.title = modal.data.title ?? $localize`:@@popup.error.mainTitle:Etwas ist schiefgelaufen`;
  }

  ngOnInit(): void {
  }

  ok(): void {
    this.modal.close();
  }
}
