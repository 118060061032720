export type SelectionTagKeyType = string;

export interface SelectionTag {
  key: SelectionTagKeyType;
  name: string;
}

export enum TagStatus {
  None = 'None',
  Unselected = 'Unselected',
  Selected = 'Selected',
  Added = 'Added'
}
