import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  expiresAt: number;
  userProfile: any;
  accessToken: string;
  idToken: string;
  idTokenPayload: object;
  roles: string[];
  authenticated: boolean;

  constructor() {
    this.clearSession();
   }

  get isLoggedIn(): boolean {
    return this.hasValidToken && (this.isGiver || this.isRecipient);
  }

  get isUncompletedRegistration(): boolean {
    return this.hasValidToken && !(this.isGiver || this.isRecipient);
  }

  // If the user has created an identity but has not yet completed the
  // registration flow, he a valid token but is not yet logged in
  get hasValidToken(): boolean {
    // Check if current date is before token
    // expiration and user is signed in locally
    return this.expiresAt != null && Date.now() < this.expiresAt && this.authenticated;
  }

  get isGiver(): boolean {
    return this.roles && this.roles.includes('giver');
  }

  get isRecipient(): boolean {
    return this.roles && this.roles.includes('recipient');
  }

  setSession(authResult, profile) {
    // Save authentication data and update login status subject
    this.expiresAt = authResult.expiresIn * 1000 + Date.now();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.idTokenPayload = authResult.idTokenPayload;
    this.roles = authResult.idTokenPayload['http://cleaapp/roles'].map(r => r.toLowerCase());
    this.userProfile = profile;
    this.authenticated = true;
  }

  clearSession() {
    // Save authentication data and update login status subject
    this.expiresAt = null;
    this.accessToken = null;
    this.idToken = null;
    this.idTokenPayload = null;
    this.roles = [];
    this.userProfile = null;
    this.authenticated = false;
  }
}
