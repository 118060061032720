import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, interval } from 'rxjs';
import { SelectionTag } from '../ui-library/models/selection-tag';
import { LANGUAGES } from './mock-marketplace';
import { SearchResult, Perimeter, Location, SearchRequest, OfferModification, FilterCriteria, SortDirectionDto } from './marketplace.model';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {
  resultPage = 1;

  private availableAssistanceServices$: Observable<SelectionTag[]>;
  private availableGenders$: Observable<SelectionTag[]>;
  private availableCompensation$: Observable<SelectionTag[]>;
  private availableLanguages$: Observable<SelectionTag[]>;
  private availableMobilities$: Observable<SelectionTag[]>;

  constructor(private http: HttpClient) { }

  getAvailableAssistanceServices(): Observable<SelectionTag[]> {
    if (!this.availableAssistanceServices$) {
      this.availableAssistanceServices$ = this.http
        .get<SelectionTag[]>('/api/marketplace/selection-tags?selectionTypeKey=assistanceService')
        .pipe(shareReplay({ bufferSize: 1, refCount: true })) as Observable<SelectionTag[]>;
    }
    return this.availableAssistanceServices$;
  }
  getAvailableGenders() {
    if (!this.availableGenders$) {
      this.availableGenders$ = this.http
        .get<SelectionTag[]>('/api/marketplace/selection-tags?selectionTypeKey=gender')
        .pipe(shareReplay({ bufferSize: 1, refCount: true })) as Observable<SelectionTag[]>;
    }
    return this.availableGenders$;
   }
  getAvailableCompensations() {
    if (!this.availableCompensation$) {
      this.availableCompensation$ = this.http
        .get<SelectionTag[]>('/api/marketplace/selection-tags?selectionTypeKey=compensation')
        .pipe(shareReplay({ bufferSize: 1, refCount: true })) as Observable<SelectionTag[]>;
    }
    return this.availableCompensation$;
   }
  getAvailableLanguages() {
    if (!this.availableLanguages$) {
      this.availableLanguages$ = this.http
        .get<SelectionTag[]>('/api/marketplace/selection-tags?selectionTypeKey=language')
        .pipe(shareReplay({ bufferSize: 1, refCount: true })) as Observable<SelectionTag[]>;
    }
    return this.availableLanguages$;
   }
  getAvailableMobilities() {
    if (!this.availableMobilities$) {
      this.availableMobilities$ = this.http
        .get<SelectionTag[]>('/api/marketplace/selection-tags?selectionTypeKey=mobility')
        .pipe(shareReplay({ bufferSize: 1, refCount: true })) as Observable<SelectionTag[]>;
    }
    return this.availableMobilities$;
   }

  queryLocation(q: string): Observable<Location[]> {
    return this.http
      .get<Perimeter[]>(`/api/marketplace/locations?q=${q}`)
      .pipe(map(ps => ps.map(p => new Location(p))));
  }

  async saveOffer(offer: OfferModification): Promise<void> {
    return this.http
      .put<void>(`/api/marketplace/offers`, offer).toPromise();
  }

  getFavoriteLanguages(): Observable<SelectionTag[]> {
    return this.availableLanguages$.pipe(map(al => al.slice(0, 4)));
  }
}
