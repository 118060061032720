<div class="grid grid--1em" [formGroup]="parentGroup"> <!-- style="align-items: center;"> -->
  <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
  <div class="form-group span-12 profile-image">
    <div class="avatar0">
      <app-avatar-selector name="avatar-group" [src]="avatars[0]"
                           [number]="1"
                           [checked]="profileImage === avatars[0]"
                           (change)="setAvatar(avatars[0])"></app-avatar-selector>
    </div>
    <div class="avatar1">
      <app-avatar-selector name="avatar-group" [src]="avatars[1]"
      number="2"
                           [checked]="profileImage === avatars[1]"
                           (change)="setAvatar(avatars[1])"></app-avatar-selector>
    </div>
    <div class="avatar2">
      <app-avatar-selector name="avatar-group" [src]="avatars[2]"
      number="3"
                           [checked]="profileImage === avatars[2]"
                           (change)="setAvatar(avatars[2])"></app-avatar-selector>
    </div>
    <div class="avatar3">
      <app-avatar-selector name="avatar-group" [src]="avatars[3]"
      number="4"
                           [checked]="profileImage === avatars[3]"
                           (change)="setAvatar(avatars[3])"></app-avatar-selector>
    </div>
    <div class="custom">
      <app-profile-image-selector #profileImageSelector
                                  [src]="customProfileImage"
                                  (srcChange)="setCustomProfileImage($event)">
      </app-profile-image-selector>
    </div>

    <div *ngIf="profileImageControl.invalid && (firstControl.touched || firstControl.dirty)"
         class="validation error span-12" i18n="@@shared.giverProfile.error.profileImage">Bitte wählen Sie Ihr Profilbild aus.</div>
  </div>

  <div class="form-group span-6">
    <label for="input-firstname" class="label">
      <ng-container i18n="@@shared.giverProfile.input.firstName">Vorname</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <input id="input-firstname" type="text" class="input"
           formControlName="first"
           maxlength="40">
    <div *ngIf="firstControl.invalid && (firstControl.touched || firstControl.dirty)"
         class="validation error" i18n="@@shared.giverProfile.error.firstName">Bitte geben Sie Ihren Vornamen ein.</div>
  </div>
  <div class="form-group span-6">
    <label for="input-name" class="label">
      <ng-container i18n="@@shared.giverProfile.input.secondName">Name (wird nicht veröffentlicht)</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <input id="input-name" type="text" class="input"
           formControlName="name"
           maxlength="40">
    <div *ngIf="nameControl.invalid && (nameControl.touched || nameControl.dirty)"
         class="validation error" i18n="@@shared.giverProfile.error.secondName">Bitte geben Sie Ihren Nachnamen ein.</div>
  </div>

  <div class="form-group span-12 information-text information-text--meaningful-description" i18n="@@shared.giverProfile.text.aboutMe">
    Mit einer aussagekräftigen Beschreibung helfen sie anderen Nutzern Sie besser einschätzen zu können. Dadurch finden sie schneller eine Übereinstimmung und schaffen vertrauen.
  </div>

  <div class="form-group span-12">
    <label for="input-description" class="label" i18n="@@shared.giverProfile.input.aboutMe">Beschreibung (optional, max 700 Zeichen)</label>
    <textarea id="input-description" class="textarea"
              formControlName="aboutMe"
              i18n-placeholder="@@shared.giverProfile.placeholder.aboutMe"
              placeholder="Wie möchten Sie Assistenz leisten? Wie oft möchten Sie arbeiten? Was ist Ihnen wichtig? …"
              maxlength="701">
    </textarea>
    <div *ngIf="aboutMe.invalid && (aboutMe.touched || aboutMe.dirty)"
         class="validation error" i18n="@@shared.giverProfile.error.aboutMe">Sie haben mehr als die erlaubten 700 Zeichen eingegeben. Bitte reduzieren Sie die Anzahl Zeichen.</div>
  </div>
</div>
