<div class="no-results-container">
  <div class="no-results-content">
    <h3 class="title" i18n="@@marketplace.noResults.title">Keine Übereinstimmungen gefunden</h3>
    <figure class="images" aria-hidden="true">
      <img src="\assets\images\illustration-empty.svg" alt="">
    </figure>
    <ng-container *ngIf="unsavedQuery && auth.isRecipient">
      <p class="text" i18n="@@marketplace.noResults.saveSearch">
        Speichern Sie diese Suche in Ihrem Profil, um beim nächsten Besuch dieselbe Suche direkt verfügbar zu haben.
      </p>
      <button class="button" (click)="save()" [disabled]="isSaving" i18n="@@marketplace.noResults.btnSaveSearch">Diese Suche speichern</button>
    </ng-container>
    <ng-container *ngIf="unsavedQuery && auth.isGiver">
      <p class="text" i18n="@@marketplace.noResults.anyPossibleEmployers">
        Wir haben keine möglichen Arbeitgeber für Sie gefunden. Aktualisieren Sie Ihr Profil mit den gewählten Leistungen, um besser gefunden zu werden.
      </p>
      <button class="button" (click)="save()" [disabled]="isSaving" i18n="@@marketplace.noResults.btnUpdateServices">Ihre Leistungen aktualisieren</button>
    </ng-container>
  </div>
</div>
