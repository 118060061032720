import { Component, OnInit } from '@angular/core';
import { IdentityService } from '../identity.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-callback',
  template: `
    <p>
      Loading...
    </p>
  `,
  styleUrls: []
})
export class CallbackComponent implements OnInit {
  constructor(
    private identity: IdentityService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    console.log('callback', this.route.snapshot.queryParams);
    const redirectUri = this.route.snapshot.queryParams.redirectUri || '/';
    const decodedUri = decodeURIComponent(redirectUri);
    this.identity.handleLoginCallback(decodedUri);
  }
}
