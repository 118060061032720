<h1 i18n="sr-only@@footer.header" class="sr-only">Fusszeile</h1>
<h2 i18n="sr-only@@footer.linkHeader" class="sr-only">Metainformationen</h2>

<ul class="navbar-nav vertical horizontal-sm">
  <li class="nav-item"><a
    href="https://www.clea.app/"
    i18n-href="@@www-clea-app"
    target="_blank"
    class="nav-link"
    i18n="@@footer.link.cleaHome"
  >CléA Assistenzplattform</a></li>
  <li class="nav-item"><a
    href="https://www.clea.app/ueber-uns/"
    i18n-href="@@www-clea-app-ueber-uns"
    target="_blank"
    class="nav-link"
    i18n="@@footer.link.aboutUs"
  >Über uns</a></li>
  <li class="nav-item"><a
    href="https://www.clea.app/medien/"
    i18n-href="@@www-clea-app-medien"
    target="_blank"
    class="nav-link"
    i18n="@@footer.link.press"
  >Medien</a></li>
  <li class="nav-item"><a
    href="https://www.clea.app/datenschutzerklaerung-clea-assistenzplattform/"
    i18n-href="@@www-clea-app-datenschutzerklaerung-clea-assistenzplattform"
    target="_blank"
    class="nav-link"
    i18n="@@footer.link.privacy"
  >Datenschutz</a></li>
  <li class="nav-item"><a
      href="https://www.clea.app/impressum/"
      i18n-href="@@www-clea-app-impressum"
      target="_blank"
      class="nav-link"
      i18n="@@footer.link.legalNotice"
    >Impressum</a></li>
</ul>

<div class="navbar-nav vertical horizontal-sm">
  <div class="language-switcher" (click)="toggleLangSwitcher()" [class]="activeSwitcher ? 'active' : ''">
    <button class="language-switcher__btn button tertiary" type="button">
      <span class="icon icon-chevron-down" [class]="activeSwitcher ? 'icon-chevron-up' : 'icon-chevron-down'" aria-hidden="true"></span>
      {{ siteLocale.label }}
    </button>
    <ul class="language-switcher__ul">
      <li class="language-switcher__list" *ngFor="let lang of languageList">
        <a [href]="lang.url" (click)="toggleLang($event, lang)" [class]="lang.code === siteLocale.code ? 'active' : ''">{{ lang.label }}</a>
      </li>
    </ul>
  </div>

  <div class="footer-links-block">
    <a
      class="donation-button d-none d-sm-flex"
      href="https://www.clea.app/spenden/"
      i18n-href="@@www-clea-app-spenden"
      target="_blank"
      i18n="@@footer.link.donate"
    >Spenden</a>
    <a
      class="button tertiary"
      href="https://www.clea.app/faq-zur-clea-assistenzplattform/"
      i18n-href="@@www-clea-app-faq-zur-clea-assistenzplattform"
      target="_blank"
    >
      <span class="icon icon-help-circle" aria-hidden="true"></span>
      <ng-container i18n="@@footer.link.help">Hilfe</ng-container>
    </a>
    <a [href]="emailFeedbackPlatfrom" class="button tertiary block">
      <span class="icon icon-message-circle" aria-hidden="true"></span>
      <ng-container i18n="@@footer.link.feedback">Feedback</ng-container>
    </a>
  </div>
  <a class="donation-button button block d-sm-none" href="https://www.clea.app/spenden/" i18n-href="@@www-clea-app-spenden" target="_blank" i18n="@@footer.link.donate">Spenden</a>
</div>
