import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { InvitationService } from 'src/app/invitation.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  @Input() addPlaceholderToTeam = false;
  @Output() sent = new EventEmitter<string>();
  isLoading$ = new BehaviorSubject<boolean>(false);
  invited = false;

  invitationForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private invitation: InvitationService) { }

  get email(): AbstractControl {
    return this.invitationForm.get('email');
  }

  ngOnInit(): void {
  }

  submitInvitation() {
    this.invitationForm.markAsTouched();
    if (this.invitationForm.invalid) {
      return;
    }

    this.isLoading$.next(true);
    this.invitation.inviteMember(this.email.value, this.addPlaceholderToTeam)
      .then(() => {
        const email = this.email.value;
        this.email.setValue('');
        this.isLoading$.next(false);
        this.invited = true;
        this.invitationForm.markAsPristine();
        this.invitationForm.markAsUntouched();
        this.sent.emit(email);
        setTimeout(() => this.invited = false, 5000);
      })
      .catch(() => this.isLoading$.next(false));
  }
}
