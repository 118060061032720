import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  readonly LOCALE_KEY = 'locale';

  public languageList = [
    { code: 'de', label: 'Deutsch', url: '/', prefix: '' },
    { code: 'fr', label: 'Français', url: '/fr', prefix: '/fr' },
    { code: 'it', label: 'Italiano', url: '/it', prefix: '/it' }
  ];

  private _siteLocale = null;

  get siteLocale() {
    if (!this._siteLocale) {
      this._siteLocale = this.setCurrentLang();

      console.log('Site locale: ', this._siteLocale);
    }

    return this._siteLocale;
  }

  get detectedBrowserLocale() {
    const languageList = navigator.languages || [navigator.language];

    const languages = languageList
      .map((lang) => lang.slice(0, 2))
      .filter((code) => this.languageList.find(lang => lang.code === code));

    if (languages.length) {
      return this.languageList.find(lang => lang.code === languages[0]);
    }

    return this.languageList[0];
  }

  constructor() {
    const browserLocale = this.detectedBrowserLocale;
    const appLocale = this.siteLocale;

    const storedLocaleCode = localStorage.getItem(this.LOCALE_KEY) || null;
    if (storedLocaleCode && storedLocaleCode !== appLocale.code) {
      const locale = this.languageList.find(lang => lang.code === storedLocaleCode);
      this.redirectToLanguage(locale);
      return;
    }
    if (!storedLocaleCode) {
      this.setLangToLocalStorage(browserLocale.code);

      if (browserLocale.code !== appLocale.code) {
        this.redirectToLanguage(browserLocale);
        return;
      }
    }
  }

  setLangToLocalStorage(code) {
    localStorage.setItem(this.LOCALE_KEY, code);
  }

  redirectToLanguage(language) {
    const url = window.location.pathname.replace(/^\/(it|fr)\/|^\/(it|fr)$|(^\/)/, '');

    if (window.location.hostname === 'localhost') {
      console.log('Prevented localhost redirect to: ' + language.prefix + '/' + url);
      return;
    }

    window.location.href = language.prefix + '/' + url;
  }

  setCurrentLang() {
    const routeParams = window.location.pathname.split('/');
    if (routeParams.length <= 1) {
      return this.languageList[0];
    }

    const langParam = routeParams[1];
    const lang = this.languageList.find(f => f.code === langParam);
    if (!lang) {
      return this.languageList[0];
    }

    return lang;
  }
}
