import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Relation, MemberRelationData } from '../employment.model';
import { Offer, OfferType, Member } from 'src/app/marketplace/marketplace.model';
import { AuthService } from 'src/app/identity/auth.service';

@Component({
  selector: 'app-job-request',
  templateUrl: './job-request.component.html',
  styleUrls: ['./job-request.component.scss']
})
export class JobRequestComponent implements OnInit {
  @Input() memberRelation: MemberRelationData;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
  }

  get member(): Member {
    return this.memberRelation?.member;
  }

  get offer(): Offer {
    const visibleOffer: OfferType = this.auth.isGiver ? 'Job' : 'Assistance';
    return this.memberRelation.member.offers.find(o => o.offerType === visibleOffer);
  }

  get relation(): Relation {
    return this.memberRelation?.relation;
  }

  get unreadMessages(): number {
    return this.memberRelation?.unreadMessages || 0;
  }

}
