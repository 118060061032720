<main [cdkTrapFocus]="isTrapActive" cdkTrapFocusAutoCapture class="layout-panel-centered">
  <form class="panel overflowing" [formGroup]="step2">
    <header class="panel-header">
      <h1 class="sr-only" i18n="sr-only@@profile.setupRecipientProfileDetails.title">Registrierungsprozess</h1>
      <ol class="process-steps heading" aria-label="Schritte">
        <li class="completed d-none d-sm-flex"><a routerLink="/on-boarding/recipient-profile" i18n="@@profile.setupGiverOffer.profile">1. Profil</a></li>
        <li class="completed"><a routerLink="/on-boarding/recipient-profile-details" aria-current="step" i18n="@@profile.setupGiverOffer.information">2. Angaben</a></li>
        <li class="d-none d-sm-flex" i18n="@@profile.setupGiverOffer.assistance">3. Assistenz</li>
      </ol>
      <a routerLink="/" class="cancel" i18n="@@profile.setupGiverOffer.btn.cancel">Abbrechen</a>
    </header>
    <div class="panel-content transportation-illustration">
      <p class="text" i18n="@@profile.setupRecipientProfileDetails.info.step2Info">
        Um Sie mit passenden persönlichen Assistentinnen und Assistenten in Verbindung zu bringen, brauchen wir einige Angaben von Ihnen. Diese Angaben sind nicht öffentlich zugänglich.
      </p>

      <app-recipient-profile-details [parentGroup]="step2" [form]="form"></app-recipient-profile-details>

      <div class="panel-section">
        <p class="text" i18n="@@profile.setupRecipientProfileDetails.info.moreDetails">
          Um die CléA Assistenzplattform nutzen zu können, muss man unterschriftsberechtigt sein.
        </p>

        <div class="grid">
          <div class="span-12">
            <div class="form-check">
              <input type="checkbox" id="input-authorized-to-sign" formControlName="authorizedToSign" />
              <label for="input-authorized-to-sign" class="label">
                <ng-container i18n="@@profile.setupRecipientProfileDetails.checkbox.authorizedToSign">Ich bin unterschriftsberechtigt</ng-container>
                <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
              </label>
            </div>
            <div *ngIf="authorizedToSign.invalid && (authorizedToSign.touched || authorizedToSign.dirty)"
                class="validation error" i18n="@@profile.setupRecipientProfileDetails.error.authorizedToSign">Bitte bestätigen Sie, dass Sie unterschriftsberechtigt sind.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <button type="button" class="button block-xs" (click)="back()" i18n="@@profile.setupGiverOffer.btn.back">Zurück</button>
      <button type="button" class="button primary block-xs" (click)="next()" i18n="@@profile.setupGiverOffer.btn.next">Weiter</button>
    </div>
  </form>
</main>
