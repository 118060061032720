<div class="panel">
  <header class="panel-header">
    <h1 class="heading" i18n="@@popup.referenceEdit.title">Referenz</h1>
    <button class="cancel" (click)="cancel()" i18n="@@popup.btn.cancel">Abbrechen</button>
  </header>
  <div class="panel-content">
    <form [formGroup]="referenceForm" class="form" (ngSubmit)="save()">
      <div *ngIf="(isLoading$ | async) === false" class="form-group">
        <label for="reference-comment" class="label" i18n="@@popup.referenceEdit.textarea">Referenztext (max 160 Zeichen)</label>
        <textarea id="reference-comment" class="textarea" [formControl]="comment" i18n-placeholder="@@popup.referenceEdit.textareaPlaceholder" placeholder="Ihre Beschreibung über {{ member.firstName }}..." maxlength="160"></textarea>
        <div *ngIf="comment.invalid && comment.touched" class="validation error" i18n="@@popup.referenceEdit.invalidText">
             Bitte geben Sie einen Referenztext ein.
        </div>
      </div>
      <app-spinner [visible]="isLoading$ | async" class="loading"></app-spinner>
      <div class="form-group actions">
        <button type="submit" [disabled]="isLoading$ | async" class="button primary" i18n="@@popup.btn.save">Speichern</button>
      </div>
    </form>
  </div>
</div>
