<div class="grid" [formGroup]="parentGroup">
  <div class="form-group span-12">
    <label id="input-compensation" class="label">
      <ng-container i18n="@@shared.giverOffer.typeOfAssistance">Art der Assistenz</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <app-segment-button-list labelledby="input-compensation"
                            [selectableTags]="availableCompensations$ | async"
                            [value]="compensationControl.value"
                            (valueChange)="compensationControl.setValue($event); compensationControl.markAsDirty()">
    </app-segment-button-list>
  </div>

  <div class="form-group span-8">
    <label for="input-location" class="label">
      <ng-container i18n="@@shared.giverOffer.placeOfUse">Einsatzort</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr></label>
    <ng-select #locationSelect
              labelForId="input-location"
              class="typeahead"
              i18n-placeholder="@@shared.giverOffer.placeholder.placeOfUse"
              placeholder="Einsatzort auswählen..."
              bindLabel="zipAndName"
              i18n-typeToSearchText="@@shared.giverOffer.placeholder.plzTypeToSearchText"
              typeToSearchText="PLZ/Ortsnamen eingeben"
              notFoundText="Keine Treffer"
              [items]="locations$ | async"
              [typeahead]="locationInput$"
              [formControl]="locationControl"
              (keydown.delete)="clearLocation()">
    </ng-select>
    <div *ngIf="locationControl.invalid && (locationControl.touched || locationControl.dirty)"
        class="validation error"
        i18n="@@shared.giverOffer.error.placeOfUse"
      >Bitte wählen Sie Ihren Einsatzort aus.</div>
  </div>

  <div class="form-group span-4">
    <label for="input-range" class="label">
      <ng-container i18n="@@shared.giverOffer.area">Einsatzgebiet (in km)</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <ng-select
      [searchable]="false"
      [clearable]="false"
      labelForId="input-range"
      formControlName="range"
      appAddIconChevronDown>
      <ng-option value="5">5</ng-option>
      <ng-option value="10">10</ng-option>
      <ng-option value="25">25</ng-option>
      <ng-option value="50">50</ng-option>
      <ng-option value="100">100</ng-option>
    </ng-select>
    <div *ngIf="rangeControl.invalid && (rangeControl.touched || rangeControl.dirty)"
        class="validation error">
      <span *ngIf="rangeControl.errors.required" i18n="@@shared.giverOffer.error.areaRquired">Bitte wählen Sie Ihren Einsatzradius aus.</span>
      <span *ngIf="rangeControl.errors.min" i18n="@@shared.giverOffer.error.areaMin">Der Radius muss mindestens 1&nbsp;km betragen.</span>
    </div>
  </div>

  <div class="form-group span-12">
    <label id="input-assistance-services" class="label">
      <ng-container i18n="@@shared.giverOffer.assistanceServices">Ich biete Assistenz bei</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <div class="form-group span-12 information-text information-text--not-shure-terms" i18n="@@recipientOffer.informationText.notShureTerms">Sind sie nicht ganz sicher, was die untenstehenden Begriffe bedeuten oder welche Aufgaben damit verbunden sind? Kein Problem <a [href]="linkToFilterDescription" target="_blank">lesen sie es hier nach.</a></div>
    <div *ngIf="assistanceServicesControl.invalid && (assistanceServicesControl.touched || assistanceServicesControl.dirty)"
        class="validation error fix-margin-assistance" i18n="@@shared.giverOffer.error.assistanceServices">Bitte wählen Sie mindestens 1 Kategorie aus, um fortzufahren.</div>
    <app-tag-selector labelledby="input-assistance-services"
                      [selectableTags]="availableAssistanceServices$ | async"
                      [value]="assistanceServicesControl.value"
                      (valueChange)="assistanceServicesControl.setValue($event); assistanceServicesControl.markAsDirty();">
    </app-tag-selector>
  </div>
</div>
