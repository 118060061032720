import { Component, OnInit } from '@angular/core';
import { ModalRef } from 'src/app/ui-library/modal/modal-ref';

@Component({
  selector: 'app-required-assistance-services',
  templateUrl: './required-assistance-services.component.html',
  styleUrls: ['./required-assistance-services.component.css']
})
export class RequiredAssistanceServicesComponent implements OnInit {

  constructor(public modal: ModalRef) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modal.close();
  }

}
