import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { IonicModule } from '@ionic/angular';

import { UiLibraryBrowserComponent } from './ui-library-browser/ui-library-browser.component';
import { MessageThreadComponent } from './message-thread/message-thread.component';
import { MessageComponent } from './message/message.component';
import { BadgeComponent } from './badge/badge.component';
import { SegmentButtonComponent } from './segment-button/segment-button.component';
import { SegmentButtonListComponent } from './segment-button-list/segment-button-list.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { TagComponent } from './tag/tag.component';
import { TagSelectorComponent } from './tag-selector/tag-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddIconChevronDownDirective } from './select/add-icon-chevron-down.directive';
import { ModalComponent } from './modal/component/modal.component';
import { MessageBoxComponent } from './modal/message-box/message-box.component';
import { AgeFromDatePipe } from './pipes/age-from-date.pipe';
import { StringifySelectionTagsPipe } from './pipes/stringify-selection-tags.pipe';
import { MailtoPipe } from './pipes/mailto.pipe';
import { ErrorBoxComponent } from './modal/error-box/error-box.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { InvitationComponent } from './invitation/invitation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReferenceDetailComponent } from './modal/reference-detail/reference-detail.component';
import { ReferenceEditComponent } from './modal/reference-edit/reference-edit.component';
import { ConfirmationComponent } from './modal/confirmation/confirmation.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';

@NgModule({
  declarations: [
    UiLibraryBrowserComponent,
    MessageThreadComponent,
    MessageComponent,
    BadgeComponent,
    SegmentButtonComponent,
    SegmentButtonListComponent,
    TagListComponent,
    TagComponent,
    TagSelectorComponent,
    MessageBoxComponent,
    AddIconChevronDownDirective,
    ModalComponent,
    AgeFromDatePipe,
    StringifySelectionTagsPipe,
    MailtoPipe,
    ErrorBoxComponent,
    SpinnerComponent,
    InvitationComponent,
    ReferenceDetailComponent,
    ReferenceEditComponent,
    ConfirmationComponent,
    DateSelectorComponent
  ],
  entryComponents: [
    ErrorBoxComponent,
    MessageBoxComponent,
    ModalComponent
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    OverlayModule,
    A11yModule,
  ],
  exports: [
    MessageThreadComponent,
    MessageComponent,
    BadgeComponent,
    SegmentButtonComponent,
    SegmentButtonListComponent,
    TagListComponent,
    TagComponent,
    TagSelectorComponent,
    AddIconChevronDownDirective,
    AgeFromDatePipe,
    StringifySelectionTagsPipe,
    MailtoPipe,
    SpinnerComponent,
    InvitationComponent,
    A11yModule,
    DateSelectorComponent
  ]
})
export class UiLibraryModule { }
