<div class="empty-team-container">
  <div class="empty-team-info">
    <h3 class="title" i18n="@@employment.popup.noJobs.title">Sie sind noch in keinem Team</h3>
    <figure class="images" aria-hidden="true">
      <img src="\assets\images\team-triptychon.svg" alt="">
    </figure>
    <p class="text" i18n="@@employment.popup.noJobs.content">
      Sobald Sie von einer Arbeitgeberin/einem Arbeitgeber in ihr/sein Team aufgenommen werden, sehen Sie diesen hier.
    </p>
  </div>
</div>
