<div class="grid" [formGroup]="parentGroup">
  <div class="form-group span-12">
    <label id="input-gender" class="label">
      <ng-container i18n="@@shared.giverProfile.input.gender">Geschlecht</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <app-segment-button-list labelledby="input-gender"
                            [selectableTags]="availableGenders$ | async"
                            [value]="genderControl.value"
                            (valueChange)="genderControl.setValue($event); genderControl.markAsDirty();">
    </app-segment-button-list>
  </div>

  <div class="form-group span-12">
    <label for="input-native-language" class="label">
      <ng-container i18n="@@shared.giverProfile.input.nativeLanguage">Muttersprache</ng-container>
      <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <ng-select labelForId="input-native-language"
              i18n-placeholder="@@shared.giverProfile.placeholder.nativeLanguage"
              placeholder="Auswählen..."
              bindLabel="name"
              bindValue="key"
              [items]="availableLanguages"
              [clearable]="false"
              formControlName="nativeLanguage"
              appAddIconChevronDown>
    </ng-select>
    <div *ngIf="nativeLanguageControl.invalid && (nativeLanguageControl.touched || nativeLanguageControl.dirty)"
         class="validation error" i18n="@@shared.giverProfile.error.nativeLanguage">Bitte wählen Sie Ihre Muttersprache aus.
    </div>
  </div>

  <!-- <app-toglle-visibility [form]="form"></app-toglle-visibility> -->

  <div class="span-12">
    <div class="form-group ">
      <label for="input-languages" class="label" i18n="@@shared.giverProfile.input.otherLanguages">Weitere Sprachen</label>
      <ng-select labelForId="input-languages"
                i18n-placeholder="@@shared.giverProfile.placeholder.otherLanguages"
                placeholder="Hinzufügen..."
                bindLabel="name"
                [items]="availableLanguages"
                [clearable]="false"
                [formControl]="addSelectedLanguageControl"
                appAddIconChevronDown>
      </ng-select>
    </div>

    <div class="form-group">
      <app-tag-selector labelledby="input-assistance-services"
                        [selectableTags]="favoriteLanguages"
                        [value]="languagesControl.value"
                        (valueChange)="languagesControl.setValue($event); languagesControl.markAsDirty();">
      </app-tag-selector>
    </div>
  </div>

  <div class="form-group span-12">
    <label id="input-mobility" class="label" i18n="@@shared.giverProfile.input.mobility">Ich verfüge über folgende Mobilitätsmöglichkeiten (optional)</label>
    <app-tag-selector [selectableTags]="availableMobility$ | async"
                      [value]="mobilityControl.value"
                      (valueChange)="mobilityControl.setValue($event); mobilityControl.markAsDirty();"
                      labelledby="input-mobility">
    </app-tag-selector>
  </div>

  <div class="form-group span-6">
    <label for="input-phone" class="label" i18n="@@shared.giverProfile.input.telefon">Telefon (optional)</label>
    <input id="input-phone" type="text" inputmode="tel" class="input" formControlName="phone" maxlength="25">
    <div *ngIf="phone.invalid && (phone.touched || phone.dirty)"
         class="validation error" i18n="@@shared.giverProfile.error.telefon">Bitte geben Sie eine gültige Telefonnummer ein.
    </div>
  </div>

  <div class="form-group span-6" *ngIf="birthdateControl">
    <app-date-selector
      [valueControl]="birthdateControl"
      (valueChange)="birthdateControl.setValue($event); birthdateControl.markAsDirty();"
    ></app-date-selector>
  </div>

  <div class="form-group span-12 upload-tool">
    <!-- id is linked in .ts file -->
    <label for="input-documents" class="label" i18n="@@shared.giverProfile.title.documents">Dokumente wie Lebenslauf, Arbeitszeugnis, etc. (optional)</label>

    <div *ngFor="let doc of visibleDocuments" class="document">
      <div class="border">
        <a class="filename" [href]="documentUrl(doc)" (click)="downloadDocument(doc, $event)">{{doc.name}}</a>
      </div>
      <button class="button form icon-only small" (click)="removeDocument(doc)">
        <span aria-hidden="true" class="icon icon-trash"></span>
        <span class="sr-only" i18n="sr-only@@shared.giverProfile.btn.removeDocument">Entfernen</span></button>
    </div>
    <form method="post" enctype="multipart/form-data" #myForm>

      <div class="mobile-input">
        <input id="mobile-input" class="mobile-input--input" type="file" multiple (change)="onChange($event)" />
        <div class="filepond--drop-label">
          <label for="mobile-input" aria-hidden="true" i18n="@@shared.giverProfile.input.documents">
            Dateien hier hinziehen oder <span class="filepond--link">auswählen</span>
            <br>
            <span class="info">Maximal 10 MB pro Datei</span>
            <span aria-hidden="true" class="icon icon-arrow-up-circle"></span>
          </label>
        </div>
      </div>

      <file-pond #myPond [options]="pondOptions"></file-pond>
    </form>

    <div *ngIf="documentsControl.touched && documentsControl.errors && documentsControl.errors.uploading"
         class="validation error" i18n="@@shared.giverProfile.error.documents">Solange der Upload läuft, kann mit der Registrierung nicht fortgefahren werden.
    </div>
  </div>
</div>
