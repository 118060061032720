import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Relation, MemberRelationData, RelationRole, MemberRelation, MessagesResult, MultiMessage, MultiMessagePost } from './employment.model';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class EmploymentService {
  relations: MemberRelation[] = [];
  openRequests: MemberRelation[] = [];
  relationsInTeam: MemberRelation[] = [];
  invitees: MemberRelation[] = [];

  constructor(private http: HttpClient) {
  }

  get hasOpenRequests(): boolean {
    return this.openRequests.some(r => r.relation.allowedAction !== 'None' && r.relation.allowedAction !== 'Decline');
  }

  get countOpenRequestsAndNewMessages(): number {
    return this.relations.reduce((acc, r) => {
      acc += r.unreadMessages;
      if (r.relation && r.relation.allowedAction === 'ShareContactsOrDecline') {
        acc += 1;
      }
      return acc;
    }, 0);
  }

  loadRelations(role: RelationRole): Observable<void> {
    return this.http
      .get<MemberRelationData[]>(`/api/employments/${role}`)
      .pipe(
        map(d => d.map(r => new MemberRelation(r))),
        tap({
          next: (rs) => {
            this.relations = rs.sort(this._sortBylastMessage);
            this._updateFilteredRelations();
          }
        }),
        map(rs => {})
      );
  }

  getRelation(role: RelationRole, memberId: string): Observable<Relation> {
    return this.http
      .get<Relation>(`/api/employments/${role}/${memberId}`);
  }

  addRelation(relation: MemberRelation): void {
    this.relations.push(relation);
    this._updateFilteredRelations();
  }

  request(relation: Relation, message: string): Observable<void> {
    return this.http
      .post<void>(`/api/employments/${relation.memberId}/request`, {
        message
      })
      .pipe(tap({
        complete: () => {
          let memberRelation = this.relations.find(r => r.relation && r.relation.memberId === relation.memberId);
          if (!memberRelation) {
            memberRelation = new MemberRelation({
              member: null,
              relation
            });
            this.relations.push(memberRelation);
          }
          relation.conversation.push({ timestamp: new Date(), messageType: 'Sent', text: message });
          relation.allowedAction = 'None';
          relation.inTeam = false;
          this._updateFilteredRelations(relation);
        }
      }));
  }

  grant(role: RelationRole, relation: Relation, message: string): Observable<void> {
    return this.http
      .post<void>(`/api/employments/${role}/${relation.memberId}/grant`, {
        message
      })
      .pipe(tap({
        complete: () => {
          relation.conversation.push({ timestamp: new Date(), messageType: 'System', text: $localize`:@@api.employments.grantRole:Anfrage angenommen und persönliche Informationen geteilt` });
          relation.conversation.push({ timestamp: new Date(), messageType: 'Sent', text: message });
          relation.allowedAction = role === 'Employee' ? 'Decline' : 'AddToTeamOrDecline';
          relation.inTeam = false;
          this._updateFilteredRelations(relation);
        }
      }));
  }

  decline(role: RelationRole, relation: Relation, message: string): Observable<void> {
    return this.http
      .post<void>(`/api/employments/${role}/${relation.memberId}/decline`, {
        message
      })
      .pipe(tap({
        complete: () => {
          relation.conversation.push({ timestamp: new Date(), messageType: 'System', text: $localize`:@@api.employments.declineRole:Anfrage abgelehnt` });
          relation.conversation.push({ timestamp: new Date(), messageType: 'Sent', text: message });
          relation.allowedAction = 'Request';
          relation.inTeam = false;
          this.relations = this.relations.filter(r => r.relation.memberId !== relation.memberId);
          this._updateFilteredRelations(relation);
        }
      }));
  }

  addToTeam(relation: Relation): Observable<void> {
    return this.http
      .post<void>(`/api/employments/${relation.memberId}/addToTeam`, {})
      .pipe(tap({
        complete: () => {
          relation.conversation.push({ timestamp: new Date(), messageType: 'System', text: $localize`:@@api.employments.addToTeam:Ins Team aufgenommen` });
          relation.allowedAction = 'RemoveFromTeam';
          relation.inTeam = true;
          this._updateFilteredRelations(relation);
        }
      }));
  }

  removeFromTeam(role: RelationRole, relation: Relation): Observable<void> {
    return this.http
      .post<void>(`/api/employments/${role}/${relation.memberId}/removeFromTeam`, {})
      .pipe(tap({
        complete: () => {
          relation.conversation.push({ timestamp: new Date(), messageType: 'System', text: $localize`:@@api.employments.removeFromTeam:Teambeziehung aufgelöst` });
          relation.allowedAction = 'Request';
          relation.inTeam = false;
          this.relations = this.relations.filter(r => r.relation.memberId !== relation.memberId);
          this._updateFilteredRelations();
        }
      }));
  }

  sendMessage(relation: Relation, message: MultiMessagePost): Observable<MultiMessage> {
    return this.http
      .post<MultiMessage>(`/api/marketplace/members/${relation.memberId}/messages`, message);
  }

  getMessages(relation: Relation): Promise<Array<MultiMessage>> {
    return this.http
      .get<MessagesResult>(`/api/marketplace/members/${relation.memberId}/messages`)
      .toPromise()
      .then(data => data.messages)
      .then(messages => messages.sort(this._compare))
      .then((messages) => {
        this.setReadMessagesToRelation(relation.memberId);
        return messages;
      });
  }

  private setReadMessagesToRelation(memberId) {
    const relation = this.relations.find((r) => r.member.id === memberId);
    if (relation) {
      relation.unreadMessages = 0;
    }
  }

  private _updateFilteredRelations(relation: Relation = null): void {

    const existingRelation = this._getRelation(relation?.memberId);
    if (existingRelation) {
      existingRelation.conversation = relation.conversation;
      existingRelation.allowedAction = relation.allowedAction;
      existingRelation.inTeam = relation.inTeam;
    }
    this.openRequests = this.relations.filter(r => r.isOpenRequest);
    this.relationsInTeam = this.relations.filter(r => r.isInTeam && !r.member.placeholder);
    this.invitees = this.relations.filter(r => r.isInTeam && r.member.placeholder);
  }

  private _getRelation(memberId: string | undefined): Relation {
    if (!memberId) {
      return null;
    }

    return this.relations.find(r => r.relation.memberId === memberId)?.relation;
  }

  private _compare(a: MultiMessage, b: MultiMessage): number {
    if ( a.createdOn < b.createdOn ){
      return 1;
    }
    if ( a.createdOn > b.createdOn ){
      return -1;
    }
    return 0;
  }

  private _sortBylastMessage(a: MemberRelation, b: MemberRelation): number {
    if ( a.lastMessageTimeStamp < b.lastMessageTimeStamp ){
      return 1;
    }
    if ( a.lastMessageTimeStamp > b.lastMessageTimeStamp ){
      return -1;
    }
    return 0;
  }
}
