import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../ui-library/modal/modal.service';
import { IdentityService } from 'src/app/identity/identity.service';
const ROLE_PA = 'PA';
const ROLE_AN = 'AN';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  @Input() isGiver = false;
  @Input() isRecipient = false;
  @Input() loading = false;
  @ViewChild('tabRegisterGiver') tabRegisterGiverElement: ElementRef;
  @ViewChild('tabRegisterRecipient') tabRegisterRecipientElement: ElementRef;

  form: FormGroup;
  emailControl: AbstractControl;
  agb: AbstractControl;

  readonly linkToAGB: string = $localize`:@@linkToAGB:https://www.clea.app/allgemeine-geschaeftsbedingungen-agbs/`;
  readonly linkToDSE: string = $localize`:@@linkToDSE:https://www.clea.app/datenschutzerklaerung-clea-assistenzplattform/`;

  constructor(
    private identity: IdentityService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      agb: [false, Validators.requiredTrue]
    });
    this.emailControl = this.form.controls.email;
    this.agb = this.form.controls.agb;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      if (param.role && [ROLE_PA, ROLE_AN].includes(param.role)) {
        this.updateRole(param.role);
      }
    });
  }

  selectGiver(): void {
    this.changeRoleRoute(ROLE_PA);
    this.updateRole(ROLE_PA);
  }

  selectRecipient(): void {
    this.changeRoleRoute(ROLE_AN);
    this.updateRole(ROLE_AN);
  }

  updateRole(role: string): void {
    this.isGiver = role === ROLE_PA;
    this.isRecipient = role === ROLE_AN;
  }

  changeRoleRoute(role: string) {
    this.router.navigate([], {
      queryParams: { ...this.route.snapshot.queryParams, role },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  register(): void {
    if (this.loading) {
      return;
    }

    if (!this.isGiver && !this.isRecipient) {
      return;
    }

    if (this.isGiver) {
      this.startEnrollment('/on-boarding/giver-profile');
    } else {
      this.startEnrollment('/on-boarding/recipient-profile');
    }
  }

  login(): void {
    this.identity.loginWithCheckToken();
  }

  private startEnrollment(absoluteRedirectPath: string): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    const email = this.emailControl.value;
    this.identity.startRegistration(email, (err, _) => {
      if (err) {
        this.modalService.showErrorBox($localize`:@@popup.error.startRegistration:Registrierung konnte nicht gestartet werden.`);
        this.loading = false;
        return;
      }

      this.router.navigate(['/verify-registration'], {
        relativeTo: this.route,
        state: {
          email,
          redirectPath: absoluteRedirectPath
        }
      });
    });
  }
}
