import { Component, OnInit, Input, ContentChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() flavor = '';
  @Input() symbol = '';
  @Input() pulse = false;

  constructor() { }

  ngOnInit(): void {
  }

  public get isSymbol(): boolean {
    return !!this.symbol;
  }
}
