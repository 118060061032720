import { Injectable } from '@angular/core';
import { Observable, from, EMPTY, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IdentityService } from '../identity/identity.service';
import { DocumentModification, Member, ProfileImageModification, Offer, OfferType } from '../marketplace/marketplace.model';
import { AuthService } from '../identity/auth.service';
import { shareReplay, catchError } from 'rxjs/operators';
import { ProfileFormService } from './profile-form.service';
import { ModalService } from 'src/app/ui-library/modal/modal.service';

export type UserRoleType = 'giver' | 'recipient';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  offer$ = new BehaviorSubject<any | null>(null);
  _member: Member | null;

  private _cachedCurrentMember: Observable<Member>;

  constructor(private http: HttpClient,
              private identity: IdentityService,
              private formService: ProfileFormService,
              private auth: AuthService,
              private modal: ModalService) {
  }

  member(id: string): Observable<Member> {
    return this.http.get<Member>(`/api/marketplace/members/${id}`);
  }

  currentMember(reload = false): Observable<Member> {
    if (this._cachedCurrentMember && !reload) {
      return this._cachedCurrentMember;
    }

    this._cachedCurrentMember = this.http.get<Member>(`/api/marketplace/members`).pipe(
      shareReplay(1),
      catchError(err => {
        this._cachedCurrentMember = null;
        this._member = null;
        this.offer$.next(null);
        return EMPTY;
      }));

    this._cachedCurrentMember.subscribe(m => {
      this._member = m;
      this.offer$.next(m?.offers[0]);

      if (!m.agbAccepted) {
        this.modal.showAcceptAgbBox();
      }
    });

    return this._cachedCurrentMember;
  }

  get offer(): Offer {
    if (!this._member || this._member.offers.length === 0) {
      return null;
    }

    return this._member.offers[0];
  }

  get isActive(): boolean {
    return !!(this.offer?.isActive);
  }

  set isActive(value: boolean) {
    if (!this.offer) {
      return;
    }

    const offer = { ...this.offer };
    offer.isActive = value;

    this.http
      .post<void>(`/api/marketplace/offers/toggleactivity`, { isActive: value })
      .toPromise()
      .then(() => this.currentMember(true))
      .then(() => this.offer$.next(offer));
  }

  async registerRecipient(
    member: any,
    profileImage: ProfileImageModification,
    documentUploads: Array<{name: string; type: string; path: string }>
  ): Promise<void> {
    // perform silent authentication after member has been modified to update token with added roles claim
    await this.registerMember(member, profileImage, documentUploads, ['recipient']);
  }

  async registerGiver(
    member: any,
    profileImage: ProfileImageModification,
    documentUploads: Array<{name: string; type: string; path: string }>
  ): Promise<void> {
    await this.registerMember(member, profileImage, documentUploads, ['giver']);
  }

  async modifyMember(
    member: any,
    modifiedProfileImage: ProfileImageModification,
    documentUploads: Array<DocumentModification>
  ): Promise<void> {
    const roles = this.auth.roles;
    await this.http.put('/api/marketplace/members',
      {
        member,
        modifiedProfileImage,
        documentUploads,
        roles
      }).toPromise();
      this._cachedCurrentMember = null;
  }

  async changeEmail(
    email: string
  ): Promise<void> {
    await this.http.put('/api/identity/email',
      {
        email
      }).toPromise();
  }

  async deleteMember(): Promise<void> {
    await this.http.delete('/api/marketplace/members').toPromise();
  }

  downloadDocument(url: string) {
    this.http.get(url, {
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition.match(/filename="(.+)"/)[1];
      this.downloadBlob(response.body, filename);
    });
  }

  private downloadBlob(blob: Blob, filename) {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      // Create a new anchor element
      const a = document.createElement('a');

      // Set the href and download attributes for the anchor element
      // You can optionally set other attributes like `title`, etc
      // Especially, if the anchor element will be attached to the DOM
      a.href = reader.result as string;
      a.download = filename || 'download';

      // Click handler that releases the object URL after the element has been clicked
      // This is required for one-off downloads of the blob content
      const clickHandler = () => {
        setTimeout(() => {
          // URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 500);
      };

      // Add the click event listener on the anchor element
      // Comment out this line if you don't want a one-off download of the blob content
      a.addEventListener('click', clickHandler, false);

      // Programmatically trigger a click on the anchor element
      // Useful if you want the download to happen automatically
      // Without attaching the anchor element to the DOM
      // Comment out this line if you don't want an automatic download of the blob content
      a.click();

      // Return the anchor element
      // Useful if you want a reference to the element
      // in order to attach it to the DOM or use it in some other way
      return a;
    };
    reader.readAsDataURL(blob);
  }

  private async registerMember(
    member: any,
    profileImage: ProfileImageModification,
    documentUploads: Array<{name: string; type: string; path: string }>,
    userRoles: UserRoleType[]
  ): Promise<void> {
    await this.http.post('/api/marketplace/members',
      {
        member,
        modifiedProfileImage: profileImage,
        documentUploads,
        userRoles
      }).toPromise();
      // perform silent authentication after member has been modified to update token with added roles claim
    await this.identity.silentAuthentication(true);
  }
}

export const documentDownloadUrl = (memberId: string, docPath: string): string => `/api/marketplace/documents/${memberId}/${docPath}`;
