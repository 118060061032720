import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { WebAuth } from 'auth0-js';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { I18nService } from './../i18n.service';
import { ModalService } from 'src/app/ui-library/modal/modal.service';

export interface ILoginConfig {
  ui_locales: string;
  title: string;
  login_hint?: string;
}

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  private auth0;

  constructor(
    private config: ConfigService,
    private auth: AuthService,
    private router: Router,
    private http: HttpClient,
    private i18nService: I18nService
  ) {
  }

  init(): Promise<boolean> {
    this.auth0 = new WebAuth({
      clientID: this.config.auth0ClientId,
      domain: this.config.auth0Domain,
      redirectUri: `${window.location.origin}${this.i18nService.siteLocale.prefix}/callback?redirectUri=`,
      audience: this.config.auth0ApiIdentifier,
      responseType: 'token id_token',
      scope: 'openid profile email',
      ui_locales: this.i18nService.siteLocale.code,
    });
    return this.silentAuthentication();
  }

  login(email?: string) {
    const config: ILoginConfig = {
      ui_locales: this.i18nService.siteLocale.code,
      title: this.config.applicationTitle,
    };

    if (email) {
      config.login_hint = email;
    }

    this.auth0.authorize(config);
  }

  loginWithCheckToken(): void {
    this.silentAuthentication().then((hasValidToken) => {
      // if there is a valid token e.g. because of an uncompleted registration
      // first log out to prevent awkward behavior.
      if (hasValidToken) {
        this.logout('/login');
      } else {
        this.login();
      }
    });
  }

  resetPassword() {
    if (this.auth.userProfile.email) {
      this.auth0.changePassword({
        connection: this.config.auth0Connection,
        email: this.auth.userProfile.email,
        ui_locales: this.i18nService.siteLocale.code // it will not work as auth0 filters this param(
      }, (err, resp) => {
        if (err) {
          console.log(err.message);
        }
      });
    }
  }

  async acceptAGB(): Promise<void> {
    return this.http
      .post('/api/marketplace/members/acceptagb', null)
      .toPromise()
      .then();
  }

  async handleLoginCallback(redirectPath: string = '/'): Promise<void> {
    console.log('handleLoginCallback', redirectPath);
    try {
      const authResult = await this.checkSession();
      const profile = await this.getUserInfo(authResult);
      this.auth.setSession(authResult, profile);
      this.router.navigateByUrl(redirectPath);
    } catch (error) {
      console.error(`Error: ${error.error}`);
      this.auth.clearSession();
      this.router.navigateByUrl(redirectPath);

      /* if you see "Error: login_required" message in Safari for staging please read readme.md file */
    }
  }

  async silentAuthentication(force: boolean = false): Promise<boolean> {
    if (force) {
      this.auth.clearSession();
    }
    if (this.auth.hasValidToken) {
      return true;
    }
    try {
      const authResult = await this.checkSession();
      const profile = await this.getUserInfo(authResult);
      this.auth.setSession(authResult, profile);
    } catch (error) {
      console.error(`Error: ${error.error}`);
      this.auth.clearSession();
    }
    console.log('Token silently renewed');
    return this.auth.hasValidToken;
  }

  async getTokenSilently(): Promise<string> {
    await this.silentAuthentication();
    return this.auth.accessToken;
  }

  async parseHash(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      // When Auth0 hash parsed, get profile
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken) {
          resolve(authResult);
        } else {
          reject(err);
        }
      });
    });
  }

  logout(redirectPath: string = '/') {
    // Log out of Auth0 session
    // Ensure that returnTo URL is specified in Auth0
    // Application settings for Allowed Logout URLs
    this.auth.clearSession();
    this.auth0.logout({
      returnTo: `${window.location.origin}${this.i18nService.siteLocale.prefix}/callback?redirectUri=${redirectPath}`,
      clientID: this.config.auth0ClientId,
      ui_locales: this.i18nService.siteLocale.code,
    });
  }

  startRegistration(email: string, cb: (err, res) => void ) {
    this.auth0.passwordlessStart({
      connection: this.config.auth0PasswordlessConnection,
      send: 'code',
      email,
      xRequestLanguage: this.i18nService.siteLocale.code,
    }, cb);
  }

  verifyRegistration(email: string, verificationCode: string, redirectPath: string,
                     cb: (err, res) => void) {
    this.auth0.passwordlessLogin({
      connection: this.config.auth0PasswordlessConnection,
      email,
      verificationCode,
      scope: 'openid email profile',
      redirectUri: `${window.location.origin}${this.i18nService.siteLocale.prefix}/callback?redirectUri=${redirectPath}`,
      ui_locales: this.i18nService.siteLocale.code,
    }, cb);
  }

  private getUserInfo(authResult): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      // Use access token to retrieve user's profile and set session
      this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
        if (profile) {
          resolve(profile);
        } else {
          reject(err);
        }
      });
    });
  }

  private checkSession(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.auth0.checkSession({}, async (err, authResult) => {
        if (authResult && authResult.accessToken) {
          resolve(authResult);
        } else {
          reject(err);
        }
      });
    });
  }
}
