import { Component, OnInit } from '@angular/core';
import { ModalRef } from '../modal-ref';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  title = '';
  text = '';
  image = '/assets/images/alert-octagon.svg';
  btnCancel = $localize`:@@popup.btn.cancel:Abbrechen`;
  btnAccept = $localize`:@@popup.btn.yes:Ja`;

  constructor(private modal: ModalRef<boolean>) {
    this.title = modal.data.title;
    this.text = modal.data.text;
    if (modal.data.image) {
      this.image = modal.data.image;
    }
    if (modal.data.btnCancel) {
      this.btnCancel = modal.data.btnCancel;
    }
    if (modal.data.btnAccept) {
      this.btnAccept = modal.data.btnAccept;
    }
  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.modal.close(false);
  }

  ok(): void {
    this.modal.close(true);
  }

}
