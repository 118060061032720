import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { StartComponent } from './start/start.component';
import { SetupRecipientProfileComponent } from './setup-recipient-profile/setup-recipient-profile.component';
import { SetupRecipientProfileDetailsComponent } from './setup-recipient-profile-details/setup-recipient-profile-details.component';
import { SetupRecipientOfferComponent } from './setup-recipient-offer/setup-recipient-offer.component';
import { SetupGiverProfileComponent } from './setup-giver-profile/setup-giver-profile.component';
import { SetupGiverProfileDetailsComponent } from './setup-giver-profile-details/setup-giver-profile-details.component';
import { SetupGiverOfferComponent } from './setup-giver-offer/setup-giver-offer.component';
import { LoginComponent } from './login/login.component';
import { VerifyComponent } from './verify/verify.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { AuthGuard, PublicAuthGuard, RegistrationAuthGuard } from '../auth.guard';
import { CanDeactivateGuard } from '../ui-library/can-deactivate-guard.service';

const routes: Routes = [
  { path: '', component: StartComponent, canActivate: [PublicAuthGuard] },
  {
    path: 'on-boarding',
    component: OnBoardingComponent,
    data: { isLoggedIn: false },
    canActivate: [PublicAuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [RegistrationAuthGuard],
        children: [
          { path: 'recipient-profile', component: SetupRecipientProfileComponent },
          { path: 'recipient-profile-details', component: SetupRecipientProfileDetailsComponent },
          { path: 'recipient-offer', component: SetupRecipientOfferComponent },
          { path: 'giver-profile', component: SetupGiverProfileComponent },
          { path: 'giver-profile-details', component: SetupGiverProfileDetailsComponent },
          { path: 'giver-offer', component: SetupGiverOfferComponent }
      ] }
    ]
  },
  { path: 'login', component: LoginComponent, canActivate: [PublicAuthGuard] },
  { path: 'verify-registration', component: VerifyComponent, canActivate: [PublicAuthGuard] },
  { path: 'profile', component: ProfileEditComponent, pathMatch: 'full', canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [
    AuthGuard
  ],
  exports: [
    RouterModule
  ]
})
export class ProfileRoutingModule {
}
