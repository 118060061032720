<div class="container">
  <a [routerLink]="['/marketplace', member.id]">
    <h3 class="heading">{{member.firstName}} {{member.lastName}}</h3>
  </a>
  <figure class="avatar" aria-hidden="true">
    <img [src]="member.profilePicture.path" alt="">
  </figure>
  <p class="contact">
    <span class="sr-only" i18n="sr-only@@employment.teamMember.telefon">Telefon:</span>
    <span class="icon icon-phone"></span>
    <a href="tel:{{ member.phoneNumber }}">
      {{ member.phoneNumber }}
    </a>
    <br>
    <span class="sr-only" i18n="sr-only@@employment.teamMember.email">E-Mail:</span>
    <span class="icon icon-mail"></span>
    <a href="mailto:{{ member.emailAddress }}">{{ member.emailAddress }}</a>
  </p>
  <div class="services">
    <span class="sr-only" i18n="sr-only@@employment.teamMember.assistanceServices">Assistenzdienstleistungen:</span>
    <app-tag-list [tags]="offer.assistanceServices" center="true"></app-tag-list>
  </div>
</div>
