<main [cdkTrapFocus]="isTrapActive" cdkTrapFocusAutoCapture class="layout-panel-centered">
  <form class="panel overflowing" [formGroup]="step2">
    <header class="panel-header">
      <h1 class="sr-only" i18n="sr-only@@profile.setupGiverProfile.title">Registrierungsprozess</h1>
      <ol class="process-steps heading" aria-label="Schritte">
        <li class="completed d-none d-sm-flex"><a routerLink="/on-boarding/giver-profile" [state]="navigateState" i18n="@@profile.setupGiverOffer.profile">1. Profil</a></li>
        <li class="completed"><a routerLink="/on-boarding/giver-profile-details" [state]="navigateState" aria-current="step" i18n="@@profile.setupGiverOffer.information">2. Angaben</a></li>
        <li class="d-none d-sm-flex" i18n="@@profile.setupGiverOffer.assistance">3. Assistenz</li>
      </ol>
      <a routerLink="/" class="cancel" i18n="@@profile.setupGiverOffer.btn.cancel">Abbrechen</a>
    </header>
    <div class="panel-content transportation-illustration">
      <p class="text" i18n="@@profile.setupGiverProfileDetails.info.moreDetails">
        Um Sie mit passenden möglichen Arbeitgeber/innen (Assistenznehmende) in Verbindung zu bringen, brauchen wir einige Angaben von Ihnen. Diese Angaben sind nicht öffentlich zugänglich.
      </p>

      <app-giver-profile-details [parentGroup]="step2" [form]="form"></app-giver-profile-details>
        <!-- id is linked in .ts file -->
    </div>
    <div class="panel-footer">
      <button type="button" class="button block-xs" (click)="back()" i18n="@@profile.setupGiverOffer.btn.back">Zurück</button>
      <button type="button" class="button primary block-xs" (click)="next()" i18n="@@profile.setupGiverOffer.btn.next">Weiter</button>
    </div>
  </form>
</main>
