import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ViewChild } from '@angular/core';
import { MarketplaceService } from '../marketplace.service';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { SelectionTag } from '../../ui-library/models/selection-tag';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { AdvancedSearchMaskComponent } from '../advanced-search-mask/advanced-search-mask.component';
import { Location } from 'src/app/marketplace/marketplace.model';
import { AuthService } from 'src/app/identity/auth.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-search-mask',
  templateUrl: './search-mask.component.html',
  styleUrls: ['./search-mask.component.scss']
})
export class SearchMaskComponent implements OnInit, OnDestroy {
  @ViewChild('locationSelect') locationSelect: NgSelectComponent;

  @Input() parentGroup: FormGroup;
  @Input() unsavedQuery: boolean;
  @Input() isSaving = false;
  @Output() saveQuery = new EventEmitter();

  selectedLocation = new FormControl();

  locationInput$ = new Subject<string>();
  locations$: Observable<Location[]>;
  locationLoading: boolean;

  addService = new FormControl(null);
  allAvailableServices: SelectionTag[] = [];
  selectableServices: SelectionTag[] = [];
  selectedServices: SelectionTag[] = [];

  private addServicesSubscription: Subscription;
  private availableAssistanceServicesSubscription: Subscription;
  private assistanceServicesControlSubscription: Subscription;

  constructor(
    private marketplace: MarketplaceService,
    private modalService: ModalService,
    public auth: AuthService) { }

  get locationControl(): AbstractControl {
    return this.parentGroup.controls.location;
  }

  get assistanceServicesControl(): AbstractControl {
    return this.parentGroup.controls.assistanceServices;
  }

  ngOnInit() {
    this.locations$ = concat(
      of([]),
      this.locationInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.locationLoading = true),
        switchMap(term => this.marketplace.queryLocation(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.locationLoading = false)
        ))
      )
    );

    this.availableAssistanceServicesSubscription = this.marketplace.getAvailableAssistanceServices().subscribe(s => {
      this.allAvailableServices = s;
      this.selectableServices = s;
      this.assistanceServicesControl.updateValueAndValidity();
    });

    this.addServicesSubscription = this.addService.valueChanges.subscribe((tag) => {
      // this is called when a service is selected in the combo box
      // -> add it to selectedServices and reset combo box selection (= selectedService)
      this.selectedServices = [...this.selectedServices, tag];
      this.selectableServices = this.allAvailableServices.filter(s => !this.selectedServices.includes(s));
      this.assistanceServicesControl.setValue(this.selectedServices.map(s => s.key));
      this.assistanceServicesControl.markAsDirty();
      this.addService.setValue(null, {emitEvent: false});
    });

    this.assistanceServicesControlSubscription = this.assistanceServicesControl.valueChanges.subscribe((tags) => {
      this.selectedServices = this.allAvailableServices.filter(s => tags.find(t => t === s.key));
      this.selectableServices = this.allAvailableServices.filter(s => !this.selectedServices.includes(s));
    });
    this.assistanceServicesControl.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.availableAssistanceServicesSubscription.unsubscribe();
    this.addServicesSubscription.unsubscribe();
    this.assistanceServicesControlSubscription.unsubscribe();
  }

  unselectService(tag: SelectionTag) {
    this.selectedServices = this.selectedServices.filter(s => s !== tag);
    this.selectableServices = this.allAvailableServices.filter(s => !this.selectedServices.includes(s));
    this.assistanceServicesControl.setValue(this.selectedServices.map(s => s.key));
    this.assistanceServicesControl.markAsDirty();
  }

  openAdvancedSearch(): void {
    this.modalService.show(AdvancedSearchMaskComponent, this.parentGroup);
  }

  save(): void {
    if (this.selectedServices.length === 0) {
      this.modalService.showErrorBox('', $localize`:@@shared.recipientOffer.error.assistanceServices:Bitte wählen Sie mindestens 1 Kategorie aus, um fortzufahren.`);
      return;
    }
    this.saveQuery.emit();
  }

  clearLocation() {
    this.locationSelect.clearModel();
  }
}
