import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UiLibraryBrowserComponent } from './ui-library/ui-library-browser/ui-library-browser.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth.guard';
import { CallbackComponent } from './identity/callback/callback.component';

const appRoutes: Routes = [
  { path: 'callback', component: CallbackComponent, pathMatch: 'full' },
  { path: 'component-browser', component: UiLibraryBrowserComponent, pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      }
    )
  ],
  providers: [
    AuthGuard
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
