import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EmploymentService } from '../employment.service';
import { Relation } from '../employment.model';

@Component({
  selector: 'app-jobs-overview',
  templateUrl: './jobs-overview.component.html',
  styleUrls: ['./jobs-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsOverviewComponent implements OnInit {
  isLoaded = false;

  readonly emailFeedbackPlatfrom: string = 'mailto:feedback@clea.app?subject=' + encodeURIComponent($localize`:@@emailFeedbackPlatfrom:Feedback Nutzung CléA Jobplattform`);

  constructor(
    public employment: EmploymentService,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.employment.loadRelations('Employee').subscribe({
      complete: () => {
        this.isLoaded = true;
        this._cdr.detectChanges();
      },
      error: () => {
        this.isLoaded = true;
        this._cdr.detectChanges();
      }
    });
  }

}
