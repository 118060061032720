import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmploymentComponent } from './employment/employment.component';
import { TeamOverviewComponent } from './team-overview/team-overview.component';
import { AuthGuard, RoleANGuard, RolePAGuard } from '../auth.guard';
import { JobsOverviewComponent } from './jobs-overview/jobs-overview.component';

const employmentRoutes: Routes = [
  {
    path: 'team',
    component: EmploymentComponent,
    canActivate: [AuthGuard, RoleANGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '',  component: TeamOverviewComponent }
    ]
  },
  {
    path: 'jobs',
    component: EmploymentComponent,
    canActivate: [AuthGuard, RolePAGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '',  component: JobsOverviewComponent }
    ]
  }
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(employmentRoutes)
  ],
  providers: [
    AuthGuard
  ],
  exports: [
    RouterModule
  ]
})
export class EmploymentRoutingModule { }
