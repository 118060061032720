<div class="main"
     [class.empty]="!src">
  <div #imageEditor class="image-editor">
    <div class="croppie-container">
      <img #croppie="croppie" [croppieOptions]="croppieOptions" />
    </div>
    <button type="button"
            class="button secondary icon-only close adjust-jh"
            i18n-aria-label="@@profile.imageSelector.btn.remove"
            aria-label="Profilbild entfernen"
            (click)="removeProfileImage()">
      <span class="icon icon-x"></span>
    </button>
  </div>
  <div class="placeholder">
    <input #input id="file-chooser" type="file" accept="image/*" class="file-chooser"
           (change)="loadProfileImage(input.files)">
    <label
      for="file-chooser"
      i18n="@@profile.imageSelector.placeholder.selectFile"
      i18n-title="@@profile.imageSelector.placeholder.selectFile"
      title="Profilbild auswählen"
    >Profilbild auswählen</label>
  </div>
</div>
