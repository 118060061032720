import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalRef } from '../modal-ref';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
  title = '';
  text = '';
  image = '';

  constructor(private modal: ModalRef) {
    this.title = modal.data.title;
    this.text = modal.data.text;
    this.image = modal.data.image;
   }

  ngOnInit(): void {
  }

  ok(): void {
    this.modal.close();
  }
}
