import { Component, OnInit } from '@angular/core';
import { ModalRef } from '../modal-ref';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ReferenceService } from 'src/app/reference.service';
import { Member, Reference } from 'src/app/marketplace/marketplace.model';

@Component({
  selector: 'app-reference-edit',
  templateUrl: './reference-edit.component.html',
  styleUrls: ['./reference-edit.component.scss']
})
export class ReferenceEditComponent implements OnInit {
  member: Member;
  reference: Reference;
  isLoading$ = new BehaviorSubject<boolean>(false);
  referenceForm = new FormGroup({
    comment: new FormControl('', [Validators.required, Validators.maxLength(160)])
  });

  constructor(
    private modal: ModalRef,
    private referenceService: ReferenceService) { }

  get comment(): AbstractControl {
    return this.referenceForm.get('comment');
  }

  ngOnInit(): void {
    this.member = this.modal.data.member;
    this.reference = this.modal.data.reference;
    const initialComment = this.reference ? this.reference.comment : '';
    this.comment.setValue(initialComment);
  }

  cancel() {
    this.modal.close();
  }

  save() {
    this.referenceForm.markAsTouched();
    if (this.referenceForm.invalid) {
      return;
    }

    this.isLoading$.next(true);
    this.referenceService.saveReference(this.member.id, this.comment.value)
      .then(() => {
        this.reference.comment = this.comment.value;
        this.comment.setValue('');
        this.isLoading$.next(false);
        this.referenceForm.markAsPristine();
        this.referenceForm.markAsUntouched();
        this.modal.close(this.reference);
      })
      .catch(() => this.isLoading$.next(false));
  }
}
