import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MemberRelationData, Relation } from '../employment.model';
import { Member, Offer, OfferType, Reference } from 'src/app/marketplace/marketplace.model';
import { InvitationService } from 'src/app/invitation.service';
import { EmploymentService } from '../employment.service';

@Component({
  selector: 'app-invitee-placeholder',
  templateUrl: './invitee-placeholder.component.html',
  styleUrls: ['./invitee-placeholder.component.scss']
})
export class InviteePlaceholderComponent {
  @Input() memberRelation: MemberRelationData;
  @Output() removed = new EventEmitter<MemberRelationData>();
  sending: boolean;

  constructor(
    private _employmentService: EmploymentService,
    private _invitation: InvitationService) { }

  clearPlaceholder(memberRelation: MemberRelationData): void {
    this._employmentService.removeFromTeam('Employer', memberRelation.relation).subscribe({
      complete: () => this.removed.emit(memberRelation)
    });
  }

  resendInvitation(member: Member): void {
    this._invitation.inviteMember(member.emailAddress, false);
    this.sending = true;
    setTimeout(() => this.sending = false, 5000);
  }
}
