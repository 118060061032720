import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Message } from '../models/message';
import { SelectionTag, SelectionTagKeyType, TagStatus } from '../models/selection-tag';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-ui-library-browser',
  templateUrl: './ui-library-browser.component.html',
  styleUrls: ['./ui-library-browser.component.scss']
})
export class UiLibraryBrowserComponent implements OnInit {
  messages: Message[] = [
    {
      timestamp: new Date(),
      text: 'Lorem ipsum incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla pariatur. Excepteur dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor sint occaecat cupidatat non consequat.',
      messageType: 'Sent'
    },
    {
      timestamp: new Date(),
      text: 'System Message',
      messageType: 'System'
    },
    {
      timestamp: new Date(),
      text: 'Lorem ipsum incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla pariatur. Excepteur dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor sint occaecat cupidatat non consequat.',
      messageType: 'Received'
    }
  ];
  segmentedSelectionTags: SelectionTag[] = [
    {
      key: 'option1',
      name: 'Option 1'
    },
    {
      key: 'option2',
      name: 'Option 2'
    },
    {
      key: 'option3',
      name: 'Option 3'
    }
  ];
  tags: SelectionTag[] = [
    {
      key: 'cooking',
      name: 'Kochen'
    },
    {
      key: 'care',
      name: 'Pflege'
    },
    {
      key: 'transport',
      name: 'Transport'
    },
    {
      key: 'shopping',
      name: 'Shopping'
    }
  ];
  selectableTags: SelectionTag[] = [
    {
      key: 'cooking',
      name: 'Kochen'
    },
    {
      key: 'care',
      name: 'Pflege'
    },
    {
      key: 'transport',
      name: 'Transport'
    },
    {
      key: 'shopping',
      name: 'Shopping'
    }
  ];

  selectedTags: SelectionTagKeyType[] = ['care'];

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openDialog() {
    this.modalService.showMessageBox('Geschafft', 'Sie können ihre Angaben jederzeit im «Profil» anpassen. Um ihnen bei der Suche zu helfen, haben wir bereits passende Assistenten gesucht.', '/assets/images/illustration-01.svg');
  }

  openErrorDialog() {
    this.modalService.showErrorBox('Die Anfrage konnte nicht übermittelt werden.');
  }
}
