import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../ui-library/modal/modal.service';
import { IdentityService } from 'src/app/identity/identity.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent {

  form: FormGroup;
  verificationCodeControl: AbstractControl;
  isLoading$ = new BehaviorSubject<boolean>(false);

  private readonly registrationState: object;

  constructor(
    private identity: IdentityService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      verificationCode: ['', [Validators.required, Validators.pattern('[ ]*[0-9]{6,6}[ ]*')]]
    });
    this.verificationCodeControl = this.form.controls.verificationCode;

    this.registrationState = this.router.getCurrentNavigation()?.extras.state;
    if (!this.registrationState) {
      this.router.navigate(['/']);
    }
  }

  verifyEnrollment(): void {
    const verificationCode = this.verificationCodeControl.value.trim();

    this.isLoading$.next(true);
    this.identity.verifyRegistration(
      this.registrationState['email'],
      verificationCode,
      this.registrationState['redirectPath'],
      (err, _) => {
        this.isLoading$.next(false);
        if (err) {
          if (err.description.includes('expired')) {
            this.modalService.showErrorBox(
              $localize`:@@popup.error.verifyEnrollmentExpired:Die Verifikationsnummer ist abgelaufen. Bitte fordern Sie eine neue Verifikationsnummer an`
            );
          } else {
            this.modalService.showErrorBox($localize`:@@popup.error.verifyEnrollment:Verifikation fehlgeschlagen`);
          }
        }
      }
    );
  }

  requestVerificationCode() {
    this.identity.startRegistration(
      this.registrationState['email'],
      (err, _) => {
        if (err) {
          this.modalService.showErrorBox($localize`:@@popup.error.requestVerificationCode:Code konnte nicht angefordert werden.`);
        } else {
          this.modalService.showMessageBox(
            $localize`:@@popup.error.requestVerificationCode.title.main:Verifikationsnummer angefordert`,
            $localize`:@@popup.error.requestVerificationCode.content.main:Wir haben Ihnen eine neue Verifikationsnummer an Ihre E-Mail-Adresse geschickt.`
          );
        }
      }
    );
  }
}
