<div class="panel sticky-footer">
  <header class="panel-header">
    <h1 class="heading" i18n="@@marketplace.advancedSearchMask.titleFilter">Filter</h1>
    <button class="cancel" (click)="close()" i18n="@@marketplace.advancedSearchMask.btnClose">Schliessen</button>
  </header>
  <div class="panel-content" [formGroup]="form">
    <div class="grid">
      <div class="form-group" [class]="{'span-8': auth.isGiver, 'span-12': auth.isRecipient}">
        <label for="input-location" class="label" i18n="@@marketplace.advancedSearchMask.placeOfUse">Einsatzort</label>
        <ng-select #locationSelect
                  labelForId="input-location"
                  class="typeahead"
                  i18n-placeholder="@@marketplace.advancedSearchMask.placeOfUsePlaceholder"
                  placeholder="Einsatzort auswählen..."
                  bindLabel="zipAndName"
                  i18n-typeToSearchText="@@shared.giverOffer.placeholder.plzTypeToSearchText"
                  typeToSearchText="PLZ/Ortsnamen eingeben"
                  notFoundText="Keine Treffer"
                  [items]="locations$ | async"
                  [typeahead]="locationInput$"
                  [formControl]="locationControl"
                  (change)="updateLocation($event)"
                  (keydown.delete)="clearLocation()">
        </ng-select>
        <div *ngIf="locationControl.invalid && (locationControl.touched || locationControl.dirty)"
            class="validation error" i18n="@@marketplace.advancedSearchMask.invlaidPlaceOfUse">Bitte wählen Sie den Einsatzort aus.</div>
      </div>

      <div *ngIf="auth.isGiver" class="form-group span-4">
        <label for="input-range" class="label" i18n="@@marketplace.advancedSearchMask.area">Einsatzgebiet (in Kilometer)</label>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          labelForId="input-range"
          formControlName="range"
          appAddIconChevronDown>
          <ng-option value="5">5</ng-option>
          <ng-option value="10">10</ng-option>
          <ng-option value="25">25</ng-option>
          <ng-option value="50">50</ng-option>
          <ng-option value="100">100</ng-option>
        </ng-select>
        <div *ngIf="rangeControl.invalid && (rangeControl.touched || rangeControl.dirty)"
            class="validation error">
          <span *ngIf="rangeControl.errors.required" i18n="@@marketplace.advancedSearchMask.areaInvalidRequired">Bitte wählen Sie Ihren Einsatzradius aus.</span>
          <span *ngIf="rangeControl.errors.min" i18n="@@marketplace.advancedSearchMask.areaInvalidMin">Der Radius muss mindestens 1&nbsp;km betragen.</span>
        </div>
      </div>

      <div class="form-group span-12">
        <label id="input-assistance-services" class="label">
          <ng-container i18n="@@marketplace.advancedSearchMask.services">Dienstleistungen</ng-container>
          <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
        </label>
        <div
          class="form-group span-12 information-text information-text--not-shure-terms"
          i18n="@@recipientOffer.informationText.notShureTerms"
        >Sind sie nicht ganz sicher, was die untenstehenden Begriffe bedeuten oder welche Aufgaben damit verbunden sind? Kein Problem <a [href]="linkToFilterDescription" target="_blank">lesen sie es hier nach.</a></div>
        <app-tag-selector labelledby="input-assistance-services"
                          [selectableTags]="availableAssistanceServices$ | async"
                          [value]="assistanceServicesControl.value"
                          (valueChange)="assistanceServicesControl.setValue($event); assistanceServicesControl.markAsDirty();">
        </app-tag-selector>
      </div>

      <div class="form-group span-12">
        <label id="input-kind" class="label" i18n="@@marketplace.advancedSearchMask.typeOfAssistance">Art der Assistenz</label>
        <app-segment-button-list labelledby="input-kind"
                                [selectableTags]="availableKinds$ | async"
                                [value]="compensationControl.value"
                                (valueChange)="compensationControl.setValue($event); compensationControl.markAsDirty();">
        </app-segment-button-list>
      </div>

      <div class="span-12">
        <div class="form-group">
          <label for="input-languages" class="label" i18n="@@marketplace.advancedSearchMask.languages">Sprachen</label>
          <ng-select labelForId="input-languages"
                    i18n-placeholder="@@marketplace.advancedSearchMask.languagesPlaceholder"
                    placeholder="Hinzufügen..."
                    bindLabel="name"
                    [items]="availableLanguages"
                    [clearable]="false"
                    [formControl]="addSelectedLanguageControl"
                    appAddIconChevronDown>
          </ng-select>
        </div>

        <div class="form-group">
          <app-tag-selector labelledby="input-assistance-services"
                            [selectableTags]="favoriteLanguages"
                            [value]="languagesControl.value"
                            (valueChange)="languagesControl.setValue($event); languagesControl.markAsDirty();">
          </app-tag-selector>
        </div>
      </div>

      <div class="form-group span-12">
        <label id="input-gender" class="label" i18n="@@marketplace.advancedSearchMask.sex">Wunschgeschlecht</label>
        <app-segment-button-list labelledby="input-gender"
                                [selectableTags]="availableGenders$ | async"
                                [value]="genderControl.value"
                                (valueChange)="genderControl.setValue($event); genderControl.markAsDirty();">
        </app-segment-button-list>
      </div>

    </div>
  </div>
  <div class="panel-footer">
    <button class="button primary block-xs" (click)="close()" i18n="@@marketplace.advancedSearchMask.btnSearch">Suchen</button>
  </div>
</div>
