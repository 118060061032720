import { Component, OnInit, Input } from '@angular/core';
import { SelectionTag, TagStatus } from '../models/selection-tag';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {
  @Input() tags: SelectionTag[];
  @Input() status: TagStatus;
  @Input() block = false;
  @Input() center = false;

  constructor() { }

  ngOnInit(): void {
  }

}
