import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { SearchResult, ORDER_BY_DISTANCE, ORDER_BY_SCORE } from '../marketplace.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SearchService } from '../search.service';
import { LOCALSTORAGE_SORT_ORDER_KEY, OrderBy } from './../marketplace.model';

@Component({
  selector: 'app-hitlist',
  templateUrl: './hitlist.component.html',
  styleUrls: ['./hitlist.component.scss']
})
export class HitlistComponent implements OnInit, OnChanges {
  @Input() parentGroup: FormGroup;
  @Input() hitlist: SearchResult;
  @Input() unsavedQuery: boolean;
  @Input() loadMoreActive: boolean;
  @Input() isSaving = false;
  @Output() saveQuery = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @ViewChild('loadMoreBtn') loadMoreBtn: ElementRef;

  readonly ORDER_BY_DISTANCE = ORDER_BY_DISTANCE;
  readonly ORDER_BY_SCORE = ORDER_BY_SCORE;

  constructor(
    public search: SearchService
  ) { }

  ngOnInit(): void {
  }

  get sortFieldControl(): AbstractControl {
    return this.parentGroup.get('sortField');
  }

  get hasResults(): boolean {
    return this.hitlist && this.hitlist.totalMatches > 0;
  }

  get hasMoreResults(): boolean {
    return this.hitlist.currentPage < this.hitlist.totalPages;
  }

  ngOnChanges(changes): void {
    if (!changes.hitlist) {
      return;
    }

    if (!this.loadMoreActive) {
      return;
    }
    this.loadMoreActive = false;
  }

  toggleSort(orderBy: OrderBy) {
    this.parentGroup.patchValue({ sortField: orderBy });
    localStorage.setItem(LOCALSTORAGE_SORT_ORDER_KEY, orderBy);
  }

  save(): void {
    this.saveQuery.emit();
  }

  loadMoreResults(): void {
    if (this.hasMoreResults) {
      this.loadMoreActive = true;
      this.loadMore.emit();
    }
  }
}
