import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.css']
})
export class OnBoardingComponent implements OnInit, OnDestroy {

  constructor() {
    window.addEventListener('beforeunload', this.setBeforeunloadPopup);
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.setBeforeunloadPopup);
  }

  private setBeforeunloadPopup(event) {
    event.preventDefault();
    event.returnValue = 'Unsaved modifications';
    return event;
  }

}
