<div class="message-modal message-modal--accept-agb">
  <img src="/assets/images/illustration-58.svg" class="header-image" alt="" aria-hidden="true">
  <h1 id="modal-title" class="title" i18n="@@popup.acceptAgb.title">Neue AGB und DSE</h1>
  <p class="body" i18n="@@popup.acceptAgb.text">Liebe*r Nutzer*in wir haben die AGB und DSE aktualisiert. Bitte akzeptieren Sie die Änderungen um fortzufahren.</p>

  <p class="message-modal__checkbox">
    <label class="custom-checkbox">
      <input type="checkbox" [(ngModel)]="agb" [ngModelOptions]="{standalone: true}" />
      <span i18n="@@popup.acceptAgb.agbCheckbox"><a [href]="linkToAGB" target="_blank">AGB</a> akzeptiere</span>
    </label>
  </p>
  <p class="message-modal__checkbox">
    <label class="custom-checkbox">
      <input type="checkbox" [(ngModel)]="dse" [ngModelOptions]="{standalone: true}" />
      <span i18n="@@popup.acceptAgb.dseCheckbox"><a [href]="linkToDSE" target="_blank">DSE</a> akzeptiere</span>
    </label>
  </p>

  <div class="action">
    <button class="button primary" [disabled]="!acceptedAll" (click)="ok()" i18n="@@popup.acceptAgb.accept">Fertig</button>
  </div>
</div>
