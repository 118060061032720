<h2 *ngIf="auth.isRecipient" class="heading" i18n="@@marketplace.searchMask.recipientTitle">Assistenten finden</h2>
<h2 *ngIf="auth.isGiver" class="heading" i18n="@@marketplace.searchMask.giverTitle">Assistenznehmer finden</h2>

<div>
  <div class="form-group">
    <label for="search-location" class="label" i18n="@@marketplace.searchMask.formPlaceOfUse">Einsatzort</label>

    <ng-select #locationSelect
               labelForId="search-location"
               class="typeahead"
               i18n-placeholder="@@marketplace.searchMask.formPlaceholderChose"
               placeholder="Auswählen..."
               bindLabel="zipAndName"
               i18n-typeToSearchText="@@shared.giverOffer.placeholder.plzTypeToSearchText"
               typeToSearchText="PLZ/Ortsnamen eingeben"
               notFoundText="Keine Treffer"
               [items]="locations$ | async"
               [typeahead]="locationInput$"
               [formControl]="locationControl"
               (keydown.delete)="clearLocation()">
    </ng-select>
    <div *ngIf="locationControl.invalid && (locationControl.touched || locationControl.dirty)"
        class="validation error">Bitte wählen Sie den Einsatzort aus.</div>
  </div>

  <div class="form-group">
    <label id="search-services-label" for="search-services" class="label" i18n="@@marketplace.searchMask.formAssistance">Assistenz</label>
    <ng-select labelForId="search-services"
               aria-controls="selected-services"
               i18n-placeholder="@@marketplace.searchMask.formPlaceholderChose"
               placeholder="Auswählen..."
               bindLabel="name"
               [clearable]="false"
               [searchable]="false"
               [items]="selectableServices"
               [formControl]="addService"
               appAddIconChevronDown>
    </ng-select>

    <ul id="selected-services" class="tag-list vertical" role="group" aria-labelledby="search-services-label" aria-live="polite">
      <li *ngFor="let service of selectedServices">
        <button class="tag-button"
                role="button"
                aria-controls="selected-services"
                [attr.aria-label]="service.name + ' entfernen'"
                (click)="unselectService(service)">
          <app-tag status="Added">{{service.name}}</app-tag>
        </button>
      </li>
    </ul>

  </div>
</div>

<button class="button block" (click)="openAdvancedSearch()" i18n="@@marketplace.searchMask.btnOpenAdvancedSearch">Filter anzeigen</button>

<div *ngIf="auth.isRecipient" class="save-query-section">
  <p *ngIf="unsavedQuery" i18n="@@marketplace.searchMask.recipientFormUnsavedQuery">Sie haben die Suche geändert. Speichern Sie die Suche in Ihrem Profil, um beim nächsten Besuch dieselbe Suche direkt verfügbar zu haben.</p>
  <p *ngIf="!unsavedQuery" i18n="@@marketplace.searchMask.recipientFormNoUnsavedQuery">Sie haben die Suche in Ihrem Profil gespeichert.</p>
  <button class="button block" (click)="save()" [disabled]="!unsavedQuery || isSaving" i18n="@@marketplace.searchMask.recipientFormBtnSave">Diese Suche speichern</button>
</div>
<div *ngIf="auth.isGiver" class="save-query-section">
  <p *ngIf="unsavedQuery" i18n="@@marketplace.searchMask.giverFormUnsavedQuery">Sie haben neue Leistungen gesucht. Möchten Sie Ihr Profil entsprechend aktualisieren um mit diesen Leistungen gefunden zu werden?</p>
  <p *ngIf="!unsavedQuery" i18n="@@marketplace.searchMask.giverFormNoUnsavedQuery">Sie haben die Leistungen in Ihrem Profil gespeichert.</p>
  <button class="button block" (click)="save()" [disabled]="!unsavedQuery || isSaving" i18n="@@marketplace.searchMask.giverFormBtnSave">Leistungen aktualisieren</button>
</div>
