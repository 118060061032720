import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileFormService } from '../profile-form.service';
import { FormGroup } from '@angular/forms';
import { ScreenService } from 'src/app/ui-library/screen.service';

@Component({
  selector: 'app-setup-giver-profile-details',
  templateUrl: './setup-giver-profile-details.component.html',
  styleUrls: ['./setup-giver-profile-details.component.scss']
})
export class SetupGiverProfileDetailsComponent implements OnInit, OnDestroy {
  readonly form: FormGroup;
  readonly step2: FormGroup;
  isTrapActive = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private renderer: Renderer2,
              private screenService: ScreenService,
              formService: ProfileFormService) {
    this.isTrapActive = this.screenService.isMobileScreen();
    this.form = formService.createGiver();
    this.step2 = this.form.get('step2') as FormGroup;
  }

  ngOnInit() {
    // add class to body to prevent scrolling in the background on mobile screen
    this.renderer.addClass(document.body, 'full-screen');

    const formState = window.history.state?.onboardingForm;
    if (formState) {
      this.form.setValue(formState);
    } else {
      this.router.navigate(['../giver-profile'], {
        relativeTo: this.route
      });
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'full-screen');
  }

  back() {
    // the user is not allowed to navigate back while uploading
    const docControl = this.step2.controls.documents;
    if (docControl.invalid) {
      docControl.markAsTouched();
      return;
    }

    this.router.navigate(['../giver-profile'], {
      relativeTo: this.route,
      state: this.navigateState
    });
  }

  next() {
    if (!this.step2.valid) {
      this.step2.markAllAsTouched();
      return;
    }

    this.router.navigate(['../giver-offer'], {
      relativeTo: this.route,
      state: this.navigateState
    });
  }

  get navigateState() {
    return {
      onboardingForm: this.form.value
    };
  }
}
