import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../models/message';

@Component({
  selector: 'app-message-thread',
  templateUrl: './message-thread.component.html',
  styleUrls: ['./message-thread.component.scss']
})
export class MessageThreadComponent implements OnInit {
  @Input() messages: Message[];
  @Input() companionName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
