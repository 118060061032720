import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModalRef } from '../modal-ref';
import { IdentityService } from '../../../identity/identity.service';

@Component({
  selector: 'app-accep-agb-box',
  templateUrl: './accpet-agb-box.component.html',
  styleUrls: ['./accpet-agb-box.component.css']
})
export class AcceptAgbBoxComponent implements OnInit {

  readonly linkToAGB: string = $localize`:@@linkToAGB:https://www.clea.app/allgemeine-geschaeftsbedingungen-agbs/`;
  readonly linkToDSE: string = $localize`:@@linkToDSE:https://www.clea.app/datenschutzerklaerung-clea-assistenzplattform/`;

  agb = false;
  dse = false;

  constructor(
    private modal: ModalRef,
    private identity: IdentityService,
  ) {
  }

  get acceptedAll(): boolean {
    return this.agb && this.dse;
  }

  ngOnInit(): void {
  }

  async ok(): Promise<void> {
    if (!this.acceptedAll) {
      return;
    }

    await this.identity.acceptAGB();

    this.modal.close();
  }
}
