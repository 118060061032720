<div class="hitlist-container">
  <header class="hitlist-header">
    <h2 class="sr-only" i18n="sr-only@@marketplace.hitlist.title">Gefundene Personen</h2>

    <div class="header-info">
      <ng-container *ngIf="hasResults">
        <span>
          <strong>{{hitlist.totalMatches}}
            <ng-container i18n="@@marketplace.hitlist.goal">Treffer</ng-container>
          </strong>,
        </span>
        <div class="sort-selector">
          <button class="link" *ngIf="sortFieldControl.value === ORDER_BY_SCORE" (click)="toggleSort(ORDER_BY_DISTANCE)">
            <ng-container i18n="@@marketplace.hitlist.sortRelevanceScore">Sortiert nach Übereinstimmung</ng-container>
            <span class="caret"></span>
          </button>
          <button class="link" *ngIf="sortFieldControl.value === ORDER_BY_DISTANCE" (click)="toggleSort(ORDER_BY_SCORE)">
            <ng-container i18n="@@marketplace.hitlist.sortDistance">Sortiert nach Entfernung</ng-container>
            <span class="caret"></span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!hasResults">
        <strong  i18n="@@marketplace.hitlist.noMatches">Keine Treffer</strong>
      </ng-container>
    </div>
  </header>
  <app-no-results *ngIf="!hasResults" [unsavedQuery]="unsavedQuery" [isSaving]="isSaving" (saveQuery)="save()"></app-no-results>

  <ng-container *ngIf="hasResults">
    <ol class="hitlist">
      <ng-container *ngFor="let result of hitlist.matches; index as i">
        <li class="item">
          <a [routerLink]="['/marketplace', result.member.id]" class="member-link">
            <article>
              <app-hitlist-item [match]="result"></app-hitlist-item>
            </article>
          </a>
        </li>
        <li *ngIf="(i === 1) || (hitlist.totalMatches < 2 && i === hitlist.totalMatches - 1)" class="item">
          <app-invitation></app-invitation>
        </li>
      </ng-container>
    </ol>

    <footer class="hitlist-footer">
      <button class="button" [disabled]="!hasMoreResults || (search.isMoreLoading$ | async)" #loadMoreBtn (click)="loadMoreResults()" i18n="@@marketplace.hitlist.loadMore">Mehr Treffer anzeigen</button>
    </footer>
  </ng-container>
</div>
