import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { EmploymentService } from '../../employment/employment.service';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MultiMessage, Relation, MESSAGE_TYPE_RECEIVED, MESSAGE_TYPE_SENT, DATATYPE_TEXT, MultiMessagePost } from '../../employment/employment.model';

@Component({
  selector: 'app-multi-messages',
  templateUrl: './multi-messages.component.html',
  styleUrls: ['./multi-messages.component.scss']
})
export class MultiMessagesComponent implements OnInit {
  @Input() companionName: string;
  @Input() relation: Relation;
  @ViewChild('replyControl') replyControl: ElementRef;

  public replyFormControl: FormControl;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public messages: Array<MultiMessage> = [];

  readonly RECEIVED = MESSAGE_TYPE_RECEIVED;
  readonly SENT = MESSAGE_TYPE_SENT;

  constructor(private _employment: EmploymentService) {
    this.replyFormControl = new FormControl('', [Validators.required, Validators.maxLength(320)]);
  }

  ngOnInit(): void {
    this.loadMessages();
  }

  async loadMessages() {
    this.messages = await this._employment.getMessages(this.relation);
  }

  request(): void {
    if (this.replyFormControl.invalid) {
      this.replyFormControl.markAsTouched();
      return;
    }

    this.isLoading$.next(true);
    this.replyFormControl.disable();

    const message: MultiMessagePost = {
      data: {
        text: this.replyFormControl.value
      },
      dataType: DATATYPE_TEXT,
    };
    this._employment.sendMessage(this.relation, message).subscribe({
      next: multiMessage => {
        this.messages.unshift(multiMessage);
      },
      complete: () => {
        this.replyFormControl.enable();
        this.replyFormControl.reset();
        this.isLoading$.next(false);
      },
      error: (ex) => {
        this.replyFormControl.enable();
        this.isLoading$.next(false);
      }
    });

  }

}
