import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(private http: HttpClient) { }

  async saveReference(memberId: string, comment: string): Promise<void> {
    return this.http
      .post<void>(`/api/references/${memberId}`, {
        comment
      }).toPromise();
  }
}
