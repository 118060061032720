<section class="relation-status">
  <ng-container *ngIf="readyForRequest || sharingRequested">
    <h2 class="relation-status__title" i18n="@@employment.relations.advancedProfile">Erweitertes Profil</h2>
    <p class="relation-status__p" i18n="@@employment.relations.contentDataProtectionReason">Aus Datenschutzgründen ist nicht das vollständige Profil öffentlich ersichtlich. Falls sie mehr Daten wie Zeugnisse oder die Telefonnummer benötigen, können Sie eine Anfrage an {{ companionName }} stellen.</p>
  </ng-container>

  <button *ngIf="readyForRequest" class="button secondary" (click)="request()" type="submit" [disabled]="(isLoading$ | async) !== false">
    <span class="icon icon-head" aria-hidden="true"></span>
    <ng-container i18n="@@employment.conversation.btnExchangeAdvancedProfile">Erweitertes Profil austauschen</ng-container>
  </button>

  <div class="relation-status__info-box">
    <div *ngIf="sharingRequested" class="info-label">
      <div class="info-label__icon">
        <span class="icon icon-pending" aria-hidden="true"></span>
      </div>
      <div class="info-label__text" i18n="@@employment.relations.invitationIsPending">Die Team-Einladung ist pendent</div>

      <button class="button warning info-label__btn-abort" (click)="decline()" type="button" [disabled]="(isLoading$ | async) !== false">
        <ng-container i18n="@@employment.conversation.team.btnAbortRequest">Anfrage abbrechen</ng-container>
      </button>
    </div>

    <div *ngIf="answerNecessary" class="info-label">
      <div class="info-label__icon">
        <span class="icon icon-head" aria-hidden="true"></span>
      </div>
      <div class="info-label__text" i18n="@@employment.relations.requestToShareProfile">Anfrage: Möchten Sie Ihr erweitertes Profil mit {{ companionName }} teilen?</div>

      <div class="info-label__actions">
        <button class="button warning" (click)="decline()" type="button" [disabled]="(isLoading$ | async) !== false">
          <ng-container i18n="@@employment.conversation.team.btnPrepareReply">Anfrage ablehnen</ng-container>
        </button>
        <button class="button secondary" (click)="grant()" type="button" [disabled]="(isLoading$ | async) !== false">
          <ng-container i18n="@@employment.conversation.team.btnAcceptRequest">Anfrage akzeptieren</ng-container>
        </button>
      </div>
    </div>

    <div *ngIf="contactDataShared || isInTeam" class="info-label info-label--green">
      <div class="info-label__icon">
        <span class="icon icon-check" aria-hidden="true"></span>
      </div>
      <div class="info-label__text" i18n="@@employment.relations.canSeeProfile">{{ companionName }} und Sie können gegenseitig ihr erweitertes Profil sehen</div>
    </div>

    <button *ngIf="contactDataShared" class="button warning revoke-access-btn" (click)="revoke()" type="button" [disabled]="(isLoading$ | async) !== false">
      <ng-container i18n="@@employment.conversation.team.btnRevokeAccess">Zugriff auf erweitertes Profil entziehen</ng-container>
    </button>
  </div>
</section>
