import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() text: string;
  @Input() messageType = 'status';
  @Input() timestamp: Date;
  @Input() companionName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
