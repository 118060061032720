import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/identity/auth.service';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit {
  @Input() unsavedQuery: boolean;
  @Input() isSaving = false;
  @Output() saveQuery = new EventEmitter();

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }

  save(): void {
    this.saveQuery.emit();
  }
}
