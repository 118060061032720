import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty-team',
  templateUrl: './empty-team.component.html',
  styleUrls: ['./empty-team.component.scss']
})
export class EmptyTeamComponent implements OnInit {
  @Output() invitationSent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
