<main class="layout-panel-centered">
  <div class="panel">
    <header class="panel-header">
      <h1 class="heading">Verwendete UI-Komponenten</h1>
    </header>
    <div class="panel-content">

      <h2>Info Dialog</h2>
      <button class="button" (click)="openDialog()">Show Info Dialog</button>
      <button class="button" (click)="openErrorDialog()">Show Error Dialog</button>

      <h2>Nachrichten</h2>
      <p>
        <app-message>
          Lorem ipsum incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla pariatur. Excepteur dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor sint occaecat cupidatat non consequat.
        </app-message>
      </p>
      <p>
        <app-message-thread [messages]="messages"></app-message-thread>
      </p>

      <h2>Buttons</h2>
      <p><button class="button primary">Primary</button></p>
      <p><button class="button secondary">Secondary</button></p>
      <p><button class="button secondary left-aligned"><span class="icon icon-send" aria-hidden="true"></span>Icon</button></p>
      <p><button class="button ghost">Ghost</button></p>
      <p><button class="button warning">Warning</button></p>
      <p><button class="button warning" disabled>Disabled</button></p>
      <p><button class="button tertiary"><span class="icon icon-message-circle" aria-hidden="true"></span>Tertiary</button></p>
      <p><button class="button secondary icon-only"><span class="icon icon-arrow-right" aria-hidden="true"></span><span class="sr-only">Vorwärts</span></button></p>
      <p><button class="button form icon-only small"><span class="icon icon-trash" aria-hidden="true"></span><span class="sr-only">Entfernen</span></button></p>

      <h2>Badges</h2>
      <p><app-badge>Anfrage ausstehend</app-badge></p>
      <p><app-badge flavor="alert">In deinem Team</app-badge></p>
      <p><app-badge flavor="secondary" symbol="i">info</app-badge></p>
      <p><app-badge flavor="secondary">Neu</app-badge></p>
      <p><app-badge flavor="secondary" symbol="!">Achtung</app-badge></p>

      <h2>Checkbox</h2>


      <h2>Segmented Control</h2>
      <div style="width: 500px;">
        <app-segment-button-list #seglist [selectableTags]="segmentedSelectionTags"></app-segment-button-list>
        <p>Selected: {{seglist.value}}</p>
      </div>

      <h2>Radio</h2>


      <h2>Input</h2>
      <p><input type="text" class="input" placeholder="Input Value"></p>
      <p><input type="text" class="input" value="disabled text" disabled></p>
      <p><input type="text" class="input" disabled></p>
      TODO: Search Input

      <h2>Select</h2>

      <p><label for="gender" style="margin-right: 1em;">Gender</label>
      <ng-select labelForId="gender" placeholder="Choose gender..." [clearable]="false" [searchable]="false"
                 appAddIconChevronDown>
        <ng-option>Male</ng-option>
        <ng-option>Female</ng-option>
        <ng-option>Other</ng-option>
      </ng-select></p>

      <h2>Textarea</h2>
      <p><textarea class="textarea" placeholder="Text area content"></textarea></p>
      <p><textarea class="textarea" disabled></textarea></p>
      TODO: Textarea with button


      <h2>Tags</h2>
      <h3>Readonly Taglist</h3>
      <app-tag-list [tags]="tags"></app-tag-list>

      <h3>Readonly Taglist Block</h3>
      <div style="width: 300px;">
        <app-tag-list [tags]="tags" [block]="true"></app-tag-list>
      </div>

      <h3>Tag Selector</h3>
      <app-tag-selector [selectableTags]="selectableTags" [(value)]="selectedTags"></app-tag-selector>
      <ul>
        <li *ngFor="let t of selectedTags">{{t}}</li>
      </ul>

      <h2>Content</h2>

    </div>
  </div>
</main>
