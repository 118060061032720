<div class="empty-team-container">
  <div class="empty-team-info">
    <div class="empty-team-info-content">
      <h3 class="title" i18n="@@employment.emptyTeam.title">Sie haben noch keine Teammitglieder</h3>
      <figure class="images" aria-hidden="true">
        <img src="\assets\images\team-triptychon.svg" alt="">
      </figure>
      <p class="text" i18n="@@employment.emptyTeam.content">
        Sobald Sie Assistenzpersonen zu Ihrem Team hinzugefügt haben, können Sie diese hier verwalten.
      </p>
    </div>
  </div>
  <app-invitation [addPlaceholderToTeam]="true" class="invitation" (sent)="invitationSent.emit($event)"></app-invitation>
</div>
