<main [cdkTrapFocus]="isTrapActive" cdkTrapFocusAutoCapture class="layout-panel-centered">
  <form class="panel overflowing" [formGroup]="step1">
    <header class="panel-header">
      <h1 class="sr-only" i18n="sr-only@@profile.setupRecipientProfile.title">Registrierungsprozess</h1>
      <ol class="process-steps heading" aria-label="Schritte">
        <li class="completed"><a routerLink="/on-boarding/recipient-profile" aria-current="step" i18n="@@profile.setupGiverOffer.profile">1. Profil</a></li>
        <li class="d-none d-sm-flex" i18n="@@profile.setupGiverOffer.information">2. Angaben</li>
        <li class="d-none d-sm-flex" i18n="@@profile.setupGiverOffer.assistance">3. Assistenz</li>
      </ol>
      <a routerLink="/" class="cancel" i18n="@@profile.setupGiverOffer.btn.cancel">Abbrechen</a>
    </header>
    <div class="panel-content">
      <p class="text" i18n="@@profile.setupRecipientProfile.info.moreDetails">
        Der erste Eindruck zählt. Laden Sie Ihr persönliches Profilbild hoch oder wählen Sie eines der vorgegebenen Profilbilder aus. Das gewählte Profilbild wird öffentlich innerhalb der Plattform angezeigt.
      </p>
      <app-recipient-profile #recipientProfile [parentGroup]="step1"></app-recipient-profile>
    </div>
    <div class="panel-footer">
      <button type="button" class="button primary block-xs" (click)="next()" i18n="@@profile.setupGiverOffer.btn.next">Weiter</button>
    </div>
  </form>
</main>
