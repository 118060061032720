<form class="message-modal" (ngSubmit)="submit()">
  <h1 id="modal-title" class="title" i18n="@@profile.changeEmail.title">E-Mail-Adresse ändern</h1>
  <p class="body" i18n="@@profile.changeEmail.content">
    Hier können Sie die E-Mail-Adresse Ihres Profils ändern.
    Nach erfolgreicher Änderung werden Sie abgemeldet und Sie müssen sich mit der neuen E-Mail-Adresse erneut bei CléA anmelden.
  </p>
  <div class="form-group">
    <label for="profile-current-email" class="label" i18n="@@profile.changeEmail.formCurrentEmail">Aktuelle E-Mail</label>
    <input id="profile-current-email" type="email" class="input" [value]="currentEmail" disabled="true" />
  </div>
  <div class="form-group">
    <label for="profile-email" class="label">
      <ng-container i18n="@@profile.changeEmail.formNewEmail">Neue E-Mail</ng-container> <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <input id="profile-email" type="email" class="input" [formControl]="email" />
    <div *ngIf="email.invalid && email.touched"
         class="validation error" i18n="@@profile.changeEmail.formInvalidEmail">Bitte geben Sie eine gültige E-Mail-Adresse ein.</div>
  </div>
  <div class="form-group">
    <label for="profile-confirm-email" class="label">
      <ng-container i18n="@@profile.changeEmail.formRepeatNewEmail">Neue E-Mail wiederholen</ng-container> <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
    </label>
    <input id="profile-confirm-email" type="email" class="input" [formControl]="confirmEmail" />
    <div *ngIf="form.hasError('unconfirmedEmail') && form.touched"
         class="validation error" i18n="@@profile.changeEmail.formInvalidRepeatEmail">Bitte wiederholen Sie die E-Mail-Adresse.</div>
  </div>
  <div class="action">
    <ng-container *ngIf="(isLoading$ | async) === false">
      <button type="button" class="button" (click)="cancel()" i18n="@@profile.changeEmail.formBtnCancel">Abbrechen</button>
      <button type="submit" class="button primary" i18n="@@profile.changeEmail.formBtnSave">Ändern</button>
    </ng-container>
    <app-spinner [visible]="isLoading$ | async"></app-spinner>
  </div>
</form>
