<ul role="radiogroup" class="segmented-button-group" [attr.aria-labelledby]="labelledby">
  <li *ngFor="let tag of selectableTags">
    <app-segment-button [isChecked]="isChecked(tag)"
                        [value]="tag.key"
                        [name]="tag.name"
                        (click)="check(tag)">
    </app-segment-button>
  </li>
</ul>
<ng-select class="segmented-button-dropdown"
      bindLabel="name"
      bindValue="key"
      [items]="selectableTags"
      [clearable]="false"
      [searchable]="false"
      (change)="check($event)"
      [ngModel]="value"
      appAddIconChevronDown>
</ng-select>
