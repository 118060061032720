import { Component, OnInit, Input } from '@angular/core';
import { MemberRelationData } from '../employment.model';
import { Member, Offer, OfferType, Reference } from 'src/app/marketplace/marketplace.model';
import { AuthService } from 'src/app/identity/auth.service';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { ReferenceEditComponent } from 'src/app/ui-library/modal/reference-edit/reference-edit.component';
import { ProfileService } from 'src/app/profile/profile.service';

@Component({
  selector: 'app-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent implements OnInit {
  @Input() memberRelation: MemberRelationData;
  myReference: Reference;

  constructor(
    private auth: AuthService,
    private modal: ModalService,
    private profile: ProfileService) { }

  get member(): Member {
    return this.memberRelation?.member;
  }

  get offer(): Offer {
    const visibleOffer: OfferType = this.auth.isGiver ? 'Job' : 'Assistance';
    return this.memberRelation.member.offers.find(o => o.offerType === visibleOffer);
  }

  get hasReference(): boolean {
    return this.myReference?.comment !== '';
  }

  ngOnInit(): void {
    this.profile.currentMember().subscribe(m => {
      this.myReference = this.member.references.find(r => r.authorId === m.id) || {
        authorId: m.id,
        comment: '',
        createdOn: null,
        authorFirstName: m.firstName,
        authorProfilePicture: m.profilePicture
      };
    });
  }

  openReferenceEdit(): void {
    const modalRef = this.modal.show(ReferenceEditComponent, { member: this.member, reference: this.myReference });
    modalRef.afterClosed$.subscribe(d => {
      if (d.data) {
        this.myReference = d.data;
      }
    });
  }
}
