import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ProfileImageSelectorComponent } from '../../profile-image-selector/profile-image-selector.component';

@Component({
  selector: 'app-recipient-profile',
  templateUrl: './recipient-profile.component.html',
  styleUrls: ['./recipient-profile.component.scss']
})
export class RecipientProfileComponent implements OnInit {
  @Input() parentGroup: FormGroup;

  @ViewChild('profileImageSelector')
  profileImageSelector: ProfileImageSelectorComponent;

  avatars = [
    'assets/images/profil-thumbnail-1.svg',
    'assets/images/profil-thumbnail-2.svg',
    'assets/images/profil-thumbnail-3.svg',
    'assets/images/profil-thumbnail-4.svg',
  ];

  get profileImage(): string {
    return this.parentGroup.get('profileImage').value;
  }

  get customProfileImage(): any {
    return this.parentGroup.get('customProfileImage').value;
  }

  get firstControl(): AbstractControl {
    return this.parentGroup.controls.first;
  }

  get nameControl(): AbstractControl {
    return this.parentGroup.controls.name;
  }

  get profileImageControl(): AbstractControl {
    return this.parentGroup.controls.profileImage;
  }

  constructor() { }

  ngOnInit(): void {
  }

  setAvatar(s: string) {
    this.parentGroup.get('profileImage').setValue(s);
    this.parentGroup.get('profileImage').markAsDirty();
    this.parentGroup.get('customProfileImage').reset('');
    this.parentGroup.get('customProfileImage').markAsDirty();
  }

  setCustomProfileImage(s: string) {
    this.parentGroup.get('profileImage').setValue(s);
    this.parentGroup.get('profileImage').markAsDirty();
    this.parentGroup.get('customProfileImage').setValue(s);
    this.parentGroup.get('customProfileImage').markAsDirty();
  }

  async croppedImage(): Promise<any> {
    return await this.profileImageSelector.croppedImage();
  }

}
