<main class="layout-profile-sidebar">
  <header class="breadcrumb">
    <button type="button" class="button tertiary d-none d-sm-flex" (click)="back()">
      <span class="icon icon-arrow-left"></span>
      <ng-container i18n="@@profile.edit.btnBack">Zurück</ng-container>
    </button>
  </header>
  <article class="layout-ignore">
    <header class="header">
      <h1 class="heading" i18n="@@profile.edit.title">Profil bearbeiten</h1>
    </header>
    <aside class="sidebar">
      <nav>
        <h2 class="sr-only" i18n="sr-only@@profile.edit.pageNavigation">Seitennavigation</h2>
        <ul class="navbar-nav vertical sticky">
          <li class="nav-item" [class.link-active]="(activeFragment | async) === null || (activeFragment | async) === 'personal-data'">
            <a [routerLink]='"."' [replaceUrl]="true" fragment="personal-data" class="nav-link" i18n="@@profile.edit.link.personalDetails">Personalien</a>
          </li>
          <li class="nav-item" [class.link-active]="(activeFragment | async) === 'assistance'">
            <a [routerLink]='"."' [replaceUrl]="true" fragment="assistance" class="nav-link" i18n="@@profile.edit.link.assistance">Assistenz</a>
          </li>
          <li class="nav-item" [class.link-active]="(activeFragment | async) === 'visibility'">
            <a [routerLink]='"."' [replaceUrl]="true" fragment="visibility" class="nav-link" i18n="@@profile.edit.link.visibility">Sichtbarkeit</a>
          </li>
          <li class="nav-item" [class.link-active]="(activeFragment | async) === 'actions'">
            <a [routerLink]='"."' [replaceUrl]="true" fragment="actions" class="nav-link" i18n="@@profile.edit.link.actions">Aktionen</a>
          </li>
        </ul>
      </nav>

    </aside>
    <div class="content">
      <h2 class="sr-only" i18n="sr-only@@profile.edit.titleContents">Inhalt</h2>

      <form *ngIf="form" [formGroup]="form">
        <section id="personal-data" class="section">
          <h3 class="heading">
            <button i18n="@@profile.edit.title.personalDetails" type="button" aria-controls="personalData" aria-expanded="showPersonalData" (click)="toggle('personal-data')" [class.up]="showPersonalData" class="caret button semantic-only d-sm-none">Personalien</button>
            <span class="d-none d-sm-block" i18n="@@profile.edit.title.personalDetails">Personalien</span>
          </h3>
          <div id="personalData" [class.d-none]="!showPersonalData" class="d-sm-block">
            <ng-container *ngIf="isGiver">
              <app-giver-profile #giverProfile [parentGroup]="step1"></app-giver-profile>
              <app-giver-profile-details [parentGroup]="step2" [form]="form" [memberId]="member.id"></app-giver-profile-details>
            </ng-container>

            <ng-container *ngIf="isRecipient">
              <app-recipient-profile #recipientProfile  [parentGroup]="step1"></app-recipient-profile>
              <app-recipient-profile-details [parentGroup]="step2" [form]="form" [memberId]="member.id"></app-recipient-profile-details>
            </ng-container>
          </div>
        </section>

        <section id="assistance" class="section">
          <h3 class="heading">
            <button i18n="@@profile.edit.title.assistance" type="button" aria-controls="assistance" aria-expanded="showAssistance" (click)="toggle('assistance')" [class.up]="showAssistance" class="caret button semantic-only d-sm-none">Assistenz</button>
            <span class="d-none d-sm-block" i18n="@@profile.edit.title.assistance">Assistenz</span>
          </h3>
          <div id="assistance" [class.d-none]="!showAssistance" class="d-sm-block">
            <app-giver-offer *ngIf="isGiver" [parentGroup]="step3"></app-giver-offer>
            <app-recipient-offer *ngIf="isRecipient" [parentGroup]="step3"></app-recipient-offer>
          </div>
        </section>

        <section id="visibility" class="section">
          <h3 id="input-offer-active" class="heading">
            <button i18n="@@profile.edit.title.visibility" type="button" aria-controls="visibility" aria-expanded="showVisibility" (click)="toggle('visibility')" [class.up]="showVisibility" class="caret button semantic-only d-sm-none">Sichtbarkeit meines Profils</button>
            <span class="d-none d-sm-block" i18n="@@profile.edit.title.visibility">Sichtbarkeit meines Profils</span>
          </h3>
          <div id="visibility" class="form-group form-row d-sm-block" [class.d-none]="!showVisibility">
            <app-segment-button-list labelledby="input-offer-active"
                                    [selectableTags]="activeOfferControlChoices"
                                    [value]="activeOfferControl.value"
                                    (valueChange)="activeOfferControl.setValue($event); activeOfferControl.markAsDirty();">
            </app-segment-button-list>
          </div>
        </section>

        <div class="save-button section">
          <button type="button" class="button primary block-xs" (click)="submit()"
              [disabled]="!form.dirty || !form.valid" i18n="@@profile.edit.btn.submit">Speichern</button>
        </div>
      </form>
      <section id="actions">
        <h3 class="heading" i18n="@@profile.edit.title.actions">Aktionen</h3>
        <div class="actions form-group">
          <button (click)="changeEmail()" type="button" class="button block-xs" i18n="@@profile.edit.btn.changeEmail">E-Mail ändern</button>
          <button (click)="changePassword()" type="button" class="button block-xs" i18n="@@profile.edit.btn.changePassword">Passwort ändern</button>
          <button (click)="deleteMember()" type="button" class="button block-xs" i18n="@@profile.edit.btn.deleteMember">Konto löschen</button>
        </div>
      </section>
    </div>
  </article>
</main>
