import { Component, Input, EventEmitter, Output, HostListener, ViewChildren, QueryList } from '@angular/core';
import { SelectionTag, SelectionTagKeyType } from '../models/selection-tag';
import { SegmentButtonComponent } from '../segment-button/segment-button.component';

@Component({
  selector: 'app-segment-button-list',
  templateUrl: './segment-button-list.component.html',
  styleUrls: ['./segment-button-list.component.scss']
})
export class SegmentButtonListComponent {
  @Input() selectableTags: SelectionTag[] = [];
  @Input() value: SelectionTagKeyType;
  @Output() valueChange = new EventEmitter<SelectionTagKeyType>();
  @Input() labelledby = '';

  @ViewChildren(SegmentButtonComponent) options: QueryList<SegmentButtonComponent>;

  constructor() { }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      event.preventDefault();
      this.checkNext();
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      event.preventDefault();
      this.checkPrevious();
    }
  }

  isChecked(current: SelectionTag): boolean {
    return this.value === current.key;
  }

  check(current: SelectionTag): void {
    this.value = current.key;
    this.valueChange.emit(this.value);
  }

  private checkPrevious(): void {
    if (this.selectableTags.length < 2) {
      return;
    }

    const index = this.findCurrentIndex();
    const nextIndex = index === 0 ? this.selectableTags.length - 1 : index - 1;
    this.options.toArray()[nextIndex].check();
  }

  private checkNext(): void {
    if (this.selectableTags.length < 2) {
      return;
    }

    const index = this.findCurrentIndex();
    const nextIndex = index === this.selectableTags.length - 1 ? 0 : index + 1;
    this.options.toArray()[nextIndex].check();
  }

  private findCurrentIndex(): number {
    if (this.value) {
      return this.selectableTags.findIndex(v => v.key === this.value);
    } else {
      return -1;
    }
  }
}
