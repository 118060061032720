import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, ObservableInput, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  auth0Domain: string;
  auth0ClientId: string;
  auth0Connection: string;
  auth0PasswordlessConnection: string;
  auth0ApiIdentifier: string;
  applicationTitle: string;
  isProduction: boolean;

  constructor(private http: HttpClient) { }

  init(): Promise<boolean> {
    this.isProduction = window.location.host !== 'cleaapp-dev-app.azurewebsites.net' ? true : false;
    this.applicationTitle = $localize`:@@footer.link.cleaHome:CléA Assistenzplattform`;
    if (!this.isProduction){
      this.applicationTitle = this.applicationTitle + ' - Testumgebung';
    }

    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      this.http.get('/api/config')
        .pipe(
          map((x: any) => {
            this.auth0Domain = x.auth0Domain;
            this.auth0ClientId = x.auth0ClientId;
            this.auth0Connection = x.auth0Connection;
            this.auth0PasswordlessConnection = x.auth0PasswordlessConnection;
            this.auth0ApiIdentifier = x.auth0ApiIdentifier;
            resolve(true);
          }),
          catchError((x: { status: number }, caught: Observable<void>): ObservableInput<Record<string, never>> => {
            if (x.status !== 404) {
              resolve(false);
            }

            this.auth0Domain = '';
            this.auth0ClientId = '';
            this.auth0Connection = '';
            this.auth0PasswordlessConnection = '';
            this.auth0ApiIdentifier = '';
            resolve(true);
            return of({});
          })
        ).subscribe();
    });
  }
}
