import { Component, OnInit, Input } from '@angular/core';
import { TagStatus } from '../models/selection-tag';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  @Input() status: TagStatus = TagStatus.None;

  constructor() {
  }

  ngOnInit(): void {
  }

  public get isSelected(): boolean {
    return this.status === TagStatus.Selected;
  }

  public get isUnselected(): boolean {
    return this.status === TagStatus.Unselected;
  }

  public get isAdded(): boolean {
    return this.status === TagStatus.Added;
  }
}
