<label for="input-birthdate" class="label">
  <ng-container i18n="@@shared.giverProfile.input.birthdate">Geburtsdatum</ng-container>
  <abbr class="req-label" i18n-title="@@main.alt.required" title="Pflicht">*</abbr>
</label>
<div class="input-group-selectors" [class]="valueControl.invalid && (valueControl.touched || valueControl.dirty) ? 'ng-invalid' : ''">
  <ng-select labelForId="input-day"
            class="day"
            i18n-placeholder="@@shared.giverProfile.placeholder.day"
            placeholder="Tag"
            [formControl]="birthdateDayControl"
            [items]="availableDays"
            [clearable]="false"
            bindValue="key"
            appAddIconChevronDown
            i18n-notFoundText="@@shared.placeholder.notFound"
            notFoundText="Keine Elemente gefunden"
  >
  </ng-select>
  <ng-select labelForId="input-month"
            i18n-placeholder="@@shared.giverProfile.placeholder.month"
            placeholder="Monat"
            [items]="availableMonths"
            [clearable]="false"
            [formControl]="birthdateMonthControl"
            bindValue="key"
            appAddIconChevronDown
            i18n-notFoundText="@@shared.placeholder.notFound"
            notFoundText="Keine Elemente gefunden"
  >
    <ng-template ng-label-tmp let-item="item">
      {{ item.label.split('|')[0] }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      {{ item.label.split('|')[0] }}
    </ng-template>
  </ng-select>
  <ng-select labelForId="input-year"
            class="year"
            i18n-placeholder="@@shared.giverProfile.placeholder.year"
            placeholder="Jahr"
            [items]="availableYears"
            [clearable]="false"
            [formControl]="birthdateYearControl"
            bindValue="key"
            appAddIconChevronDown
            i18n-notFoundText="@@shared.placeholder.notFound"
            notFoundText="Keine Elemente gefunden"
  >
  </ng-select>
</div>
<div *ngIf="valueControl.invalid && (valueControl.touched || valueControl.dirty)">
  <div *ngIf="valueControl.errors.allowedAge"
       class="validation error" i18n="@@shared.giverProfile.error.birthdateMin18">Du musst mindestens 18 Jahre alt sein
  </div>
  <div *ngIf="valueControl.errors.pattern"
       class="validation error" i18n="@@shared.giverProfile.error.birthdateFormat">Das Datum muss im Format JJJJ-MM-DD eingegeben werden.
  </div>
</div>
