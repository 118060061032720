import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EmploymentService } from '../employment.service';
import { MemberRelation, MemberRelationData, Relation } from '../employment.model';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { ContactSharedComponent } from '../modals/contact-shared/contact-shared.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-team-overview',
  templateUrl: './team-overview.component.html',
  styleUrls: ['./team-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamOverviewComponent implements OnInit {
  isLoaded = false;

  readonly emailFeedbackPlatfrom: string = 'mailto:feedback@clea.app?subject=' + encodeURIComponent($localize`:@@emailFeedbackPlatfrom:Feedback Nutzung CléA Jobplattform`);

  constructor(
    public employment: EmploymentService,
    private _modalService: ModalService,
    private _cdr: ChangeDetectorRef,
    private cookieService: CookieService,

  ) { }

  ngOnInit(): void {
    this.employment.loadRelations('Employer').subscribe({
      complete: () => {
        this.isLoaded = true;
        this._showInfoWhenRequestGranted();
        this._cdr.detectChanges();
      },
      error: () => {
        this.isLoaded = true;
        this._cdr.detectChanges();
      }
    });
  }

  requestGranted(relation: Relation): void {
    this._showInfoWhenRequestGranted();
  }

  removeFromTeam(relation: Relation): void {
    this._cdr.detectChanges();
  }

  addPlaceholder(email: string): void {
    const m = new MemberRelation(
      {
        relation: {
          memberId: '',
          memberRole: 'Employee',
          allowedAction: 'RemoveFromTeam',
          conversation: [],
          inTeam: true
        },
        member: {
          id: null,
          placeholder: true,
          firstName: null,
          lastName: null,
          profilePicture: null,
          aboutMe: null,
          phoneNumber: null,
          emailAddress: email,
          age: null,
          dateOfBirth: null,
          nativeLanguage: null,
          gender: null,
          memberLanguages: [],
          documents: [],
          offers: [],
          references: [],
          hasPrivilegedData: false
        }});
    this.employment.addRelation(m);
    this._cdr.detectChanges();
  }

  clearPlaceholder(memberRelation: MemberRelationData): void {
    this.removeFromTeam(memberRelation.relation);
  }

  private _showInfoWhenRequestGranted(): void {
    const isShowedPopup = !!this.cookieService.get('showedContactSharedComponent');
    if (!isShowedPopup && this.employment.openRequests.some(jr => jr.relation.allowedAction === 'AddToTeamOrDecline')) {
      this._modalService.show(ContactSharedComponent, null);
      this.cookieService.set('showedContactSharedComponent', '1');
    }
  }
}
