import { ApplicationInitStatus, Component, OnDestroy, OnInit } from '@angular/core';
import { MarketplaceService } from '../marketplace.service';
import { mapToOffer } from '../marketplace-form.service';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/ui-library/modal/modal.service';
import { SearchService } from '../search.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SearchResult } from '../marketplace.model';
import { AuthService } from 'src/app/identity/auth.service';
import { ProfileService } from '../../profile/profile.service';
import { prepareMemberToUpdate } from 'src/app/profile/profile-form.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isSaving$ = new BehaviorSubject<boolean>(false);
  hitlist: SearchResult;

  private searchSubscription: Subscription;

  constructor(
    private auth: AuthService,
    private marketplaceService: MarketplaceService,
    public search: SearchService,
    private router: Router,
    private modal: ModalService,
    private profileService: ProfileService) {
    this.showWelcomeAfterRegistration();
  }

  ngOnInit(): void {
    this.form = this.search.getSearchForm();
    this.searchSubscription = this.search.loadedResults$
      .subscribe(r => this.hitlist = r);
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  get unsavedQuery(): boolean {
    return this.form.dirty;
  }

  async saveQuery(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const offer = mapToOffer(this.form.value);
    try {
      this.isSaving$.next(true);
      const member = await this.profileService.currentMember().toPromise();
      const updatedMember = prepareMemberToUpdate(member, { memberLanguages: offer.languages, offer });
      await this.profileService.modifyMember(updatedMember, undefined, []);
    } finally {
      this.form.markAsPristine();
      this.isSaving$.next(false);
    }
  }

  loadMore(): void {
    this.search.loadPage(this.hitlist.currentPage + 1, true);
  }

  private showWelcomeAfterRegistration() {
    if (this.router.getCurrentNavigation().extras.state) {
      const routeState = this.router.getCurrentNavigation().extras.state;
      if (routeState && routeState.showWelcome) {
        const text = this.auth.isRecipient
          ? $localize`:@@showWelcomeAfterRegistration.isRecipient:Sie können Ihre Angaben jederzeit im «Profil» anpassen. Um Ihnen bei der Suche zu helfen, haben wir bereits passende Assistenten/innen gesucht.\n\nHinweis: Wir haben Ihnen eine E-Mail gesendet, über welche Sie Ihr Passwort setzen können.`
          : $localize`:@@showWelcomeAfterRegistration.isGiver:Sie können Ihre Angaben jederzeit im «Profil» anpassen. Um Ihnen bei der Suche zu helfen, haben wir bereits passende Arbeitgeber/innen gesucht.\n\nHinweis: Wir haben Ihnen eine E-Mail gesendet, über welche Sie Ihr Passwort setzen können.`;

        const imagePath = this.auth.isRecipient
          ? '/assets/images/illustration-01.svg'
          : '/assets/images/illustration-16.svg';

          this.modal.showMessageBox(
            $localize`:@@showWelcomeAfterRegistration.title:Geschafft`,
            text,
            imagePath
          );
      }
    }
  }
}
