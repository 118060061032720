<main class="main-start">
  <div class="head-background"></div>
  <div class="head">
    <h1 class="sr-only" i18n="sr-only@@verify.title">Willkommen auf der CléA Assistenzplattform</h1>
    <img src="/assets/images/illustration-registration.svg" aria-hidden="true">
  </div>

  <form class="content" [formGroup]="form">
    <div>
      <p class="text" i18n="@@verify.content">
        Wir haben eine Verifikationsnummer an Ihre E-Mail-Adresse geschickt, um Ihre E-Mailadresse zu prüfen.
        Bitte geben Sie die erhaltene Verifikationsnummer in das unten stehende Feld ein.<br>
        Falls Sie die E-Mail nicht erhalten haben, prüfen Sie bitte auch den Spam-Ordner.
      </p>
    </div>
    <div class="row">
      <div class="col-left">
        <div class="form-group" [hidden]="isLoading$ | async">
        <label for="input-verification-code" class="label" i18n="@@verify.input.verificationCode">Verifikationsnummer aus E-Mail</label>
          <input id="input-verification-code" type="string" class="input large"
                  formControlName="verificationCode">
          <div *ngIf="verificationCodeControl.invalid && (verificationCodeControl.touched || verificationCodeControl.dirty)"
                class="validation error" i18n="@@verify.error.verificationCode">Bitte geben Sie die 6-stelligen Verifikationsnummer ein.</div>
        </div>
        <app-spinner [visible]="isLoading$ | async"></app-spinner>
      </div>
      <div class="col-right">
        <div class="form-group">
          <button (click)="verifyEnrollment()" class="button primary large block"
                  [disabled]="(isLoading$ | async) || !form.valid" i18n="@@verify.btn.verifyEnrollment">Bestätigen</button>
        </div>
      </div>
    </div>
    <div>
      <button class="link" type="button" (click)="requestVerificationCode()" i18n="@@verify.btn.requestVerificationCode">Verifikationsnummer erneut anfordern</button>
    </div>
  </form>
</main>
