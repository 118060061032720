import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mailto'
})
export class MailtoPipe implements PipeTransform {

  transform(value: string): string {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    return `mailto:${value}`;
  }

}
