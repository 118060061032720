import { Overlay, OverlayConfig, ScrollStrategyOptions, OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { ModalRef } from './modal-ref';
import { ModalComponent } from './component/modal.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { AcceptAgbBoxComponent } from './accept-agb-box/accpet-agb-box.component';
import { ErrorBoxComponent } from './error-box/error-box.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private overlay: Overlay, private container: OverlayContainer, private injector: Injector) {}

  show<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    data: T
  ): ModalRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal'],
      backdropClass: 'modal-background',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically()
    });

    const overlayRef = this.overlay.create(configs);
    this.container.getContainerElement().setAttribute('role', 'dialog');
    this.container.getContainerElement().setAttribute('aria-modal', 'true');
    this.container.getContainerElement().setAttribute('aria-labelledby', 'modal-title');

    const modalRef = new ModalRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(modalRef, this.injector);
    overlayRef.attach(new ComponentPortal(ModalComponent, null, injector));

    return modalRef;
  }

  showMessageBox<R = any>(title: string, text: string, image: string = null): ModalRef<R> {
    return this.show(MessageBoxComponent, { title, text, image });
  }

  showErrorBox<R = any>(text: string, title: string = null): ModalRef<R> {
    return this.show(ErrorBoxComponent, { title, text });
  }

  showAcceptAgbBox<R = any>(): ModalRef<R> {
    return this.show(AcceptAgbBoxComponent, { igonoreBackdrop: true });
  }

  async showConfirmation(title: string, question: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.show(ConfirmationComponent, { title, text: question })
        .afterClosed$
        .subscribe(s => resolve(s.data));
    });
  }

  async showCustomConfirmation(
    title: string,
    question: string,
    image: string|null = null,
    btnAccept: string|null = null,
    btnCancel: string|null = null,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.show(ConfirmationComponent, { title, text: question, image, btnAccept, btnCancel })
        .afterClosed$
        .subscribe(s => resolve(s.data));
    });
  }

  private createInjector(ref: ModalRef, inj: Injector) {
    const injectorTokens = new WeakMap([[ModalRef, ref]]);
    return new PortalInjector(inj, injectorTokens);
  }
}
